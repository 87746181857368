<section *ngIf="currentUser.role === 1002">
  <div
    *ngIf="!formStudent"
    class="container paddingcontainer d-flex justify-content-center align-items-center flex-column"
    style="padding-top: 8rem"
  >
    <p class="h2 previewTitle" translate>
      Register to Offer Your Hours in NFT Tokens
    </p>

    <div class="py-5 previewMessage" style="width: 60%; text-align: justify">
      <p translate>
        Welcome to the platform that allows you to monetize your knowledge and
        skills! Complete this form to become a seller of hours in the form of
        NFT Tokens.
      </p>
      <p translate>
        By joining our community, you will be able to offer your hours in NFT
        Tokens and finance your education or career. After submitting your
        application, our team of experts will review your profile to ensure that
        your experience will be a valuable asset to our sponsors. We will notify
        you as soon as your application is approved.
      </p>
      <p translate>
        Seize the opportunity to turn your expertise into an investment!
      </p>
      <br />
      <a [routerLink]="['/avisoslegales']" target="_blank" translate
        >Visit terms and conditions of service</a
      >
    </div>

    <div class="text-center mt-4">
      <button
        (click)="formStudent = !formStudent"
        class="continue-button"
        type="submit"
        translate
      >
        <span translate> Continue </span>
      </button>
    </div>
  </div>

  <div *ngIf="formStudent">
    <div class="container paddingcontainer" style="padding-top: 8rem">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-12">
          <p class="h2 previewTitle pb-5 text-center" translate>
            Student Registration
          </p>
          <div
            class="card card-registration card-registration-2"
            style="border: none"
          >
            <div class="card-body p-0">
              <form
                [formGroup]="estudianteForm"
                (ngSubmit)="onSubmitEstudiante(estudianteForm)"
              >
                <div class="row g-0">
                  <div class="col-lg-6" style="padding: 2rem">
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              General Infomation
                            </h3>
                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >First Name</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="first_name"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label
                                    class="form-label"
                                    for="form3Examplev3"
                                    translate
                                    >Last Name</label
                                  >
                                  <input
                                    type="text"
                                    id="form3Examplev3"
                                    class="form-control"
                                    formControlName="last_name"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="city" translate
                                    >Date of birth</label
                                  >
                                  <div class="datepicker date input-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="fecha1"
                                      bsDatepicker
                                      formControlName="date_of_birth"
                                      autocomplete="off"
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text"
                                        ><i class="fa fa-calendar"></i
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label class="form-label" translate
                                    >Email</label
                                  >
                                  <input
                                    type="text"
                                    id="form3Examplev3"
                                    class="form-control"
                                    formControlName="email"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div
                                class="col-sm-12 box-input-ss"
                                style="width: 100% !important"
                              >
                                <div class="form-outline">
                                  <label translate>phone</label>

                                  <div class="form-group">
                                    <div class="input-group">
                                      <mat-select
                                        class="form-control"
                                        formControlName="selectedCountry"
                                      >
                                        <mat-option
                                          *ngFor="
                                            let countryCode of countriesCode
                                          "
                                          [value]="countryCode.dial_code"
                                        >
                                          <img
                                            class="flag1"
                                            [src]="
                                              './../../../../assets/images/svg/' +
                                              countryCode.code.toLowerCase() +
                                              '.svg'
                                            "
                                          />
                                          <span class="country-info"
                                            >{{ countryCode.name }} ({{
                                              countryCode.dial_code
                                            }})</span
                                          >
                                        </mat-option>
                                      </mat-select>
                                      <input
                                        class="form-control"
                                        type="tel"
                                        formControlName="phoneNumber"
                                        style="width: 35% !important"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- aca empiez country Infomation -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              Address Infomation
                            </h3>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >address</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="address"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >house number</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="house_number"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="country" translate>country</label>
                                  <mat-select
                                    class="form-control"
                                    id="country"
                                    name="country"
                                    formControlName="country"
                                    required
                                  >
                                    <mat-option
                                      *ngFor="let country of countries"
                                      [value]="country.name"
                                    >
                                      <img
                                        class="flag1"
                                        [src]="
                                          './../../../../assets/images/svg/' +
                                          country.code.toLowerCase() +
                                          '.svg'
                                        "
                                      />
                                      {{ country.name }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>

                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="province" translate
                                    >Province</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="province"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-2">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="city" translate>city</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="city"
                                    name="city"
                                    formControlName="city"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >Zip Code</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="zipcode"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- aca termina country Infomation -->

                    <!-- aca comienza document information -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              ID information
                            </h3>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="type_document" translate
                                    >ID type</label
                                  >
                                  <mat-select
                                    class="form-control"
                                    id="type_document"
                                    name="type_document"
                                    formControlName="type_document"
                                    required
                                    translate
                                    [(ngModel)]="selectID"
                                  >
                                    <mat-option
                                      *ngFor="let document of documents"
                                      [value]="document.name"
                                      translate
                                    >
                                      <!-- <img class="flag1"
                                                                [src]="'./../../../../assets/images/svg/' + country.code.toLowerCase() + '.svg'" /> -->
                                      {{ document.name | translate }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>

                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="document" translate
                                    >ID number</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="document"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3" *ngIf="selectID">
                              <!-- *ngIf="selectID == 'ID Card' || selectID == 'Driver License'" -->

                              <div
                                class="col-sm-12 box-input-ss"
                                style="width: 100%; margin-left: 0px"
                              >
                                <label translate>Front ID</label>

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputFrontDocument.click()"
                                  (drop)="onDrop($event, 'photoIdFront')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload the
                                    photo of your document.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                        (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputFrontDocument
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*,application/pdf"
                                    style="display: none"
                                    (change)="onPhotoSelectedPhotoId($event)"
                                  />
                                  <label class="small text-muted mt-2" translate
                                  *ngIf="!selectedImage">Upload your photo ID.</label
                                  >
                                  <div *ngIf="selectedImage">
                                    <img
                                      [src]="selectedImage"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>

                            <div
                              class="row pb-3"
                              *ngIf="
                                selectID == 'ID Card' ||
                                selectID == 'Driver License'
                              "
                            >
                              <div
                                class="col-sm-12 box-input-ss"
                                style="width: 100%; margin-left: 0px"
                              >
                                <label translate>Back ID</label>

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputBackDocument.click()"
                                  (drop)="onDrop($event, 'photoIdBack')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload the
                                    photo of your document.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                        (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputBackDocument
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*,application/pdf"
                                    style="display: none"
                                    (change)="
                                      onPhotoSelectedPhotoIdBack($event)
                                    "
                                  />
                                  <label class="small text-muted mt-2" translate
                                  *ngIf="!selectedImagePhotoIdBack">Upload your photo ID.</label
                                  >
                                  <div *ngIf="selectedImagePhotoIdBack">
                                    <img
                                      [src]="selectedImagePhotoIdBack"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- aca termina document information -->

                    <!-- aca comienza la parte de la foto -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              Aditional Infomation
                            </h3>
                            <!-- <div class="row pb-3">
                                        -- <div class="col-sm-12 box-input">

                                            <div class="form-outline">
                                                <label translate>photo</label>
                                                <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" />
                                                <label class="small text-muted mt-2" translate>Upload your photo
                                                    ID.</label>

                                            </div>
                                        </div> --
                                        <div class="col-sm-12 box-input" style="width: 100%; margin-left: 0px">
                                            <label translate>Front document</label>

                                            <div class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box" (click)="fileInput.click()" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
                                                <label translate>Drag and drop or click here to upload the photo of your document.</label>
                                                -- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> --
                                                    <input #fileInput class="form-control" id="formFileLg" type="file" accept="image/*" style="display: none"
                                                    (change)="onPhotoSelectedPhotoId($event)" />
                                                <label class="small text-muted mt-2" translate>Upload your photo
                                                    ID.</label>
                                                    <img [src]="selectedImage" *ngIf="selectedImage"  style="max-width: 200px; max-height: 200px;" />

                                            </div>
                                        </div>
                                    </div> -->
                            <div class="row pb-3">
                              <!-- <div class="col-sm-12">

                                            <div class="form-outline">
                                                <label translate>Photo of the day of register</label>
                                                <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)=" onPhotoSelectedRegister($event)" />
                                                <label translate>Upload a photo the day of register.</label>

                                            </div>
                                        </div> -->
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <label translate
                                  >Photo of the day of register</label
                                >

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputRegisterDay.click()"
                                  (drop)="onDrop($event, 'register')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload a photo of the day of your registration.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputRegisterDay
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*"
                                    style="display: none"
                                    (change)="onPhotoSelectedRegister($event)"
                                  />
                                  <div *ngIf="selectedImageRegisterDay">
                                    <img
                                      [src]="selectedImageRegisterDay"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                    <!-- (click)="clearImage(this.selectedImageRegisterDay, this.filePhotoRegister)" -->
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>

                            <div class="row pb-2">
                              <!-- <div class="col-sm-12">

                                            <div class="form-outline">
                                                <label translate>Utility bill</label>
                                                <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="  onPhotoSelectedUtilityBill($event)" />
                                                <label class="small text-muted mt-2" translate>Upload your Utility
                                                    bill.</label>

                                            </div>
                                        </div> -->
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <label translate>Utility bill</label>

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputUtilityBill.click()"
                                  (drop)="onDrop($event, 'utilityBill')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload a photo of a utility bill in your name.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputUtilityBill
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*"
                                    style="display: none"
                                    (change)="
                                      onPhotoSelectedUtilityBill($event)
                                    "
                                  />
                                  <label class="small text-muted mt-2" translate
                                    >It must match the address provided to verify your residency.</label
                                  >
                                  <div *ngIf="selectedImageUtilityBill">
                                    <img
                                      [src]="selectedImageUtilityBill"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- aca termina la parte de la foto -->

                  </div>

                  <!-- aca comienza lo detalles de la universidad -->
                  <div class="col-lg-6" style="padding: 2rem">
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3 class="fw-normal mb-3" translate>
                              Education: University details
                            </h3>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label translate>University</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="university"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label class="form-label" translate
                                    >address</label
                                  >
                                  <input
                                    type="text"
                                    id="form3Examplev3"
                                    class="form-control"
                                    required
                                    formControlName="address_university"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="country" translate>country</label>
                                  <mat-select
                                    class="form-control"
                                    id="country"
                                    name="country"
                                    formControlName="country_university"
                                    required
                                  >
                                    <mat-option
                                      *ngFor="let country of countries"
                                      [value]="country.name"
                                    >
                                      <img
                                        class="flag1"
                                        [src]="
                                          './../../../../assets/images/svg/' +
                                          country.code.toLowerCase() +
                                          '.svg'
                                        "
                                      />
                                      {{ country.name }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>

                              <div class="col-sm-6 pb-2 box-input-ss">
                                <div class="form-outline">
                                  <label for="city" translate>Province</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="city"
                                    name="city"
                                    formControlName="province_university"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="city" translate>City</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="city"
                                    name="city"
                                    formControlName="city_university"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label translate>Zip Code</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="zipcode_university"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-group">
                                  <label translate>careers</label>
                                  <mat-select
                                    class="form-control"
                                    formControlName="selectedCategoryStudents"
                                    required
                                    id="selectedCategoryStudents"
                                  >
                                    <mat-option
                                      *ngFor="let option of estudianteOptions"
                                      [value]="option.name"
                                      >{{ option.name }}</mat-option
                                    >
                                  </mat-select>
                                </div>
                              </div>
                              <!-- <div class="col-sm-6 box-input-ss">
                                            <div class="form-outline">
                                                <label for="city" translate>Year Start University</label>
                                                <div class="datepicker date input-group">
                                                    <input type="text" class="form-control" bsDaterangepicker
                                                        formControlName="years_of_study"
                                                        [bsConfig]="{ isAnimated: true }" autocomplete="off">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text"><i
                                                                class="fa fa-calendar"></i></span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div> -->
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="city" translate
                                    >Year Start University</label
                                  >
                                  <select
                                    class="form-control"
                                    formControlName="years_of_study"
                                  >
                                    <option
                                      *ngFor="let year of years"
                                      [value]="year"
                                    >
                                      {{ year }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <!-- <div class="col-sm-12">

                                            <div class="form-outline">
                                                <label for="" translate>Photo of academic transcript</label>
                                                <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="  onPhotoSelectedAcademic($event)" />
                                                <label class="small text-muted mt-2" translate>Upload a photo no older
                                                    than three months.</label>

                                            </div>

                                        </div> -->

                              <div
                                class="col-sm-12 box-input-ss"
                                style="width: 100%; margin-left: 0px"
                              >
                                <label for="" translate
                                  >Photo of academic transcript</label
                                >

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputAcademic.click()"
                                  (drop)="onDrop($event, 'academic')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload the
                                    photo of your academic transcript.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputAcademic
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*"
                                    style="display: none"
                                    (change)="onPhotoSelectedAcademic($event)"
                                  />
                                  <label *ngIf="!selectedImageAcademic" class="small text-muted mt-2" translate
                                    >the date of issue of the document must not
                                    be older than 3 months.</label
                                  >
                                  <div *ngIf="selectedImageAcademic">
                                    <img
                                      [src]="selectedImageAcademic"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>

                            <div class="row pb-2">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <div class="form-outline">
                                  <label for="" translate>Score report</label>
                                  <input
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    #fileInputAcademicPdf
                                    accept="application/pdf"
                                    (change)="onSelectedAcademicPdf($event, fileInputAcademicPdf)"
                                  />
                                  <label class="small text-muted mt-2" translate
                                    >upload a pdf of your score report
                                    (optional)</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- aca termina lo detalles de la universidad -->

                    <!-- experiencia laboral -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <div
                              class="d-flex flex-row align-items-center mb-4"
                              style="justify-content: space-between"
                            >

                              <h3
                              class="fw-normal"
                              style="color: black; margin: 0px !important"
                              translate
                              >
                              Courses Completed
                            </h3>
                            <button
                            class="btn btn-primary"
                            type="button"
                            *ngIf="
                            courseExperienceFormArray.length > 0 &&
                            !addNewCourse
                            "
                            (click)="addNewCourse = true"
                            translate
                            >
                            Add new course
                        </button>
                    </div>

                            <form [formGroup]="courseExperienceForm">
                              <div formArrayName="courseExperiences">
                                <ng-container
                                  *ngIf="courseExperienceFormArray.length > 0"
                                >
                                  <div
                                    *ngFor="
                                      let courseExperienceGroup of courseExperienceFormArray.controls;
                                      let i = index
                                    "
                                    [formGroupName]="i"
                                    class="pb-3"
                                  >
                                    <!-- <h3 class="fw-normal mb-2" style="color: black">{{ courseText }} {{ i + 1 }}</h3> -->
                                    <div
                                      class="row pb-3 box-input"
                                      style="width: 100%; margin-left: 0px"
                                    >
                                      <div class="col-sm-6 pb-2">
                                        <div class="form-outline">
                                          <label for="courseName" translate
                                            >Course Name</label
                                          >
                                          <input
                                            type="text"
                                            id="courseName"
                                            class="form-control"
                                            name="courseName"
                                            formControlName="courseName"
                                            readonly
                                          />
                                        </div>
                                      </div>
                                      <div class="col-sm-6 pb-2">
                                        <div class="form-outline">
                                          <label for="institution" translate
                                            >Institution</label
                                          >
                                          <input
                                            type="text"
                                            id="institution"
                                            class="form-control"
                                            name="institution"
                                            formControlName="institution"
                                            readonly
                                          />
                                        </div>
                                      </div>
                                      <div class="col-sm-6 pb-2">
                                        <div class="form-outline">
                                          <label for="dateStart" translate
                                            >Start date</label
                                          >
                                          <input
                                            id="dateStart"
                                            class="form-control"
                                            formControlName="dateStart"
                                            readonly
                                          />
                                        </div>
                                      </div>
                                      <div class="col-sm-6 pb-2">
                                        <div class="form-outline">
                                          <label for="dateEnd" translate
                                            >End date</label
                                          >
                                          <input
                                            id="dateEnd"
                                            class="form-control"
                                            formControlName="dateEnd"
                                            readonly
                                          />
                                        </div>
                                      </div>

                                      <div class="col-sm-12 pb-2">
                                        <div class="form-outline">
                                          <label translate>Description</label>
                                          <textarea
                                            class="form-control"
                                            id="formFileLg"
                                            formControlName="description"
                                            readonly
                                          ></textarea>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 pb-2">
                                        <button
                                          class="btn btn-danger"
                                          style="
                                            margin-top: 1.8rem;
                                            width: 100%;
                                          "
                                          type="button"
                                          (click)="removeCourseExperience(i)"
                                          translate
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <ng-container
                                *ngIf="
                                  courseExperienceFormArray.length == 0 ||
                                  addNewCourse
                                "
                              >
                                <label
                                  class="fw-normal mb-3 mt-4"
                                  style="
                                    color: black;
                                    margin-top: 1.5rem !important;
                                  "
                                  translate
                                  >Add Courses Completed</label
                                >
                                <div
                                  class="row box-input"
                                  style="width: 100%; margin-left: 0px"
                                >
                                  <div class="col-sm-6 pb-2">
                                    <div class="form-outline">
                                      <label for="company" translate
                                        >Course Name</label
                                      >
                                      <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        class="form-control"
                                        formControlName="newCourse"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div class="col-sm-6 pb-2">
                                    <div class="form-outline">
                                      <label for="position" translate
                                        >Institution</label
                                      >
                                      <input
                                        type="text"
                                        id="position"
                                        name="position"
                                        class="form-control"
                                        formControlName="newInstitution"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <!-- <div class="col-sm-6 pb-2">
                                                <div class="form-outline">
                                                    <label for="newYears" translate>Years of Experience</label>
                                                    <input type="number" id="newYears" class="form-control" formControlName="newYears" max="30" autocomplete="off">

                                                    -- <input type="text" id="newYears" class="form-control"
                                                        formControlName="newYears" bsDaterangepicker
                                                        [bsConfig]="{ isAnimated: true }" autocomplete="off"> --
                                                        
                                                </div>
                                            </div> -->
                                  <div class="col-sm-12 pb-2">
                                    <label for="monthStart" translate
                                      >Start date</label
                                    >
                                    <div class="form-outline row">
                                      <div class="col-sm-6">
                                        <mat-select
                                          class="form-control"
                                          id="monthStart"
                                          name="monthStart"
                                          formControlName="monthStart"
                                          required
                                          translate
                                          [(ngModel)]="monthStart"
                                          [placeholder]="'month' | translate"
                                        >
                                          <mat-option
                                            *ngFor="let month of months"
                                            [value]="month"
                                            translate
                                          >
                                            {{ month.name | translate }}
                                          </mat-option>
                                        </mat-select>
                                      </div>
                                      <div class="col-sm-6">
                                        <mat-select
                                          class="form-control"
                                          id="yearStart"
                                          name="yearStart"
                                          formControlName="yearStart"
                                          required
                                          translate
                                          [(ngModel)]="yearStart"
                                          [placeholder]="'year' | translate"
                                        >
                                          <mat-option
                                            *ngFor="let year of years"
                                            [value]="year"
                                            translate
                                          >
                                            {{ year }}
                                          </mat-option>
                                        </mat-select>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 pb-2">
                                    <label for="type_document" translate
                                      >End date (or expected date)</label
                                    >
                                    <div class="form-outline row">
                                      <div class="col-sm-6">
                                        <mat-select
                                          class="form-control"
                                          id="monthEnd"
                                          name="monthEnd"
                                          formControlName="monthEnd"
                                          required
                                          translate
                                          [(ngModel)]="monthEnd"
                                          [placeholder]="'month' | translate"
                                        >
                                          <mat-option
                                            *ngFor="let month of months"
                                            [value]="month"
                                            translate
                                          >
                                            {{ month.name | translate }}
                                          </mat-option>
                                        </mat-select>
                                      </div>
                                      <div class="col-sm-6">
                                        <mat-select
                                          class="form-control"
                                          id="yearEnd"
                                          name="yearEnd"
                                          formControlName="yearEnd"
                                          required
                                          translate
                                          [(ngModel)]="yearEnd"
                                          [placeholder]="'year' | translate"
                                        >
                                          <mat-option
                                            *ngFor="let year of years"
                                            [value]="year"
                                            translate
                                          >
                                            {{ year }}
                                          </mat-option>
                                        </mat-select>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 pb-2">
                                    <div class="form-outline">
                                      <label translate>Description</label>
                                      <textarea
                                        class="form-control"
                                        id="formFileLg"
                                        formControlName="descriptionCourse"
                                      ></textarea>
                                      <!-- <label class="small text-muted mt-2" translate>Example, experience that
                                                        you have as an
                                                        athlete</label> -->
                                    </div>
                                  </div>

                                  <div class="col-sm-12 pb-2">
                                    <button
                                      class="btn btn-primary"
                                      type="button"
                                      style="margin-top: 1.8rem; width: 100%"
                                      (click)="addCourseExperience()"
                                      translate
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </ng-container>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- aca terminar experiencia `laboral -->

                    <!-- aca  comienza de la experiencia laboral -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <div
                              class="d-flex flex-row align-items-center mb-4"
                              style="justify-content: space-between"
                            >
                                <h3
                                class="fw-normal mb-4"
                                style="
                                  color: black;
                                  margin: 0px !important;
                                "
                                translate
                              >
                                Work Experience
                              </h3>
                              <button
                                class="btn btn-primary"
                                type="button"
                                style="width: 55%"
                                *ngIf="
                                  workExperienceFormArray.length > 0 &&
                                  !addNewWork
                                "
                                (click)="addNewWork = true"
                                translate
                              >
                                Add New Work Experience
                              </button>
                            </div>
                            
                            <form [formGroup]="workExperienceForm">
                              <div formArrayName="workExperiences">
                                <ng-container
                                  *ngIf="workExperienceFormArray.length > 0"
                                >
                                  <div
                                    *ngFor="
                                      let workExperienceGroup of workExperienceFormArray.controls;
                                      let i = index
                                    "
                                    [formGroupName]="i"
                                    class="pb-3"
                                  >
                                    <div
                                      class="row pb-3 box-input"
                                      style="width: 100%; margin-left: 0px"
                                    >
                                      <div class="col-sm-6 pb-3">
                                        <div class="form-outline">
                                          <label for="company" translate
                                            >Company</label
                                          >
                                          <input
                                            id="company"
                                            class="form-control"
                                            formControlName="company"
                                            readonly
                                          />
                                        </div>
                                      </div>
                                      <div class="col-sm-6 pb-2">
                                        <div class="form-outline">
                                          <label for="position" translate
                                            >Position</label
                                          >
                                          <input
                                            id="position"
                                            class="form-control"
                                            formControlName="position"
                                            readonly
                                          />
                                        </div>
                                      </div>
                                      <div class="col-sm-6 pb-2">
                                        <div class="form-outline">
                                          <label for="years" translate
                                            >Years of Experience</label
                                          >
                                          <input
                                            id="years"
                                            class="form-control"
                                            formControlName="years"
                                            readonly
                                          />
                                        </div>
                                      </div>
                                      <div class="col-sm-6 pb-2">
                                        <div class="form-outline">
                                          <label for="ejobType" translate
                                            >Employment type</label
                                          >
                                          <input
                                            id="ejobType"
                                            class="form-control"
                                            [value]="workExperienceGroup.get('ejobType')?.value | translate"
                                            readonly
                                          />
                                        </div>
                                      </div>

                                      <div class="col-sm-12 pb-2">
                                        <div class="form-outline">
                                          <label translate>Description</label>
                                          <textarea
                                            class="form-control"
                                            id="formFileLg"
                                            formControlName="description"
                                            readonly
                                          ></textarea>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 pb-2">
                                        <button
                                          class="btn btn-danger"
                                          style="
                                            margin-top: 1.8rem;
                                            width: 100%;
                                          "
                                          type="button"
                                          (click)="removeWorkExperience(i)"
                                          translate
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <ng-container
                                *ngIf="
                                workExperienceFormArray.length == 0 ||
                                  addNewWork
                                "
                              >
                                <label
                                  class="fw-normal mb-3"
                                  style="color: black; margin-top: 1.5rem !important;"
                                  translate>Add New Work Experience</label>
                                <!-- <h3 class="fw-normal mb-3" style="color: black" translate>Add New Work
                                            Experience</h3> -->
                                <div
                                  class="row box-input"
                                  style="width: 100%; margin-left: 0px"
                                >
                                  <div class="col-sm-6 pb-2">
                                    <div class="form-outline">
                                      <label for="newCompany" translate
                                        >Company</label
                                      >
                                      <input
                                        type="text"
                                        id="newCompany"
                                        class="form-control"
                                        formControlName="newCompany"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div class="col-sm-6 pb-2">
                                    <div class="form-outline">
                                      <label for="newPosition" translate
                                        >Position</label
                                      >
                                      <input
                                        type="text"
                                        id="newPosition"
                                        class="form-control"
                                        formControlName="newPosition"
                                        required
                                        translate
                                      />
                                    </div>
                                  </div>
                                  <div class="col-sm-6 pb-2">
                                    <div class="form-outline">
                                      <!-- <label for="newYears" translate>Years of Experience</label>
                                                    <input type="text" id="newYears" class="form-control"
                                                        formControlName="newYears" bsDaterangepicker
                                                        [bsConfig]="{ isAnimated: true }" autocomplete="off"> -->
                                      <label for="newYears" translate
                                        >Years of Experience</label
                                      >
                                      <input
                                        type="number"
                                        id="newYears"
                                        class="form-control"
                                        formControlName="newYears"
                                        max="30"
                                        autocomplete="off"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-sm-6">
                                    <label for="newYears" translate
                                      >Employment type</label
                                    >
                                    <mat-select
                                      class="form-control"
                                      id="jobType"
                                      name="jobType"
                                      formControlName="jobType"
                                      required
                                      translate
                                      [(ngModel)]="jobType"
                                    >
                                      <mat-option
                                        *ngFor="let jobType of jobTypes"
                                        [value]="jobType"
                                        translate
                                      >
                                        {{ jobType | translate }}
                                      </mat-option>
                                    </mat-select>
                                  </div>

                                  <div class="col-sm-12 pb-2">
                                    <div class="form-outline">
                                      <label translate>Description</label>
                                      <textarea
                                        class="form-control"
                                        id="formFileLg"
                                        formControlName="newDescription"
                                      ></textarea>
                                    </div>
                                  </div>

                                  <div class="col-sm-6 pb-2">
                                    <button
                                      class="btn btn-primary"
                                      type="button"
                                      style="margin-top: 1.8rem; width: 40%"
                                      (click)="addWorkExperience()"
                                      translate
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </ng-container>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- aca termina la experiencia laboral -->

                    <!-- aca empiezan detalles personales -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3 class="fw-normal mb-3" translate>
                              Personal Details
                            </h3>
                            <div class="row pb-3">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <div class="form-outline">
                                  <label for="" translate
                                    >Upload your video</label
                                  >
                                  <input
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="video/*"
                                    #fileInputVideo
                                    (change)="onPhotoSelectedVideo($event)"
                                  />
                                  <label class="small text-muted mt-2" translate
                                    >Upload a video presentation in which you address the camera to explain why they 
                                    should buy your tokens and what you can offer. 
                                    The video should be no longer than one minute.</label
                                  >
                                  <label for="" class="small text-muted mt-2" translate
                                    >This field is optional but completing it generates more confidence in sponsors.</label>
                                </div>

                                <!-- <label translate>Upload your video</label> -->

                                <!-- <div class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"  
                                                    (click)="fileInput.click()" 
                                                    (drop)="onDrop($event, 'photoIdFront')" 
                                                    (dragover)="onDragOver($event)">
                                                <label translate>Drag and drop or click here to upload the photo of your document.</label>
                                                    <input #fileInput class="form-control" id="formFileLg" type="file" accept="image/*" style="display: none"
                                                    (change)="onPhotoSelectedPhotoId($event)" />
                                                <label class="small text-muted mt-2" translate>Upload your video
                                                    ID.</label>
                                                    <img [src]="selectedImage" *ngIf="selectedImage"  style="max-width: 200px; max-height: 200px;" />

                                            </div> -->
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <div class="form-outline">
                                  <label for="" translate
                                    >Upload your pdf</label>
                                  <input
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    #fileInputSelectedPdf
                                    accept="application/pdf"
                                    (change)="onPhotoSelectedPdf($event, fileInputSelectedPdf)"
                                  />
                                  <label class="small text-muted mt-2" translate
                                    >Upload a pdf presentation of
                                    yourself.</label>
                                    <label for="" translate class="small text-muted mt-2"
                                    >This field is optional but completing it generates more confidence in sponsors.</label>
                                </div>

                                <!-- <label translate>Upload your pdf</label>

                                            <div class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"  
                                                        (click)="fileInput.click()" 
                                                        (drop)="onDrop($event, 'photoIdFront')" 
                                                        (dragover)="onDragOver($event)">
                                                <label translate>Drag and drop or click here to upload the photo of your document.</label>
                                                    <input #fileInput class="form-control" id="formFileLg" type="file" accept="image/*" style="display: none"
                                                    (change)="onPhotoSelectedPhotoId($event)" />
                                                <label class="small text-muted mt-2" translate>Upload your pdf
                                                    ID.</label>
                                                    <img [src]="selectedImage" *ngIf="selectedImage"  style="max-width: 200px; max-height: 200px;" />

                                            </div> -->
                              </div>
                            </div>

                            <div class="row pb-4">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <div class="form-outline">
                                  <label translate
                                    >Experience in the tokens you will
                                    sell</label
                                  >
                                  <textarea
                                    class="form-control"
                                    id="formFileLg"
                                    required
                                    formControlName="experience"
                                  ></textarea>
                                  <label class="small text-muted mt-2" translate
                                    >Example, if you are a programmer you have
                                    experience in PHP, JavaScript, Angular,
                                    React.</label
                                  >
                                </div>
                              </div>
                            </div>

                            <div
                              class="form-check d-flex justify-content-start mb-4 pb-3"
                            >
                              <input
                                class="form-check-input me-3"
                                type="checkbox"
                                id="form2Example3c"
                                formControlName="accept_term"
                                [(ngModel)]="termsAndConditions"
                              />
                              <label
                                style="display: flex; gap: 5px"
                                class="form-check-label"
                                for="form2Example3"
                              >
                                <p translate>I do accept the</p>
                                <a><u
                                      (click)="openLegalWarnings()"
                                      style="cursor: pointer"
                                      translate
                                      >Terms and Conditions</u></a>
                                <p translate>of your site.</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- aca terminan detalles personales -->

                    <button
                      [disabled]="!termsAndConditions"
                      type="submit"
                      class="btn btn-primary"
                      data-mdb-ripple-color="dark"
                      translate
                    >
                      Register
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="currentUser.role === 1003">
  <div
    *ngIf="!formSportsman"
    class="container paddingcontainer d-flex justify-content-center align-items-center flex-column"
    style="padding-top: 8rem"
  >
    <p class="h2 previewTitle" translate>
      Register to Tokenize Your Sports Career
    </p>

    <div class="py-5 previewMessage" style="width: 60%; text-align: justify">
      <p translate>
        Welcome to the system that allows you to finance your sports career in
        an innovative way! Complete this form to become a seller of tokens that
        represent your future income.
      </p>
      <p translate>
        By joining our community, you will be able to share your successes with
        sponsors who believe in your potential. After submitting your
        application, our team of experts will review your profile to ensure that
        your track record is a valuable asset to our investors. We will notify
        you as soon as your application is approved.
      </p>
      <p translate>
        Seize the opportunity to boost your career and secure a solid financial
        future!
      </p>
      <br />
      <a [routerLink]="['/avisoslegales']" target="_blank" translate
        >Visit terms and conditions of service</a
      >
    </div>

    <div class="text-center mt-4">
      <button
        (click)="formSportsman = !formSportsman"
        class="continue-button"
        type="submit"
        translate
      >
        <span translate> Continue </span>
      </button>
    </div>
  </div>

  <div *ngIf="formSportsman">
    <div class="container paddingcontainer" style="padding-top: 8rem">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-12">
          <p class="h2 previewTitle pb-5 text-center" translate>
            Sportman Registration
          </p>
          <div
            class="card card-registration card-registration-2"
            style="border: none"
          >
            <div class="card-body p-0">
              <form
                [formGroup]="deportistaForm"
                (ngSubmit)="onSubmitDeportista(deportistaForm)"
              >
                <div class="row g-0">
                  <div class="col-lg-6" style="padding: 2rem">
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              General Infomation
                            </h3>
                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >First Name</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="first_name"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label
                                    class="form-label"
                                    for="form3Examplev3"
                                    translate
                                    >Last Name</label
                                  >
                                  <input
                                    type="text"
                                    id="form3Examplev3"
                                    class="form-control"
                                    formControlName="last_name"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label class="form-label" translate
                                    >Email</label
                                  >
                                  <input
                                    type="text"
                                    id="form3Examplev3"
                                    class="form-control"
                                    formControlName="email"
                                  />
                                </div>
                              </div>

                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="city" translate
                                    >Date of birth</label
                                  >
                                  <div class="datepicker date input-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="fecha1"
                                      bsDatepicker
                                      formControlName="date_of_birth"
                                      autocomplete="off"
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text"
                                        ><i class="fa fa-calendar"></i
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div
                                class="col-sm-6 box-input-ss"
                                style="width: 100%; margin-left: 0px !important"
                              >
                                <div class="form-outline">
                                  <label translate>phone</label>

                                  <div class="form-group">
                                    <div class="input-group">
                                      <mat-select
                                        class="form-control"
                                        formControlName="selectedCountry"
                                      >
                                        <mat-option
                                          *ngFor="
                                            let countryCode of countriesCode
                                          "
                                          [value]="countryCode.dial_code"
                                        >
                                          <img
                                            class="flag1"
                                            [src]="
                                              './../../../../assets/images/svg/' +
                                              countryCode.code.toLowerCase() +
                                              '.svg'
                                            "
                                          />
                                          <span class="country-info"
                                            >{{ countryCode.name }} ({{
                                              countryCode.dial_code
                                            }})</span
                                          >
                                        </mat-option>
                                      </mat-select>
                                      <input
                                        class="form-control"
                                        type="tel"
                                        formControlName="phoneNumber"
                                        style="width: 35% !important"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- aca termina la informacion general -->
                    </div>
                    <!-- aca comiennza la informacioon del pais -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate>
                              Address Infomation
                            </h3>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate>address</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="address"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate>house number</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="house_number"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="country" translate>country</label>
                                  <mat-select
                                    class="form-control"
                                    id="country"
                                    name="country"
                                    formControlName="country"
                                    required
                                  >
                                    <mat-option
                                      *ngFor="let country of countries"
                                      [value]="country.name"
                                    >
                                      <img
                                        class="flag1"
                                        [src]="
                                          './../../../../assets/images/svg/' +
                                          country.code.toLowerCase() +
                                          '.svg'
                                        "
                                      />
                                      {{ country.name }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>

                              <div class="col-sm-6 pb-2 box-input-ss">
                                <div class="form-outline">
                                  <label for="province" translate
                                    >Province</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="province"
                                    name="province"
                                    formControlName="province"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="city" translate>city</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="city"
                                    name="city"
                                    formControlName="city"
                                    required
                                  />
                                </div>
                              </div>

                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >Zip Code</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="zipcode"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            


                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- aca terminar la informacion del pais -->

                    <!-- aca empieza document information -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              ID information
                            </h3>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="type_document" translate
                                    >ID type</label
                                  >
                                  <mat-select
                                    class="form-control"
                                    id="type_document"
                                    name="type_document"
                                    formControlName="type_document"
                                    required
                                    translate
                                    [(ngModel)]="selectID"
                                  >
                                    <mat-option
                                      *ngFor="let document of documents"
                                      [value]="document.name"
                                      translate
                                    >
                                      <!-- <img class="flag1"
                                                                [src]="'./../../../../assets/images/svg/' + country.code.toLowerCase() + '.svg'" /> -->
                                      {{ document.name | translate }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>

                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="document" translate
                                    >ID number</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="document"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <!-- <div class="row pb-2">
                                                        <div class="col-sm-12">
                                                        
                                                           <div class="form-outline">
                                                                <label translate>photo</label>
                                                                <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                                    (change)="onPhotoSelectedPhotoId($event)" />
                                                                <label class="small text-muted mt-2" translate>Upload your photo
                                                                    ID.</label>
                                                                
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <div class="row pb-3" *ngIf="selectID">
                                                      <!-- *ngIf="selectID == 'ID Card' || selectID == 'Driver License'" -->
                        
                                                      <div
                                                        class="col-sm-12 box-input-ss"
                                                        style="width: 100%; margin-left: 0px"
                                                      >
                                                        <label translate>Front ID</label>
                        
                                                        <div
                                                          class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                                          (click)="fileInputFrontDocument.click()"
                                                          (drop)="onDrop($event, 'photoIdFront')"
                                                          (dragover)="onDragOver($event)"
                                                        >
                                                          <label translate
                                                            >Drag and drop or click here to upload the
                                                            photo of your document.</label
                                                          >
                                                          <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                                                (change)="onPhotoSelectedPhotoId($event)" /> -->
                                                          <input
                                                            #fileInputFrontDocument
                                                            class="form-control"
                                                            id="formFileLg"
                                                            type="file"
                                                            accept="image/*,application/pdf"
                                                            style="display: none"
                                                            (change)="onPhotoSelectedPhotoId($event)"
                                                          />
                                                          <label *ngIf="!selectedImage" class="small text-muted mt-2" translate
                                                            >Upload your photo ID.</label
                                                          >
                                                          <div *ngIf="selectedImage">
                                                            <img
                                                              [src]="selectedImage"
                                                              style="
                                                                max-width: 200px;
                                                                max-height: 200px;
                                                              "
                                                            />
                                                            <i
                                                              class="fa fa-times cancel-images"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </div>
                                                        </div>
                                                        <label class="small text-muted" translate
                                                          >Upload a file no bigger than 10mb.</label>
                                                      </div>
                                                    </div>
                        
                                                    <div
                                                      class="row pb-3"
                                                      *ngIf="
                                                        selectID == 'ID Card' ||
                                                        selectID == 'Driver License'
                                                      "
                                                    >
                                                      <div
                                                        class="col-sm-12 box-input-ss"
                                                        style="width: 100%; margin-left: 0px"
                                                      >
                                                        <label translate>Back ID</label>
                        
                                                        <div
                                                          class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                                          (click)="fileInputBackDocument.click()"
                                                          (drop)="onDrop($event, 'photoIdBack')"
                                                          (dragover)="onDragOver($event)"
                                                        >
                                                          <label translate
                                                            >Drag and drop or click here to upload the
                                                            photo of your document.</label
                                                          >
                                                          <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                                                (change)="onPhotoSelectedPhotoId($event)" /> -->
                                                          <input
                                                            #fileInputBackDocument
                                                            class="form-control"
                                                            id="formFileLg"
                                                            type="file"
                                                            accept="image/*,application/pdf"
                                                            style="display: none"
                                                            (change)="
                                                              onPhotoSelectedPhotoIdBack($event)
                                                            "
                                                          />
                                                          <label *ngIf="!selectedImagePhotoIdBack" class="small text-muted mt-2" translate
                                                            >Upload your photo ID.</label
                                                          >
                                                          <div *ngIf="selectedImagePhotoIdBack">
                                                            <img
                                                              [src]="selectedImagePhotoIdBack"
                                                              style="
                                                                max-width: 200px;
                                                                max-height: 200px;
                                                              "
                                                            />
                                                            <i
                                                              class="fa fa-times cancel-images"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </div>
                                                        </div>
                                                        <label class="small text-muted" translate
                                                          >Upload a file no bigger than 10mb.</label>
                                                      </div>
                                                    </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- aca termina document information  -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              Aditional Infomation
                            </h3>
                            <!-- <div class="row pb-2">
                                        <div class="col-sm-12">

                                            <div class="form-outline">
                                                <label translate>Photo of the day of register</label>
                                                <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)=" onPhotoSelectedRegister($event)" />
                                                <label translate>Upload a photo the day of register.</label>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="row pb-2">
                                        <div class="col-sm-12">

                                            <div class="form-outline">
                                                <label translate>Utility bill</label>
                                                <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="  onPhotoSelectedUtilityBill($event)" />
                                                <label class="small text-muted mt-2" translate>Upload your Utility
                                                    bill.</label>

                                            </div>
                                        </div>
                                    </div> -->

                            <div class="row pb-3">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <label translate
                                  >Photo of the day of register</label
                                >

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputRegisterDay.click()"
                                  (drop)="onDrop($event, 'register')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload the
                                    photo.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputRegisterDay
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*"
                                    style="display: none"
                                    (change)="onPhotoSelectedRegister($event)"
                                  />
                                  <label *ngIf="!selectedImageRegisterDay" class="small text-muted mt-2" translate
                                    >must be facing forward keeping a sign with the date on which they make the registration.</label>
                                  <div *ngIf="selectedImageRegisterDay">
                                    <img
                                      [src]="selectedImageRegisterDay"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>

                            <div class="row pb-2">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <label translate>Utility bill</label>

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputUtilityBill.click()"
                                  (drop)="onDrop($event, 'utilityBill')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload a photo of a utility bill in your name.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputUtilityBill
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*"
                                    style="display: none"
                                    (change)="
                                      onPhotoSelectedUtilityBill($event)
                                    "
                                  />
                                  <label *ngIf="!selectedImageUtilityBill" class="small text-muted mt-2" translate
                                    >It must match the address provided to verify your residency.</label
                                  >
                                  <div *ngIf="selectedImageUtilityBill">
                                    <img
                                      [src]="selectedImageUtilityBill"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- aca comienza la informacion del deporte -->
                  <div class="col-lg-6" style="padding: 2rem">
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3 class="fw-normal mb-3" translate>
                              Sport Details
                            </h3>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >Name of the club or institution</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="institution_sport"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label
                                    class="form-label"
                                    for="form3Examplev3"
                                    translate
                                    >address</label
                                  >
                                  <input
                                    type="text"
                                    id="form3Examplev3"
                                    class="form-control"
                                    required
                                    formControlName="sport_address"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="country" translate>country</label>
                                  <mat-select
                                    class="form-control"
                                    id="country"
                                    name="country"
                                    formControlName="sport_country"
                                    required
                                  >
                                    <mat-option
                                      *ngFor="let country of countries"
                                      [value]="country.name"
                                    >
                                      <img
                                        class="flag1"
                                        [src]="
                                          './../../../../assets/images/svg/' +
                                          country.code.toLowerCase() +
                                          '.svg'
                                        "
                                      />
                                      {{ country.name }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>

                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="province" translate>Province</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="province"
                                    name="province"
                                    formControlName="sport_province"
                                    required
                                  />
                                </div>
                              </div>



                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="city" translate>city</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="city"
                                    name="city"
                                    formControlName="sport_city"
                                    required
                                  />
                                </div>
                              </div>

                              <div class="col-sm-6 box-input-ss">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >Zip Code</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="zipcode_sport"
                                    required
                                  />
                                </div>
                              </div>

                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input-ss">
                                <div class="form-group">
                                  <label for="category_sportsman" translate
                                    >sports</label
                                  >

                                  <mat-select
                                    class="form-control"
                                    formControlName="selectedSportsmanOption"
                                    required
                                    id="selectedSportsmanOption"
                                  >
                                    <mat-option
                                      *ngFor="let option of deportistaOptions"
                                      [value]="option.name"
                                      >{{ option.name }}</mat-option
                                    >
                                  </mat-select>
                                </div>
                              </div>
                              <div class="col-sm-6 box-input-ss">
                                <!-- <div class="form-outline">
                                                <label for="city" translate>Year of start</label>
                                                <div class="datepicker date input-group">
                                                    <input type="text" class="form-control" id="fecha1" bsDatepicker formControlName="year_start" autocomplete="off"> 
                                                    <div class="input-group-append">
                                                        <span class="input-group-text"><i
                                                                class="fa fa-calendar"></i></span>
                                                    </div>
                                                </div>
                                            </div> -->

                                <div class="form-outline">
                                  <label for="city" translate
                                    >Year of start</label
                                  >

                                  <!-- /*
                                                <mat-select class="form-control"
                                                formControlName="year_start" required
                                                id="selectedSportsmanOption">
                                                <mat-option *ngFor="let option of deportistaOptions"
                                                    [value]="option.title">{{ option.title }}</mat-option>
                                            </mat-select> */ -->

                                  <select
                                    class="form-control"
                                    formControlName="year_start"
                                  >
                                    <option
                                      *ngFor="let year of years"
                                      [value]="year"
                                    >
                                      {{ year }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-12 box-input" style="width: 100%">
                                <div class="form-outline">
                                  <label translate
                                    >Do you won any trophe?</label
                                  >
                                  <div>
                                    <input
                                      type="radio"
                                      id="yes"
                                      name="answer"
                                      value="Yes"
                                      formControlName="answer"
                                      required
                                    />
                                    <label
                                      translate
                                      for="yes"
                                      style="padding-left: 10px"
                                      >Yes</label
                                    >
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      id="no"
                                      name="answer"
                                      value="No"
                                      formControlName="answer"
                                    />
                                    <label for="no" style="padding-left: 10px"
                                      >No</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row pb-2">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px"
                              >
                                <label translate>ID of the federated athlete</label>

                                <!-- <div class="form-outline">
                                                <label translate>Athlete's identity photo or certificate</label>
                                                <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="  onPhotoSelectedAcademic($event)" /> <label
                                                    class="small text-muted mt-2" translate>Upload your athlete ID or
                                                    certificate.</label>

                                            </div> -->

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputAcademic.click()"
                                  (drop)="onDrop($event, 'academic')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Athlete's identity photo or
                                    certificate</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputAcademic
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*"
                                    style="display: none"
                                    (change)="onPhotoSelectedAcademic($event)"
                                  />
                                  <label *ngIf="!selectedImageAcademic" class="small text-muted mt-2" translate
                                    >Upload your athlete ID or
                                    certificate.</label
                                  >
                                  <div *ngIf="selectedImageAcademic">
                                    <img
                                      [src]="selectedImageAcademic"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- aca termina la informacion del deporte -->

                    <!-- informacion del trofero-->
                    <div *ngIf="deportistaForm.get('answer')?.value === 'Yes'">
                      <div class="card principal-box">
                        <div class="card-body p-0">
                          <div class="row g-0">
                            <div style="padding: 2rem">
                              <h3 class="fw-normal mb-3" translate>
                                Trophy Details
                              </h3>
                              <form [formGroup]="winTrophyForm">
                                <div formArrayName="trophiesWon">
                                  <ng-container
                                    *ngIf="winTrophyFormArray.length > 0"
                                  >
                                    <div
                                      *ngFor="
                                        let trophiesWonGroup of winTrophyFormArray.controls;
                                        let i = index
                                      "
                                      [formGroupName]="i"
                                      class="mb-3 box-input"
                                      style="width: 100%; margin-left: 0px"
                                    >
                                      <h3
                                        class="fw-normal mb-2"
                                        style="color: black"
                                      >
                                        {{
                                          trophiesWonGroup.get("trophyName")
                                            ?.value
                                        }}
                                        -
                                        {{
                                          trophiesWonGroup.get("winDate")?.value
                                        }}
                                      </h3>
                                      <div class="row">
                                        <div class="col-sm-12 pb-2" *ngIf="trophiesWonGroup.get('description')?.value">
                                          <div class="form-outline">
                                            <label translate>Description</label>
                                            <textarea
                                              class="form-control"
                                              id="formFileLg"
                                              formControlName="description"
                                              readonly
                                            ></textarea>
                                          </div>
                                        </div>
                                        <div
                                          class="col-sm-12 pb-2 d-flex justify-content-center align-items-center"
                                        >
                                          <img
                                            [src]="trophiesWonGroup.get('preview')?.value"
                                            style="
                                              max-width: 200px;
                                              max-height: 200px;
                                            "
                                          />
                                        </div>
                                        <div class="col-sm-12 pb-2">
                                          <button
                                            class="btn btn-danger"
                                            style="
                                              margin-top: 1.8rem;
                                              width: 100%;
                                            "
                                            type="button"
                                            (click)="removeWinTrophy(i)"
                                            translate
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                                <h3
                                  class="fw-normal mb-3"
                                  style="color: black"
                                  translate
                                >
                                  Add new trophy won
                                </h3>
                                <div
                                  class="row box-input"
                                  style="width: 100%; margin-left: 0px"
                                >
                                  <div class="col-sm-6 pb-2">
                                    <div class="form-outline">
                                      <label for="company" translate
                                        >Trophy Name</label
                                      >
                                      <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        class="form-control"
                                        formControlName="newTrophy"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div class="col-sm-6 pb-2">
                                    <div class="form-outline">
                                      <label for="winDate" translate
                                        >Win date</label
                                      >
                                      <mat-select
                                          class="form-control"
                                          id="yearStart"
                                          name="yearStart"
                                          formControlName="winDate"
                                          required
                                          [(ngModel)]="yearStart"
                                        >
                                          <mat-option
                                            *ngFor="let year of years"
                                            [value]="year"
                                            translate
                                          >
                                            {{ year }}
                                          </mat-option>
                                        </mat-select>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 pb-2">
                                    <div class="form-outline">
                                      <label translate>Description</label>
                                      <textarea
                                        class="form-control"
                                        id="formFileLg"
                                        formControlName="description"
                                      ></textarea>
                                    </div>
                                  </div>

                                  <div class="col-sm-12">
                                    <label translate>Trophy Photo</label>

                                    <div
                                      class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                      (click)="fileInput.click()"
                                      (drop)="onDrop($event, 'trophy')"
                                      (dragover)="onDragOver($event)"
                                    >
                                      <label translate
                                        >Drag and drop or click here to upload
                                        the trophy photo.</label
                                      >
                                      <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                                        (change)="onPhotoSelectedPhotoId($event)" /> -->
                                      <input
                                        #fileInput
                                        class="form-control"
                                        id="formFileLg"
                                        type="file"
                                        accept="image/*"
                                        style="display: none"
                                        (change)="onPhotoSelectedTrophy($event)"
                                      />
                                      <!-- <label class="small text-muted mt-2" translate>Upload your photo
                                                                        ID.</label> -->
                                      <div *ngIf="selectedImageTrophy">
                                        <img
                                          [src]="selectedImageTrophy"
                                          style="
                                            max-width: 200px;
                                            max-height: 200px;
                                          "
                                        />
                                        <i
                                          class="fa fa-times cancel-images"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                    <label class="small text-muted" translate
                                      >Upload a file no bigger than 10mb.</label>
                                  </div>

                                  <div class="col-sm-12 pb-2">
                                    <button
                                      class="btn btn-primary"
                                      type="button"
                                      style="margin-top: 1.8rem; width: 100%"
                                      (click)="addTrophy()"
                                      translate
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--aca termina la infromacion del trofeo -->

                    <!-- aca empieza la informacion personal -->
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3 class="fw-normal mb-3" translate>
                              Personal Details
                            </h3>
                            <div class="row pb-3">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px !important"
                              >
                                <div class="form-outline">
                                  <label for="" translate
                                    >Upload your video</label
                                  >
                                  <input
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="video/*"
                                    (change)="onPhotoSelectedVideo($event)"
                                  />
                                  <label class="small text-muted mt-2" translate
                                    >Upload a video presentation in which you
                                    address the camera to explain why they
                                    should buy your tokens, what you can offer
                                    and your highlights in your sport. The video
                                    should be no longer than one minute.</label
                                  >
                                  <label for="" class="small text-muted mt-2" translate
                                    >This field is optional but completing it generates more confidence in sponsors.</label>
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px !important"
                              >
                                <div class="form-outline">
                                  <label for="" translate
                                    >Upload your pdf</label
                                  >
                                  <input
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    #fileInputSelectedPdf
                                    accept="application/pdf"
                                    (change)="onPhotoSelectedPdf($event, fileInputSelectedPdf)"
                                  />
                                  <label class="small text-muted mt-2" translate
                                    >Upload a pdf presentation of
                                    yourself.</label
                                  >
                                  <label for="" class="small text-muted mt-2" translate
                                    >This field is optional but completing it generates more confidence in sponsors.</label>
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 100%; margin-left: 0px !important"
                              >
                                <div class="form-outline">
                                  <label translate>Experience</label>
                                  <textarea
                                    class="form-control"
                                    id="formFileLg"
                                    formControlName="experience"
                                  ></textarea>
                                  <label class="small text-muted mt-2" translate
                                    >Example, experience that you have as an
                                    athlete</label
                                  >
                                </div>
                              </div>
                            </div>

                            <div
                              class="form-check d-flex justify-content-start mb-4 pb-3"
                            >
                              <input
                                class="form-check-input me-3"
                                type="checkbox"
                                id="form2Example3c"
                                formControlName="accept_term"
                                [(ngModel)]="termsAndConditions"
                              />
                              <label
                                style="display: flex; gap: 5px"
                                class="form-check-label"
                                for="form2Example3"
                              >
                              <p translate>I do accept the</p>
                              <a><u
                                    (click)="openLegalWarnings()"
                                    style="cursor: pointer"
                                    translate
                                    >Terms and Conditions</u></a>
                              <p translate>of your site.</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      [disabled]="!termsAndConditions"
                      type="submit"
                      class="btn btn-primary"
                      data-mdb-ripple-color="dark"
                      translate
                    >
                      Register
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="currentUser.role === 1004">
  <div
    *ngIf="!formPartner"
    class="container paddingcontainer d-flex justify-content-center align-items-center flex-column"
    style="padding-top: 8rem"
  >
    <p class="h2 previewTitle" translate>Join Our Community of Sponsors</p>

    <div class="py-5 previewMessage" style="width: 60%; text-align: justify">
      <p translate>
        Welcome to the exciting world of talent investment! Please complete this
        form to become a sponsor at JuniorsTime!
      </p>
      <p translate>
        Your support is essential to help drive the success of athletes and
        students. After submitting your application, our team of experts will
        review your profile to make sure you are a perfect fit for our
        community. We will notify you as soon as your application is approved.
      </p>
      <p translate>
        Thank you for being part of this revolution in funding future talent!
      </p>
      <br />
      <a [routerLink]="['/avisoslegales']" target="_blank" translate
        >Visit terms and conditions of service</a
      >
    </div>

    <div class="text-center mt-4">
      <button
        (click)="formPartner = !formPartner"
        class="continue-button"
        type="submit"
        translate
      >
        <span translate> Continue </span>
      </button>
    </div>
  </div>

  <div *ngIf="formPartner">
    <div class="container paddingcontainer" style="padding-top: 8rem">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-12">
          <div>
            <!--  class="card principal-box" -->
            <!-- border-radius: 15px;
                    background-color: #F0F0F0;
                    border: 1px solid black;
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);  -->

            <p class="h2 previewTitle pb-5 text-center" translate>
              Sponsors Registration
            </p>

            <div class="card-body p-0">
              <div class="row g-0">
                <div>
                  <form
                    [formGroup]="patrocinadorForm"
                    (ngSubmit)="onSubmitPatrocinador(patrocinadorForm)"
                  >
                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              General Infomation
                            </h3>
                            <div class="row pb-3">
                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >First Name</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="first_name"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label
                                    class="form-label"
                                    for="form3Examplev3"
                                    translate
                                    >Last Name</label
                                  >
                                  <input
                                    formControlName="last_name"
                                    type="text"
                                    class="form-control"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row pb-3">
                              <!-- <div class="col-sm-6 box-input">
                                            <div class="form-outline">
                                                <label for="username" translate>address</label>
                                                <input type="text" class="form-control" formControlName="address"
                                                    required>
                                            </div>
                                        </div> -->
                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label for="city" translate
                                    >Date of birth</label
                                  >
                                  <div class="datepicker date input-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      formControlName="date_of_birth"
                                      id="fecha1"
                                      bsDatepicker
                                      autocomplete="off"
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text"
                                        ><i class="fa fa-calendar"></i
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label
                                    class="form-label"
                                    for="form3Examplev3"
                                    translate
                                    >Email</label
                                  >
                                  <input
                                    type="text"
                                    id="form3Examplev3"
                                    class="form-control"
                                    formControlName="email"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row pb-3">
                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label translate>phone</label>
                                  <div class="form-group">
                                    <div class="input-group">
                                      <mat-select
                                        class="form-control"
                                        formControlName="selectedCountry"
                                      >
                                        <mat-option
                                          *ngFor="
                                            let countryCode of countriesCode
                                          "
                                          [value]="countryCode.dial_code"
                                        >
                                          <img
                                            class="flag1"
                                            [src]="
                                              './../../../../assets/images/svg/' +
                                              countryCode.code.toLowerCase() +
                                              '.svg'
                                            "
                                          />
                                          <span class="country-info"
                                            >{{ countryCode.name }} ({{
                                              countryCode.dial_code
                                            }})</span
                                          >
                                        </mat-option>
                                      </mat-select>
                                      <input
                                        class="form-control"
                                        type="tel"
                                        formControlName="phoneNumber"
                                        placeholder="Enter phone number"

                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              Address Infomation
                            </h3>
                            <div class="row pb-3">
                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label for="country" translate>country</label>
                                  <mat-select
                                    class="form-control"
                                    id="country"
                                    name="country"
                                    formControlName="country"
                                    required
                                  >
                                    <mat-option
                                      *ngFor="let country of countries"
                                      [value]="country.name"
                                    >
                                      <img
                                        class="flag1"
                                        [src]="
                                          './../../../../assets/images/svg/' +
                                          country.code.toLowerCase() +
                                          '.svg'
                                        "
                                      />
                                      {{ country.name }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>

                              <div class="col-sm-6 pb-2 box-input">
                                <div class="form-outline">
                                  <label for="province" translate
                                    >Province</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="province"
                                    name="province"
                                    formControlName="province"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div class="col-sm-6 pb-2 box-input">
                                <div class="form-outline">
                                  <label for="city" translate>city</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="city"
                                    name="city"
                                    formControlName="city"
                                    required
                                  />
                                </div>
                              </div>

                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label for="username" translate
                                    >Zip Code</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="zipcode"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row pb-3">
                              <div
                                class="col-sm-12 box-input"
                                style="width: 98.5% !important"
                              >
                                <div class="form-outline">
                                  <label for="username" translate
                                    >address</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="address"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              ID information
                            </h3>

                            <div class="row pb-3">
                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label for="type_document" translate
                                    >ID type</label
                                  >
                                  <mat-select
                                    class="form-control"
                                    id="type_document"
                                    name="type_document"
                                    formControlName="type_document"
                                    required
                                    translate
                                    [(ngModel)]="selectID"
                                  >
                                    <mat-option
                                      *ngFor="let document of documents"
                                      [value]="document.name"
                                    >
                                      {{ document.name | translate }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>

                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label for="document" translate
                                    >ID number</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="document"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="row pb-3"
                              *ngIf="
                                selectID == 'ID Card' ||
                                selectID == 'Driver License'
                              "
                            >
                              <div class="col-sm-6 box-input">
                                <label translate>Front ID</label>

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputFrontDocument.click()"
                                  (drop)="onDrop($event, 'photoIdFront')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload the
                                    photo of your document.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputFrontDocument
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*,application/pdf"
                                    style="display: none"
                                    (change)="onPhotoSelectedPhotoId($event)"
                                  />
                                  <label class="small text-muted mt-2" translate *ngIf="!selectedImage"
                                    >Upload your photo ID.</label
                                  >
                                  <div *ngIf="selectedImage">
                                    <img
                                      [src]="selectedImage"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  
                                </div>
                                <label class="small text-muted" translate
                                >Upload a file no bigger than 10mb.</label>
                              </div>

                              <div class="col-sm-6 box-input">
                                <label translate>Back ID</label>

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputBackDocument.click()"
                                  (drop)="onDrop($event, 'photoIdBack')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload the
                                    photo of your document.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputBackDocument
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*,application/pdf"
                                    style="display: none"
                                    (change)="
                                      onPhotoSelectedPhotoIdBack($event)
                                    "
                                  />
                                  <label class="small text-muted mt-2" translate
                                  *ngIf="!selectedImagePhotoIdBack">Upload your photo ID.</label
                                  >
                                  <div *ngIf="selectedImagePhotoIdBack">
                                    <img
                                      [src]="selectedImagePhotoIdBack"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>

                            <div
                              class="row pb-3"
                              *ngIf="selectID == 'Passport'"
                            >
                              <div
                                class="col-sm-12 box-input"
                                style="width: 98.5% !important"
                              >
                                <label translate>Front ID</label>

                                <div
                                  class="form-outline d-flex flex-column justify-content-center align-items-center add-image-box"
                                  (click)="fileInputFrontDocument.click()"
                                  (drop)="onDrop($event, 'photoIdFront')"
                                  (dragover)="onDragOver($event)"
                                >
                                  <label translate
                                    >Drag and drop or click here to upload the
                                    photo of your document.</label
                                  >
                                  <!-- <input class="form-control" id="formFileLg" type="file" accept="image/*"
                                                    (change)="onPhotoSelectedPhotoId($event)" /> -->
                                  <input
                                    #fileInputFrontDocument
                                    class="form-control"
                                    id="formFileLg"
                                    type="file"
                                    accept="image/*,application/pdf"
                                    style="display: none"
                                    (change)="onPhotoSelectedPhotoId($event)"
                                  />
                                  <label class="small text-muted mt-2" translate
                                  *ngIf="!selectedImage">Upload your photo ID.</label
                                  >
                                  <div *ngIf="selectedImage">
                                    <img
                                      [src]="selectedImage"
                                      style="
                                        max-width: 200px;
                                        max-height: 200px;
                                      "
                                    />
                                    <i
                                      class="fa fa-times cancel-images"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <label class="small text-muted" translate
                                    >Upload a file no bigger than 10mb.</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card principal-box">
                      <div class="card-body p-0">
                        <div class="row g-0">
                          <div style="padding: 2rem">
                            <h3
                              class="fw-normal mb-3"
                              style="color: black"
                              translate
                            >
                              Other Infomation
                            </h3>

                            <div class="row pb-4">
                              <!-- <div class="col-sm-6 box-input">
                                            <div class="form-outline">
                                                <label for="username" translate>Zip Code</label>
                                                <input type="text" class="form-control" formControlName="zipcode"
                                                    required>
                                            </div>
                                        </div> -->
                              <div class="col-sm-6 box-input">
                                <div class="form-outline">
                                  <label translate
                                    >What do you like to invest?</label
                                  >
                                  <div>
                                    <input
                                      type="radio"
                                      id="yes"
                                      name="answer"
                                      value="Student"
                                      formControlName="invest"
                                      required
                                    />
                                    <label
                                      translate
                                      for="yes"
                                      style="padding-left: 10px"
                                      >Student</label
                                    >
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      id="no"
                                      name="answer"
                                      value="Athlete"
                                      formControlName="invest"
                                      required
                                    />
                                    <label
                                      for="no"
                                      translate
                                      style="padding-left: 10px"
                                      >Athlete</label
                                    >
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      id="no"
                                      name="answer"
                                      value="Both, studends and athletes"
                                      formControlName="invest"
                                      required
                                    />
                                    <label
                                      for="no"
                                      translate
                                      style="padding-left: 10px"
                                      >Both</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-check d-flex justify-content-start mb-4 pb-3"
                    >
                      <input
                        class="form-check-input me-3"
                        type="checkbox"
                        [(ngModel)]="termsAndConditions"
                        id="form2Example3c"
                        formControlName="accept_term"
                      />
                      <label
                        style="display: flex; gap: 5px"
                        class="form-check-label"
                        for="form2Example3"
                      >
                        <p translate>I do accept the</p>
                        <a>
                          <u
                            (click)="openLegalWarnings()"
                            style="cursor: pointer"
                            translate
                            >Terms and Conditions</u
                          ></a>
                        <p translate>of your site.</p>
                      </label>
                    </div>

                    <button
                      [disabled]="!termsAndConditions"
                      type="submit"
                      class="btn btn-primary"
                      data-mdb-ripple-color="dark"
                      translate
                    >
                      Register
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<app-loader *ngIf="spinner"></app-loader>