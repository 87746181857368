<div class="appContainer2">
    <div class="nft-main">

        <!-- <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100" style="background: #0000FF">
            <div class="v-center" style="background-size: cover;">
                <div class="container gpt3__header " style="background-size: cover;">
                    <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                        <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                            style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                            translate>Student
                        </h1>
                        <div class="spacer-10" style="background-size: cover;"></div>
                        <a href="javascript:void(0)" routerLink="/marketplace"
                            class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                            style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                            translate>explore</a>
                        <div class="mb-sm-30" style="background-size: cover;"></div>
                    </div>
                    <div class="gpt3__header-image">
                        <div class="container positionRelative"  >
                            <img  src="../../../assets/images/backhome.png" alt="Imagen de fondo"
                                class="background-image">
                            <img src="./../../../assets/images/home.png" alt="Imagen superior"
                                class="overlay-image">
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100"
        style="background-color: #0000FF;">

        <div class="principalImage">
            <div class="gpt3__header-image">
                <div class="container positionRelative"  >
                    <img  src="../../../assets/images/backhome.png" alt="Imagen de fondo"
                        class="background-image">
                    <img src="./../../../assets/images/home.png" alt="Imagen superior"
                        class="overlay-image">
                </div>
            </div>
        </div>

        <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
              </ol>
            <!-- Slides -->
            <div class="carousel-inner">
                <div class="carousel-item active " data-interval="5000"
                    style="background: #0000FF; width: 100%; ">
                    <div class="v-center" style="background-size: cover;">
                        <div class="container gpt3__header " style="background-size: cover;">
                            <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                <div class="spacer-single" style="background-size: cover;"></div>
                                <div class="spacer-10" style="background-size: cover;"></div>
                                <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>Invest in your future with skill NFTs</h1>
                                <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>
                                    Transform your knowledge into digital assets that fund your education or career.</p>

                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <a href="javascript:void(0)" routerLink="/marketplace"
                                        class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                        style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                        translate>explore</a>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-interval="5000"
                    style="background: #0000FF; width: 100%; ">
                    <div class="v-center" style="background-size: cover;">
                        <div class="container gpt3__header " style="background-size: cover;">
                            <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                <div class="spacer-single" style="background-size: cover;"></div>
                                <div class="spacer-10" style="background-size: cover;"></div>
                                <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>Your education, your career, your NFTs</h1>
                                <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>
                                    Your NFTs represent your hours of study and experience, creating unique opportunities.</p>

                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <a href="javascript:void(0)" routerLink="/marketplace"
                                        class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                        style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                        translate>explore</a>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item " data-interval="5000"
                style="background: #0000FF; width: 100%; ">
                <div class="v-center" style="background-size: cover;">
                    <div class="container gpt3__header " style="background-size: cover;">
                        <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                            <div class="spacer-single" style="background-size: cover;"></div>
                            <div class="spacer-10" style="background-size: cover;"></div>
                            <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                translate>Turn your experience into real opportunities</h1>
                            <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                translate>
                                Sell your skills as NFTs and open doors to a promising future.</p>

                                <div class="spacer-10" style="background-size: cover;"></div>
                                <a href="javascript:void(0)" routerLink="/marketplace"
                                    class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                    style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                    translate>explore</a>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            <div class="mb-sm-30" style="background-size: cover;"></div>
                        </div>
                    </div>
                </div>
            </div>

            </div>
        </div>
    </section>

        <section style="background-color: white;" class="sizeSectionA">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">How does it work?</h1>
                    </div>
                </div>
            </div>
            <div class="container gpt3__header " style="background-size: cover; padding: 50px 0 10px 0;" >
                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                    <div class="text">
                        <h2 translate style="color: black;">Transform Your Experience into Future Income</h2>
                    </div>
                    <p class="changetextsize" translate>
                        On this platform, students like you can sell NFTs that represent hours of your expertise in your field of study. Why wait to monetize your knowledge? Sign up, create your NFTs and let sponsors invest in your success.
                    </p>
                    <button href="javascript:void(0)" class="newbtn btnsize3" style="    background: transparent;
                        color: black;
                        padding: 0;     font-weight: 500;" translate>explore</button>
                </div>
                <div class="gpt3__header-image1">
                    <div style="width: 500px; height: 379px; background-color: #000080; border-radius: 30px;" 
                    class="d-flex justify-content-center align-items-center">
                    <img  src="../../../assets/images/sistema-informatico.png"  style="filter: invert(1); width: 50%;" alt="Imagen de fondo"
                                    class="background-image p-auto">
                    </div>
                </div>
            </div>
        </section>



        <section style="background-color: #000080" class="sizeSection">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: white;">Steps</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/registro.png'" [name]="'Register'"
                            [description]="'Register as a Student.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/nfts.png'" [name]="'Create tokens'"
                            [description]="'Create the tokens you offer with their description.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/dar-dinero.png'" [name]="'Sell your tokens'"
                            [description]="'Sell your tokens and boost your career.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/chequeo-de-tiempo.png'" [name]="'Fulfill hours'"
                            [description]="'Fulfills the hours sold.'"></app-beneficios>
                    </div>
                </div>
            </div>
        </section>

        <section style="background-color: white;" class="sizeSectionA">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">Guarantee of Compliance and Transparency</h1>
                    </div>
                </div>
            </div>
            <div class="container" style="    padding: 50px 0 10px 0;">
                <div class="container gpt3__header " style="background-size: cover;">
                    <div class="gpt3__header-image1">
                        <div style="width: 500px; height: 379px; background-color: #000080; border-radius: 30px;" 
                            class="d-flex justify-content-center align-items-center">
                    <img  src="../../../assets/images/garantia.png"  style="filter: invert(1); width: 50%;" alt="Imagen de fondo"
                                    class="background-image p-auto">
                    </div>
                    </div>
                    <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                        <p class="changetextsize" translate>
                            On our platform, we strive to ensure that both students and sponsors have a safe and transparent experience.
                        </p>
                        <p class="changetextsize" translate>
                            The process of fulfilling the hours sold is completely transparent and is backed by blockchain technology to ensure that both students and sponsors fulfill their commitments fairly and securely. This provides confidence to users of our platform and ensures that the exchange of skills for investment is beneficial for all.
                        </p>

                        <button href="javascript:void(0)" class="newbtn btnsize3" style="    background: transparent;
                            color: black; padding: 0; font-weight: 500;"
                            (click)="openDialog()" translate>View More</button>
                    </div>

                </div>
            </div>
        </section>

        <section style="background-color: #000080" class="sizeSection">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: white;">Benefits of joining JuniorsTime</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/financiamiento-de-la-educacion.png'" [name]="'Financing'"
                            [description]="'Access to financing for your education.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/marca.png'" [name]="'Build your brand'"
                            [description]="'Building your personal brand and network.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/altoparlante.png'" [name]="'Promote yourself'"
                            [description]="'Opportunity to demonstrate your experience and skills in the real world.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/flexibilidad.png'" [name]="'Flexibility'"
                            [description]="'Flexibility to set your rates and terms.'"></app-beneficios>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="container" style="background-color: #000CFE; border-radius: 30px;">
                    <div class="text-center paddingB">
                        <p class="h2" style="color: white!important;" translate>Are you a Student? Join our Educational Revolution.</p>
                        <p style=" color: white;" translate  class="p-2">
                            Your future is in your hands. Sign up today and start selling your hourly NFTs. Change your life while setting yourself up for success!
                        </p>
                        <button href="javascript:void(0)" class="newbtn btnsize3" style="    background: transparent;
                            color: white;
                            padding: 0;
                            border: 1px solid;     font-weight: 500;" translate>Register</button>

                    </div>
                </div>
            </div>
        </section>

    </div>
</div>