<div class="appContainer2">
    <div class="nft-main">

        <section id="subheader" class="text-light" style="padding-top: 5rem!important;">
            <div class="center-y relative text-center">
              <div class="container">
                <div class="row">
          
                  <div class="col-md-12 text-center">
                    <h1 class="fs-explore" style="color: black; font-size: 3.5rem !important;" translate>JuniorsTime</h1>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </section>

<section id="section-hero" aria-label="section" class="no-top no-bottom vh-100" style="margin-top: -4rem;">
    <div class="v-center" style="background-size: cover;">
        <div class="container text-center" style="overflow: auto;" style="overflow-y: scroll; scroll-behavior: smooth; max-height: 750px;">
            <h1 style="color: black;" translate="">Legal Warning</h1>
            <p class="text-justify" style="color: black;" translate>
                Legal warning p1
            </p>
            <p class="text-justify" style="color: black;" translate>
                Legal warning p2
            </p>
            <p class="text-justify" style="color: black;" translate>
                Legal warning p3
            </p>
            <p class="text-justify" style="color: black;" translate>
                Legal warning p4
            </p>
            <p class="text-justify" style="color: black;" translate>
                Legal warning p5
            </p>
            <p class="text-justify" style="color: black;" translate>
                Legal warning p6
            </p>
            <p class="text-justify" style="color: black;"translate>
                Legal warning p7
            </p>
            <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>GENERAL CONDITIONS OF USE OF THE PLATFORM</h3>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>1. Agreement of terms.</h3>
            <p class="text-justify" style="color: black;" translate>
                1. Agreement of terms p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>2. Additional terms and policies.</h3>
            <p class="text-justify" style="color: black;" translate>
                2. Additional terms and policies p1
            </p>
            <p class="text-justify" style="color: black;" translate>
                2. Additional terms and policies p2
            </p>
            <p class="text-justify" style="color: black;" translate>
                2. Additional terms and policies p3
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>3. Specific Programs.</h3>
            <p class="text-justify" style="color: black;" translate>
                3. Specific Programs p1            
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>4. Third-party service providers.</h3>
            <p class="text-justify" style="color: black;" translate>
                4. Third-party service providers p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>5. Changes to these Terms or the Services.</h3>
            <p class="text-justify" style="color: black;" translate>
                5. Changes to these Terms or the Services p1            
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>6. Users.</h3>
            <p class="text-justify" style="color: black;" translate>
                6. Users p1            
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>7. Account creation.</h3>
            <p class="text-justify" style="color: black;" translate>
                7. Account creation p1            
            </p>
            <p class="text-justify" style="color: black;" translate>
                7. Account creation p2            
            </p>
            <p class="text-justify" style="color: black;" translate>
                7. Account creation p3            
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>8. Additional information.</h3>
            <p class="text-justify" style="color: black;" translate>
                8. Additional information p1            
            </p>
            <p class="text-justify" style="color: black;" translate>
                8. Additional information p2
            </p>
            <p class="text-justify" style="color: black;" translate>
                8. Additional information p3            
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" tranlate>9. Right to block access.</h3>
            <p class="text-justify" style="color: black;" translate>
                9. Right to block access p1            
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>10. Equipment and Software.</h3>
            <p class="text-justify" style="color: black;" translate>
                10. Equipment and Software p1            
            </p>
            <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>ABOUT SERVICES.</h3>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>1. Parts.</h3>
            <p class="text-justify" style="color: black;" translate>
                1. Parts p1            
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>2. Our Role.</h3>
            <p class="text-justify" style="color: black;" translate>
                2. Our Role p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>3. Our Relationship.</h3>
            <p class="text-justify" style="color: black;" translate>
                3. Our Relationship p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>4. Duration of the relationship.</h3>
            <p class="text-justify" style="color: black;" translate>
                4. Duration of the relationship p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>5. Conditions of purchase of tokens.</h3>
            <p class="text-justify" style="color: black;" translate>
                5. Conditions of purchase of tokens p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>6. Token fee.</h3>
            <p class="text-justify" style="color: black;" translate>
                6. Token fee p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>7. Payment information.</h3>
            <p class="text-justify" style="color: black;" translate>
                7. Payment information p1
            </p>
            <p class="text-justify" style="color: black;" translate>
                7. Payment information p2
            </p>
            <p class="text-justify" style="color: black;" translate>
                7. Payment information p3
            </p>
            <p class="text-justify" style="color: black;" translate>
                7. Payment information p4
            </p>
            <p class="text-justify" style="color: black;" translate>
                7. Payment information p5
            </p>
            <p class="text-justify" style="color: black;" translate>
                7. Payment information p6
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>8. Costs and Taxes.</h3>
            <p class="text-justify" style="color: black;" translate>
                8. Costs and Taxes p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>9. Restrictions on token transfers.</h3>
            <p class="text-justify" style="color: black;" translate>
                9. Restrictions on token transfers p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;">10. Fees.</h3>
            <p class="text-justify" style="color: black;" translate>
                10. Fees p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>11. Respect for the Privacy of the players.</h3>
            <p class="text-justify" style="color: black;" translate>
                11. Respect for the Privacy of the player p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>12. Disputes between players and buyers.</h3>
            <p class="text-justify" style="color: black;" translate>
                12. Disputes between players and buyers p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>13. Regulatory and compliance suspensions or rescissions.</h3>
            <p class="text-justify" style="color: black;" translate>
                13. Regulatory and compliance suspensions or rescissions p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>14. Your content; Tokens.</h3>
            <p class="text-justify" style="color: black;" translate>
                14. Your content; Tokens p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>15. Property.</h3>
            <p class="text-justify" style="color: black;" translate>
                15. Property p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>16. Permissions for your user content.</h3>
            <p class="text-justify" style="color: black;" translate>
                16. Permissions for your user content p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>17. Terms of the tokens.</h3>
            <p class="text-justify" style="color: black;" translate>
                17. Terms of the tokens p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>18. Token ownership.</h3>
            <p class="text-justify" style="color: black;" translate>
                18. Token ownership p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>19. Authority.</h3>
            <p class="text-justify" style="color: black;" translate>
                19. Authority p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>20. Your responsibility for user content.</h3>
            <p class="text-justify" style="color: black;" translate>
                20. Your responsibility for user content p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>21. Removal of User Content.</h3>
            <p class="text-justify" style="color: black;" translate>
                21. Removal of User Content p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>22. JuniorsTime Intellectual Property.</h3>
            <p class="text-justify" style="color: black;" translate>
                22. JuniorsTime Intellectual Property p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>23. No contravention.</h3>
            <p class="text-justify" style="color: black;" translate>
                23. No contravention p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>24. Litigation.</h3>
            <p class="text-justify" style="color: black;" translate>
                24. Litigation p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>25. Acceptable Use Policy.</h3>
            <p class="text-justify" style="color: black;" translate>
                25. Acceptable Use Policy p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>26. General Prohibitions.</h3>
            <p class="text-justify" style="color: black;" translate>
                26. General Prohibitions p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>27. No obligation.</h3>
            <p class="text-justify" style="color: black;" translate>
                27. No obligation p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>28. Copyright policy.</h3>
            <p class="text-justify" style="color: black;" translate>
                28. Copyright policy p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>29. Links to third party websites or resources.</h3>
            <p class="text-justify" style="color: black;" translate>
                29. Links to third party websites or resources p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>30. Termination.</h3>
            <p class="text-justify" style="color: black;" translate>
                30. Termination p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>31. Disclaimers of liability.</h3>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p1
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p2
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p3
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p4
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p5
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p6
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p7
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p8
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p9
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p10
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p11
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p12
            </p>
            <p class="text-justify" style="color: black;" translate>
                31. Disclaimers of liability p13
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>ASSUMPTION OF RISK.</h3>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>You agree and acknowledge:</h3>
            <p class="text-justify" style="color: black;" translate>
                You agree and acknowledge p1
            </p>
            <p class="text-justify" style="color: black;" translate>
                You agree and acknowledge p2
            </p>
            <p class="text-justify" style="color: black;" translate>
                You agree and acknowledge p3
            </p>
            <p class="text-justify" style="color: black;" translate>
                You agree and acknowledge p4
            </p>
            <p class="text-justify" style="color: black;" translate>
                You agree and acknowledge p5
            </p>
            <p class="text-justify" style="color: black;" translate>
                You agree and acknowledge p6
            </p>
            <p class="text-justify" style="color: black;" translate>
                You agree and acknowledge p7
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>Indemnity.</h3>
            <p class="text-justify" style="color: black;" translate>
                Indemnity p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>Limitation of liability.</h3>
            <p class="text-justify" style="color: black;" translate>
                Limitation of liability p1
            </p>
            <p class="text-justify" style="color: black;" translate>
                Limitation of liability p2
            </p>
            <p class="text-justify" style="color: black;" translate>
                Limitation of liability p3
            </p>
            <p class="text-justify" style="color: black;" translate>
                Limitation of liability p4
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>Applicable law and choice of forum.</h3>
            <p class="text-justify" style="color: black;" translate>
                Applicable law and choice of forum p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>Conflict resolution.</h3>
            <p class="text-justify" style="color: black;" translate>
                Conflict resolution p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>General terms.</h3>
            <p class="text-justify" style="color: black;" translate>
                General terms p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>Complete agreement.</h3>
            <p class="text-justify" style="color: black;" translate>
                Complete agreement p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>Warning.</h3>
            <p class="text-justify" style="color: black;" translate>
                Warning p1
            </p>
            <h3 class="text-left font-weight-bold" style="color: black;" translate>Disclaimer of Rights.</h3>
            <p class="text-justify" style="color: black;" translate>
                Disclaimer of Rights p1
            </p>
        </div>
    </div>
</section>

<h4 class="text-center" translate>If you have any questions regarding these Terms or the Services</h4>
    <app-contact-us></app-contact-us>
    <!-- <app-share-social-media></app-share-social-media> -->
    
</div>
</div>