import { Component, Input } from '@angular/core';

export interface FaqItem {
  question: string;
  answer: string;
  showAnswer: boolean;
}
@Component({
  selector: 'app-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.css']
})
export class FaqItemComponent {
  @Input()faq!: FaqItem;
}
