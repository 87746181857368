<section id="subheader" class="text-light" style="padding-top: 5rem!important; margin-bottom: 0!important;">
      <div class="container">
  
          <div class="text-center">
            <h1  style="color: black; margin-bottom: 0!important;" translate>complete register </h1>
            <h2 translate style="color: black;" >select an option</h2>
          </div>
      </div>
  </section>
  <!-- section close -->

  <section>

    <div class="image-container1 ">

        <div class="image zit ZoomIt" style="background-image: url('../../../assets/images/firts.png');">
            <div class="overlay1">
                <h1 style="color: white!important;" translate>Are you a student?</h1>
                <button (click)="estudiante()" translate>select</button>
            </div>
        </div>


        <div class="image zit ZoomIt" style="background-image: url('../../../assets/images/second.png');">
            <div class="overlay1">
                <h1 style="color: white!important;" translate>Are you a an athlete?</h1>
                <button (click)="deportista()" translate>select</button>
            </div>
        </div>

        <div class="image zit ZoomIt" style="background-image: url('../../../assets/images/third.png');">
            <div class="overlay1">
                <h1 style="color: white!important;" translate>Are you a sponsor?</h1>
                <button  (click)="patrocinador()" translate>select</button>
            </div>
        </div>


    </div>
</section>

<section style="background-color: white;     padding: 40px 0 0 0!important;" id="WhyJunoirsTime">
    <div class="container">
        <div class="container" style="display: flex; justify-content: center; gap: 10px;">
            <div class="text">
                <h1 translate style="color: black;">Why Juniors Time?</h1>
            </div>
        </div>
    </div>
    <div class="container gpt3__header " style="background-size: cover;">
        <div class="gpt3__header-content col-md-6" style="background-size: cover;">
            <p class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color: black"
                translate>
                We want to democratize opportunity for athletes and students</p>
            <p class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color: black"
                translate>
                There are thousands of athletes and future professionals globally that are good enough but never
                make it due to a lack of capital and network. We aim to change that!</p>
            <p class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color: black"
                translate>

                JuniorTime is the premier platform where promoters can invest in athletes and students they
                believe in, share in their success or earn cheap hours of future expert professionals and deeply
                connect with them.
            </p>
            <p class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color: black"
                translate>
                Our purpose is to help athletes and students of the world to develop their careers through the
                financing power of promoters who will follow their progress.
            </p>
        </div>
        <div class="gpt3__header-image1">
            <img src="./../../../assets/images/new.png" alt="Home" />
        </div>
    </div>
</section>
  