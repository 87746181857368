import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookieslegales',
  templateUrl: './cookieslegales.component.html',
  styleUrls: ['./cookieslegales.component.css']
})
export class CookieslegalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
