<div class="appContainer2">
    <div class="nft-main">

        <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100"
            style="background-color: rgb(0, 0, 16);">

            <!-- texto -->
            <div style="z-index: 3; position: absolute; left: 12vw">
                <div class="v-center" style="background-size: cover;">
                    <div class="container gpt3__header " style="background-size: cover;">
                        <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                            <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                            style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                            translate>From Today's Investment to Tomorrow's Rewards</h1>
                          <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                           style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                           translate>
                           Welcome to JuniorTime, a platform dedicated to shaping futures and reaping
                           rewards through
                           strategic investments. We believe in the power of supporting emerging talent on
                           their
                           journey to success, whether it's students pursuing professional careers or
                           aspiring
                           sportspeople striving for excellence.</p>
                          <div style="display: flex; gap: 20px;">
                               <a href="javascript:void(0)" routerLink="/marketplace"
                                   class="newbtn btnsize3 wow fadeInUp lead animated d-flex align-items-center justify-content-center" data-wow-delay="1.25s"
                                  style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp; margin-top: 1rem; padding-top: 0px;"
                                   translate>explore</a>
                              <div style="display: flex;  gap: 10px;">
                                   <a href="javascript:void(0)" 
                                      class="wow fadeInUp lead animated" data-wow-delay="1.25s"
                                      style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp; margin-top: 1rem; color: white;"
                                      translate> <img
                                          style="height: 50px; width: 50px; visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;"
                                          src="../../../assets/images/play.png">
                                      Watch Video</a>
                           </div>
                          </div> 
                        </div>
                    </div>
                </div>              
            </div>

            <!-- carrousel -->
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active " data-interval="5000"
                        style="background-image: url('./../../../assets/images/back.jpg'); width: 100% !important; background-size: cover; ">
                        <div class="v-center" style="background-size: cover;">
                            <div class="container gpt3__header " style="background-size: cover;">
                                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                    <div class="spacer-single" style="background-size: cover;"></div>
                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="carousel-item" data-interval="5000" style="background-image: url('./../../../assets/images/back2.jpg'); background-size: cover; ">
                         <div class="v-center" style="background-size: cover;">
                            <div class="container gpt3__header " style="background-size: cover;">
                                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                    <div class="spacer-single" style="background-size: cover;"></div>
                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

        </section>


        <!-- <section id="section-intro" style="background-color: white;"
                 *ngIf="!loading && collectionItemsSubastas.length > 0">
            <div class="container" style="background-size: cover">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-12" style="background-size: cover; margin-bottom: 40px;">
                        <div class="text-center" style="background-size: cover;">
                            <h2 translate class="style-2">Subastas</h2>
                            <div class="small-border bg-color-2"></div>
                        </div>
                    </div>
                </div>
                <div class="row" style="background-size: cover;">
                    <div class="row wow fadeIn" >
                        <app-subastas [item]="item" (itemEvent)="itemEvent($event)" *ngFor="let item of collectionItemsSubastas" 
                                  class="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        </app-subastas>
                    </div>
                </div>
            </div>
        </section> -->
        <!-- 

        <section class="nft-trending no-top no-bottom" *ngIf="trendingItems.length > 0" style="background-color: white;">
            <div class="nft-trending-head">
                <div class="container">
                    <div class="col-lg-12" style="background-size: cover;">
                        <div class="text-center" style="background-size: cover;">
                            <h2 translate class="style-2">Trending</h2>
                            <div class="small-border bg-color-2"></div>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div class="container">
                    <app-itemskeleton *ngIf="loading"></app-itemskeleton>
                    <div class="row wow fadeIn" *ngIf="!loading && itemsActivatedForSale().length>0">
                        <app-trending [item]="item" (itemEvent)="itemEvent($event)" *ngFor="let item of trendingItems"
                            class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        </app-trending>
                    </div>
                </div>
            </div>
        </section> -->


        <section id="section-intro" style="background-color: white;" *ngIf="lastItemsForSale.length>0">
            <div class="container" style="background-size: cover">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-12" style="background-size: cover;">
                        <div class="text-center" style="background-size: cover;">
                            <h1 translate class="style-2" style="padding-bottom: 20px;">Marketplace</h1>
                        </div>
                    </div>
                </div>
                <div class="row" style="background-size: cover;">
                    <div class="row wow fadeIn">
                        <div *ngFor="let item of lastItemsForSale" class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <app-item [item]="item"></app-item>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <button class="btn-viewMore" routerLink="/marketplace" translate>View More</button>
                </div>
            </div>
        </section>

        <!-- <section class="no-top no-bottom" style="background-color: white;">
            <div class="container">
                <div class="row" *ngIf="bestSellers.length > 0">
                    <div class="text-center" style="background-size: cover;">
                        <h2 translate class="style-2" translate>Top Sellers</h2>
                        <div class="text-center small-border bg-color-2"></div>
                    </div>
                    <div class="col-md-12 wow fadeIn">
                        <ol class="gridResponsive" style=" display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-template-rows: 2fr 100px;
                        grid-column-gap: 20px;
                        grid-row-gap: 40px;
                        justify-items: stretch;
                        align-items: stretch; padding-left: 0!important;" *ngIf="bestSellers">
                            <li  *ngFor="let item of bestSellers">
                                <div class="author_list_pp1"> 
                                    <a href="javascript:void" [routerLink]="'/profile/'+item._id+'/created'">
                                        <img class="lazy" [src]="author(item)" alt="{{authorFullname(item)}}">
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="author_list_info" style="background: #001252;
                                border-radius: 60px;
                                 height: 7vh;     padding-top: 1rem;">
                                    <a href="javascript:void"
                                        [routerLink]="'/profile/'+item._id+'/created'">{{authorFullname(item)}}</a>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>  -->

        <section>

            <div class="image-container1 ">

                <div class="image zit ZoomIt" style="background-image: url('../../../assets/images/firts.png');">
                    <div class="overlay1">
                        <h1 style="color: white!important;" translate>Are you a student?</h1>
                        <button [routerLink]="['estudiantes']" translate>See</button>
                    </div>
                </div>


                <div class="image zit ZoomIt" style="background-image: url('../../../assets/images/second.png');">
                    <div class="overlay1">
                        <h1 style="color: white!important;" translate>Are you an athlete?</h1>
                        <button [routerLink]="['deportistas']" translate>See</button>
                    </div>
                </div>

                <div class="image zit ZoomIt" style="background-image: url('../../../assets/images/third.png');">
                    <div class="overlay1">
                        <h1 style="color: white!important;" translate>Are you a sponsor?</h1>
                        <button [routerLink]="['patrocinador']" translate>See</button>
                    </div>
                </div>


            </div>
        </section>





        <section style="background-color: white;     padding: 40px 0 0 0!important;" id="WhyJunoirsTime">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">Why Juniors Time?</h1>
                    </div>
                </div>
            </div>
            <div class="container gpt3__header " style="background-size: cover;">
                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                    <p class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color: black"
                        translate>
                        We want to democratize opportunity for athletes and students</p>
                    <p class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color: black"
                        translate>
                        There are thousands of athletes and future professionals globally that are good enough but never
                        make it due to a lack of capital and network. We aim to change that!</p>
                    <p class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color: black"
                        translate>

                        JuniorTime is the premier platform where promoters can invest in athletes and students they
                        believe in, share in their success or earn cheap hours of future expert professionals and deeply
                        connect with them.
                    </p>
                    <p class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color: black"
                        translate>
                        Our purpose is to help athletes and students of the world to develop their careers through the
                        financing power of promoters who will follow their progress.
                    </p>
                </div>
                <div class="gpt3__header-image1">
                    <img src="./../../../assets/images/new.png" alt="Home" />
                </div>
            </div>
        </section>


        <section style="background: #000080;" id="section-faqs">
            <div class="container">
                <div class="container"
                    style="display: flex; justify-content: center; gap: 10px; margin-bottom: 2rem;     padding-top: 2rem;">
                    <div class="text">
                        <h1 translate style="color: white;">Faqs</h1>
                    </div>
                </div>
                <div class="faq-list" style="padding-bottom: 2rem;">
                    <app-faq-item *ngFor="let faq of faqs | slice:0:mostrarCantidad" [faq]="faq"></app-faq-item>
                </div>
                <div class="text-center" style=" padding-bottom: 2rem;">
                    <button
                        style=" border: 1px solid #0000FF; font-weight: bold; padding: 8px 50px; color: white; background-color: transparent; "
                        *ngIf="faqs.length > mostrarCantidad" (click)="verMas()" translate>See more</button>
                </div>
            </div>
        </section>


        <section style="background-color: white">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">How does it work?</h1>
                    </div>
                </div>
            </div>

            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active" data-interval="3500">
                    <div class="d-flex justify-content-center align-items-center mt-1">
                        <div class="howto-tittle">
                            <p class="h2" style="margin: 0px !important;" translate>Sponsor</p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row" style="background-size: cover;">
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/registro.png'"
                                    [name]="'Register'"
                                    [description]="'Register as sponsor'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/financiamiento-de-la-educacion.png'"
                                    [name]="'Buy tokens'"
                                    [description]="'Invest in the careers of students or athletes with projection.'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/acuerdo.png'" [name]="'Get rewards'"
                                    [description]="'Exchange tokens for working hours or royalties as appropriate.'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/beneficios.png'"
                                    [name]="'Marketplace'"
                                    [description]="'You will be able to sell the tokens purchased freely in the marketplace.'"></app-section-two-items>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item" data-interval="3500">
                    <div class="d-flex justify-content-center align-items-center mt-1">
                        <div class="howto-tittle">
                            <p class="h2" style="margin: 0px !important;" translate>Student</p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row" style="background-size: cover;">
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/registro.png'"
                                    [name]="'Register'"
                                    [description]="'Register as a student or student as appropriate.'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/garantia.png'"
                                    [name]="'Full your profile'"
                                    [description]="'Fill in all your data to verify your account.'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/nfts.png'" [name]="'Create tokens'"
                                    [description]="'Creates the tokens available for bidding.'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/marca.png'"
                                    [name]="'Complete the work'"
                                    [description]="'Performs the job when requested. Upon completion of the job retrieves those NFTs or tokens sold.'"></app-section-two-items>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item" data-interval="3500">
                    <div class="d-flex justify-content-center align-items-center mt-1">
                        <div class="howto-tittle">
                            <p class="h2" style="margin: 0px !important;" translate>Sportman</p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row" style="background-size: cover;">
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/registro.png'"
                                    [name]="'Register'"
                                    [description]="'Register as a student or student as appropriate.'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/garantia.png'"
                                    [name]="'Full your profile'"
                                    [description]="'Fill in all your data to verify your account.'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/nfts.png'" [name]="'Create tokens'"
                                    [description]="'Creates the tokens available for bidding.'"></app-section-two-items>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <app-section-two-items [img]="'../../../assets/images/marca.png'"
                                    [name]="'Share benefits'"
                                    [description]="'Investors will be able to claim agreed profits from your earned rewards.'"></app-section-two-items>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- <button class="carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </button> -->
                </div>
        </section>

        <section id="howtowork" 
        class="d-flex justify-content-center align-items-center p-3">
                <div class="d-flex flex-column justify-content-center align-items-center text-center py-4"
                style=" background-size: cover;">
                    <h1 translate style=" font-size: 45px !important;">Discover Juniors Time: Where Dreams Find Opportunity</h1>
                    <p class="mb-4" style="font-size: 20px !important;" translate>At Juniors Time, the paths of athletes, students and sponsors converge. This is the space where dreams meet limitless opportunities</p>
                    
                    <div style="height: 360px;" class="d-flex justify-content-center align-items-center">
                        <img *ngIf="showingVideo==false" (click)="showVideo()" style="cursor: pointer;/* filter: drop-shadow(0 0 3px black)*/"
                    src="../../../assets/images/boton-de-play.png" class="mt-3" width="180px"/>
                        <iframe *ngIf="showingVideo" width="640" height="360" src="https://www.youtube.com/embed/znNllvBt_Jg?autoplay=1" allow="autoplay" frameborder="0" allowfullscreen></iframe>
                    </div>
                    
                </div>

        </section>
        
        <section id="contacto">
            <div>
                <div class="container">
                    <form (ngSubmit)="onSubmit()" class="contact-form">
                        <div class="container"
                            style="display: flex; justify-content: center; gap: 10px; margin-bottom: 0rem;">
                            <div class="text">
                                <h1 translate style="color: black;">Contact</h1>
                            </div>
                        </div>
        
                        <mat-label translate>Name</mat-label>
                        <div class="contact-form-input">
                            <input type="text" class="form-control rounded border-white form-input" [(ngModel)]="name"
                                name="name" required>
                        </div>
        
                        <mat-label translate>Email</mat-label>
                        <div class="contact-form-input">
                            <input type="email" class="form-control rounded border-white form-input" [(ngModel)]="email"
                                name="email"  required
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
                        </div>
                        
                        <mat-label translate>Message</mat-label>
                        <div class="contact-form-input">
                            <textarea class="form-control rounded border-white mb-3 form-text-area"
                                [(ngModel)]="message"  rows="4" cols="30" name="message"
                                required></textarea>
                        </div>
        
                        <mat-label translate>Subject</mat-label>
                        <mat-select class="form-control contact-form-input" [(ngModel)]="asunto" name="asunto">
                          <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                          </mat-option>
                        </mat-select>
        
                        <div class="text-center">
                            <button
                                style="background: white; border: 1px solid #0000FF; font-weight: bold; padding: 8px 50px;"
                                type="submit" translate>
                                <span *ngIf="!isSending; else sending" translate>
                                    Send
                                </span>
                                <ng-template #sending>
                                    <mat-progress-spinner style="background-color: gray; border-radius: 40px;"
                                        diameter="20" mode="indeterminate"></mat-progress-spinner>
                                </ng-template>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>