import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';
import { config } from 'src/app/constants/config';
import { CommonService } from 'src/app/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  isAPILoading: boolean = false
  currentUser:any;
  collectionID:any;
  histories: any = []
  historyLoading:boolean = true;
  pageHistory:number = 1
  totalHistoryDocs:any = 0;
  limitHistory:any = 10;
  hasNextHistoryPage:boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private itemService: ItemService,
    private commonService: CommonService,
    private snackBar: MatSnackBar
  ) { 
    // this.currentUser = this.userService.getUser()
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
    });
 
  }

  ngOnInit(): void {
    console.log(this.currentUser)
  }

  patrocinador() {
    this.updateApiPatrocinador();
  }


  deportista() { 
    this.updateApiDeportista();
  }

  estudiante() {
    this.updateApiEstudiante();
  }

  updateApiDeportista() {

    const jsonData =  {role: 1003};
    
    this.isAPILoading = true;
    this.userService.updateRole(jsonData).subscribe({
      next: (result) => {
        console.log(result)
        this.isAPILoading = false;
        localStorage.setItem('token', result.token);
        this.snackBar.open('Finish the register, please', "", { duration: 2000 });
        this.userService.refreshUser();
        this.router.navigate(['/register']);
      }, error: (error) => {
        console.log(error);
        this.isAPILoading = false;
      }
    });

  }

  updateApiEstudiante() {

    const jsonData =  {role: 1002};
    
    this.isAPILoading = true;
    this.userService.updateRole(jsonData).subscribe({
      next: (result) => {
        console.log(result)
        this.isAPILoading = false;
        localStorage.setItem('token', result.token);
        this.snackBar.open('Finish the register, please', "", { duration: 2000 });
        this.userService.refreshUser();
        this.router.navigate(['/register']);
      }, error: (error) => {
        console.log(error);
        this.isAPILoading = false;
      }
    });
  }

  updateApiPatrocinador() {

    const jsonData =  {role: 1004};
    
    this.isAPILoading = true;
    this.userService.updateRole(jsonData).subscribe({
      next: (result) => {
        console.log(result)
        this.isAPILoading = false;
        localStorage.setItem('token', result.token);
        this.snackBar.open('Finish the register, please', "", { duration: 2000 });
        this.userService.refreshUser();
        this.router.navigate(['/register']);
      }, error: (error) => {
        console.log(error);
        this.isAPILoading = false;
      }
    });

  }
}
