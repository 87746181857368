import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacidadlegales',
  templateUrl: './privacidadlegales.component.html',
  styleUrls: ['./privacidadlegales.component.css']
})
export class PrivacidadlegalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
