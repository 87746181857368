<div class="appContainer2">
    <div class="nft-main">

        <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100"
            style="background-color: rgb(0, 0, 16);">
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
                <!-- Slides -->
                <div class="carousel-inner">
                    <div class="carousel-item active" style="background-image: url('./../../../assets/images/back.jpg'); min-width: 100% !important; background-size: cover;" > <!--   -->
                        <!-- <img class="image lazy" src="./../../../assets/images/back.jpg" style="width: 100% !important;" alt="image"> -->
                        <div class="v-center" style="background-size: cover;">
                            <div class="container gpt3__header " style="background-size: cover;">
                                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                    <div class="spacer-single" style="background-size: cover;"></div>
                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                        translate>From Today's Investment to Tomorrow's Rewards</h1>
                                    <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                        translate>
                                        Welcome to JuniorTime, a platform dedicated to shaping futures and reaping
                                        rewards through
                                        strategic investments. We believe in the power of supporting emerging talent on
                                        their
                                        journey to success, whether it's students pursuing professional careers or
                                        aspiring
                                        sportspeople striving for excellence.</p>

                                    <div style="display: flex; gap: 20px;">
                                        <a href="javascript:void(0)" routerLink="/marketplace"
                                            class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                            style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp; margin-top: 1rem;"
                                            translate>explore</a>
                                        <div style="display: flex;  gap: 10px;">
                                            <a href="javascript:void(0)" 
                                                class="wow fadeInUp lead animated" data-wow-delay="1.25s"
                                                style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp; margin-top: 1rem; color: white;"
                                                translate> <img
                                                    style="height: 50px; width: 50px; visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;"
                                                    src="../../../assets/images/play.png">
                                                Watch Video</a>
                                        </div>
                                    </div>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                </div>
                                <!-- <div class="gpt3__header-image">
                                <img src="./../../../assets/images/home.png" alt="Home" />
                            </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item" style="background-image: url('./../../../assets/images/back2.jpg'); ">
                        <div class="v-center" style="background-size: cover;">
                            <div class="container gpt3__header " style="background-size: cover;">
                                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                    <div class="spacer-single" style="background-size: cover;"></div>
                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                        translate>From Today's Investment to Tomorrow's Rewards</h1>
                                    <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                        translate>
                                        Welcome to JuniorTime, a platform dedicated to shaping futures and reaping
                                        rewards through
                                        strategic investments. We believe in the power of supporting emerging talent on
                                        their
                                        journey to success, whether it's students pursuing professional careers or
                                        aspiring
                                        sportspeople striving for excellence.</p>
                                    <div style="display: flex; gap: 20px;">
                                        <a href="javascript:void(0)" routerLink="/marketplace"
                                            class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                            style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp; margin-top: 1rem;"
                                            translate>explore</a>
                                        <div style="display: flex;  gap: 10px;">
                                            <a href="javascript:void(0)" 
                                                class="wow fadeInUp lead animated" data-wow-delay="1.25s"
                                                style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp; margin-top: 1rem; color: white;"
                                                translate> <img
                                                    style="height: 50px; width: 50px; visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;"
                                                    src="../../../assets/images/play.png">
                                                Watch Video</a>
                                        </div>
                                    </div>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                </div>
                                <!-- <div class="gpt3__header-image">
                                <img src="./../../../assets/images/home.png" alt="Home" />
                            </div> -->
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>


        <section style="background-color: white">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">How does it work?</h1>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center align-items-center mt-1">
                <div class="howto-tittle">
                    <p class="h2" style="margin: 0px !important;" translate>Sponsor</p>
                </div>
            </div>
            <div class="container">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-section-two-items [img]="'../../../assets/images/registro.png'"
                            [name]="'Register'"
                            [description]="'Register as sponsor'"></app-section-two-items>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-section-two-items [img]="'../../../assets/images/financiamiento-de-la-educacion.png'"
                            [name]="'Buy tokens'"
                            [description]="'Invest in the careers of students or athletes with projection.'"></app-section-two-items>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-section-two-items [img]="'../../../assets/images/acuerdo.png'" [name]="'Get rewards'"
                            [description]="'Exchange tokens for working hours or royalties as appropriate.'"></app-section-two-items>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-section-two-items [img]="'../../../assets/images/beneficios.png'"
                            [name]="'Marketplace'"
                            [description]="'You will be able to sell the tokens purchased freely in the marketplace.'"></app-section-two-items>
                    </div>
                </div>
            </div>




        </section>


    </div>
</div>
