import { Component, OnInit, ViewChild , ElementRef} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { config } from 'src/app/constants/config';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
interface Country {
  name: string;
  code: string;
}

interface CountryCode {
  name: string;
  code: string;
  dial_code: string;
}


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;

  years: number[] = [];

  selectedImage: string = '';

  termsAndConditions: boolean = false;

  filePhotoIdFront!: File;
  selectedImagePhotoIdFront: string = '';
  filePhotoIdBack!: File;
  selectedImagePhotoIdBack: string = '';
  filePhotoRegister!: File;
  selectedImageRegisterDay: string = '';
  filePhotoUtilityBill!: File;
  selectedImageUtilityBill: string = '';
  filePhotoAcademic!: File;
  selectedImageAcademic: string = '';
  filePhotoTrophy!: File;
  fileImageTrophy!: File;
  selectedImageTrophy: string = '';

  button_title = "Save"
  patrocinadorForm!: FormGroup;
  estudianteForm!: FormGroup;
  deportistaForm!: FormGroup;             
  selectedCountry: string = '';
  phoneNumber: string = '';


  isAPILoading: boolean = false
  currentUser: any;
  mediaBase: string = config.media_path
  userInfo: any;
  estudianteOptions: any = [];

  options = ['Estudiante', 'Deportista'];


  selectedOption!: string;
  deportistaOptions: any = [];

  file!: File;
  profile_image!: string

  filePhotoId!: File;
  photoSelectedPhotoId!: String | ArrayBuffer;

  fileRegister!: File;
  photoSelectedRegister!: String | ArrayBuffer;

  fileUtilityBill!: File;
  photoSelectedUtilityBill!: String | ArrayBuffer;

  fileAcademic!: File;
  photoSelectedAcademic!: String | ArrayBuffer;

  fileVideo!: File;
  selectedVideo!: String | ArrayBuffer;

  pdfFile!: File;
  selectedPdf!: String | ArrayBuffer;
  academicPdf!: File;
  selectedAcademicPdf!: String | ArrayBuffer;
  workExperienceFormArray!: FormArray;
  workExperienceForm!: FormGroup;

  courseExperienceFormArray !: FormArray;
  courseExperienceForm!: FormGroup;

  winTrophyFormArray !: FormArray;
  winTrophyForm!: FormGroup;

  courseText: string;

  formPartner: boolean = false;
  formStudent: boolean = false;
  formSportsman: boolean = false;

  selectID!: string;

  selectedSportsmanOption!: string;
  selectedCategoryStudents!: string;
  categorystudents!: string;
  categorysportsman!: string;
  photoSelected!: String | ArrayBuffer;
  optionsInApi!: string
  optionsChooseByUser!: string
  messageadmin!: string
  countriesCode: CountryCode[] = [
    {
      "name": "Afghanistan",
      "dial_code": "+93",
      "code": "AF"
    },
    {
      "name": "Aland Islands",
      "dial_code": "+358",
      "code": "AX"
    },
    {
      "name": "Albania",
      "dial_code": "+355",
      "code": "AL"
    },
    {
      "name": "Algeria",
      "dial_code": "+213",
      "code": "DZ"
    },
    {
      "name": "AmericanSamoa",
      "dial_code": "+1684",
      "code": "AS"
    },
    {
      "name": "Andorra",
      "dial_code": "+376",
      "code": "AD"
    },
    {
      "name": "Angola",
      "dial_code": "+244",
      "code": "AO"
    },
    {
      "name": "Anguilla",
      "dial_code": "+1264",
      "code": "AI"
    },
    {
      "name": "Antarctica",
      "dial_code": "+672",
      "code": "AQ"
    },
    {
      "name": "Antigua and Barbuda",
      "dial_code": "+1268",
      "code": "AG"
    },
    {
      "name": "Argentina",
      "dial_code": "+54",
      "code": "AR"
    },
    {
      "name": "Armenia",
      "dial_code": "+374",
      "code": "AM"
    },
    {
      "name": "Aruba",
      "dial_code": "+297",
      "code": "AW"
    },
    {
      "name": "Australia",
      "dial_code": "+61",
      "code": "AU"
    },
    {
      "name": "Austria",
      "dial_code": "+43",
      "code": "AT"
    },
    {
      "name": "Azerbaijan",
      "dial_code": "+994",
      "code": "AZ"
    },
    {
      "name": "Bahamas",
      "dial_code": "+1242",
      "code": "BS"
    },
    {
      "name": "Bahrain",
      "dial_code": "+973",
      "code": "BH"
    },
    {
      "name": "Bangladesh",
      "dial_code": "+880",
      "code": "BD"
    },
    {
      "name": "Barbados",
      "dial_code": "+1246",
      "code": "BB"
    },
    {
      "name": "Belarus",
      "dial_code": "+375",
      "code": "BY"
    },
    {
      "name": "Belgium",
      "dial_code": "+32",
      "code": "BE"
    },
    {
      "name": "Belize",
      "dial_code": "+501",
      "code": "BZ"
    },
    {
      "name": "Benin",
      "dial_code": "+229",
      "code": "BJ"
    },
    {
      "name": "Bermuda",
      "dial_code": "+1441",
      "code": "BM"
    },
    {
      "name": "Bhutan",
      "dial_code": "+975",
      "code": "BT"
    },
    {
      "name": "Bolivia, Plurinational State of",
      "dial_code": "+591",
      "code": "BO"
    },
    {
      "name": "Bosnia and Herzegovina",
      "dial_code": "+387",
      "code": "BA"
    },
    {
      "name": "Botswana",
      "dial_code": "+267",
      "code": "BW"
    },
    {
      "name": "Brazil",
      "dial_code": "+55",
      "code": "BR"
    },
    {
      "name": "British Indian Ocean Territory",
      "dial_code": "+246",
      "code": "IO"
    },
    {
      "name": "Brunei Darussalam",
      "dial_code": "+673",
      "code": "BN"
    },
    {
      "name": "Bulgaria",
      "dial_code": "+359",
      "code": "BG"
    },
    {
      "name": "Burkina Faso",
      "dial_code": "+226",
      "code": "BF"
    },
    {
      "name": "Burundi",
      "dial_code": "+257",
      "code": "BI"
    },
    {
      "name": "Cambodia",
      "dial_code": "+855",
      "code": "KH"
    },
    {
      "name": "Cameroon",
      "dial_code": "+237",
      "code": "CM"
    },
    {
      "name": "Canada",
      "dial_code": "+1",
      "code": "CA"
    },
    {
      "name": "Cape Verde",
      "dial_code": "+238",
      "code": "CV"
    },
    {
      "name": "Cayman Islands",
      "dial_code": "+ 345",
      "code": "KY"
    },
    {
      "name": "Central African Republic",
      "dial_code": "+236",
      "code": "CF"
    },
    {
      "name": "Chad",
      "dial_code": "+235",
      "code": "TD"
    },
    {
      "name": "Chile",
      "dial_code": "+56",
      "code": "CL"
    },
    {
      "name": "China",
      "dial_code": "+86",
      "code": "CN"
    },
    {
      "name": "Christmas Island",
      "dial_code": "+61",
      "code": "CX"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "dial_code": "+61",
      "code": "CC"
    },
    {
      "name": "Colombia",
      "dial_code": "+57",
      "code": "CO"
    },
    {
      "name": "Comoros",
      "dial_code": "+269",
      "code": "KM"
    },
    {
      "name": "Congo",
      "dial_code": "+242",
      "code": "CG"
    },
    {
      "name": "Congo, The Democratic Republic of the Congo",
      "dial_code": "+243",
      "code": "CD"
    },
    {
      "name": "Cook Islands",
      "dial_code": "+682",
      "code": "CK"
    },
    {
      "name": "Costa Rica",
      "dial_code": "+506",
      "code": "CR"
    },
    {
      "name": "Cote d'Ivoire",
      "dial_code": "+225",
      "code": "CI"
    },
    {
      "name": "Croatia",
      "dial_code": "+385",
      "code": "HR"
    },
    {
      "name": "Cuba",
      "dial_code": "+53",
      "code": "CU"
    },
    {
      "name": "Cyprus",
      "dial_code": "+357",
      "code": "CY"
    },
    {
      "name": "Czech Republic",
      "dial_code": "+420",
      "code": "CZ"
    },
    {
      "name": "Denmark",
      "dial_code": "+45",
      "code": "DK"
    },
    {
      "name": "Djibouti",
      "dial_code": "+253",
      "code": "DJ"
    },
    {
      "name": "Dominica",
      "dial_code": "+1767",
      "code": "DM"
    },
    {
      "name": "Dominican Republic",
      "dial_code": "+1849",
      "code": "DO"
    },
    {
      "name": "Ecuador",
      "dial_code": "+593",
      "code": "EC"
    },
    {
      "name": "Egypt",
      "dial_code": "+20",
      "code": "EG"
    },
    {
      "name": "El Salvador",
      "dial_code": "+503",
      "code": "SV"
    },
    {
      "name": "Equatorial Guinea",
      "dial_code": "+240",
      "code": "GQ"
    },
    {
      "name": "Eritrea",
      "dial_code": "+291",
      "code": "ER"
    },
    {
      "name": "Estonia",
      "dial_code": "+372",
      "code": "EE"
    },
    {
      "name": "Ethiopia",
      "dial_code": "+251",
      "code": "ET"
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "dial_code": "+500",
      "code": "FK"
    },
    {
      "name": "Faroe Islands",
      "dial_code": "+298",
      "code": "FO"
    },
    {
      "name": "Fiji",
      "dial_code": "+679",
      "code": "FJ"
    },
    {
      "name": "Finland",
      "dial_code": "+358",
      "code": "FI"
    },
    {
      "name": "France",
      "dial_code": "+33",
      "code": "FR"
    },
    {
      "name": "French Guiana",
      "dial_code": "+594",
      "code": "GF"
    },
    {
      "name": "French Polynesia",
      "dial_code": "+689",
      "code": "PF"
    },
    {
      "name": "Gabon",
      "dial_code": "+241",
      "code": "GA"
    },
    {
      "name": "Gambia",
      "dial_code": "+220",
      "code": "GM"
    },
    {
      "name": "Georgia",
      "dial_code": "+995",
      "code": "GE"
    },
    {
      "name": "Germany",
      "dial_code": "+49",
      "code": "DE"
    },
    {
      "name": "Ghana",
      "dial_code": "+233",
      "code": "GH"
    },
    {
      "name": "Gibraltar",
      "dial_code": "+350",
      "code": "GI"
    },
    {
      "name": "Greece",
      "dial_code": "+30",
      "code": "GR"
    },
    {
      "name": "Greenland",
      "dial_code": "+299",
      "code": "GL"
    },
    {
      "name": "Grenada",
      "dial_code": "+1473",
      "code": "GD"
    },
    {
      "name": "Guadeloupe",
      "dial_code": "+590",
      "code": "GP"
    },
    {
      "name": "Guam",
      "dial_code": "+1671",
      "code": "GU"
    },
    {
      "name": "Guatemala",
      "dial_code": "+502",
      "code": "GT"
    },
    {
      "name": "Guernsey",
      "dial_code": "+44",
      "code": "GG"
    },
    {
      "name": "Guinea",
      "dial_code": "+224",
      "code": "GN"
    },
    {
      "name": "Guinea-Bissau",
      "dial_code": "+245",
      "code": "GW"
    },
    {
      "name": "Guyana",
      "dial_code": "+595",
      "code": "GY"
    },
    {
      "name": "Haiti",
      "dial_code": "+509",
      "code": "HT"
    },
    {
      "name": "Holy See (Vatican City State)",
      "dial_code": "+379",
      "code": "VA"
    },
    {
      "name": "Honduras",
      "dial_code": "+504",
      "code": "HN"
    },
    {
      "name": "Hong Kong",
      "dial_code": "+852",
      "code": "HK"
    },
    {
      "name": "Hungary",
      "dial_code": "+36",
      "code": "HU"
    },
    {
      "name": "Iceland",
      "dial_code": "+354",
      "code": "IS"
    },
    {
      "name": "India",
      "dial_code": "+91",
      "code": "IN"
    },
    {
      "name": "Indonesia",
      "dial_code": "+62",
      "code": "ID"
    },
    {
      "name": "Iran, Islamic Republic of Persian Gulf",
      "dial_code": "+98",
      "code": "IR"
    },
    {
      "name": "Iraq",
      "dial_code": "+964",
      "code": "IQ"
    },
    {
      "name": "Ireland",
      "dial_code": "+353",
      "code": "IE"
    },
    {
      "name": "Isle of Man",
      "dial_code": "+44",
      "code": "IM"
    },
    {
      "name": "Israel",
      "dial_code": "+972",
      "code": "IL"
    },
    {
      "name": "Italy",
      "dial_code": "+39",
      "code": "IT"
    },
    {
      "name": "Jamaica",
      "dial_code": "+1876",
      "code": "JM"
    },
    {
      "name": "Japan",
      "dial_code": "+81",
      "code": "JP"
    },
    {
      "name": "Jersey",
      "dial_code": "+44",
      "code": "JE"
    },
    {
      "name": "Jordan",
      "dial_code": "+962",
      "code": "JO"
    },
    {
      "name": "Kazakhstan",
      "dial_code": "+77",
      "code": "KZ"
    },
    {
      "name": "Kenya",
      "dial_code": "+254",
      "code": "KE"
    },
    {
      "name": "Kiribati",
      "dial_code": "+686",
      "code": "KI"
    },
    {
      "name": "Korea, Democratic People's Republic of Korea",
      "dial_code": "+850",
      "code": "KP"
    },
    {
      "name": "Korea, Republic of South Korea",
      "dial_code": "+82",
      "code": "KR"
    },
    {
      "name": "Kuwait",
      "dial_code": "+965",
      "code": "KW"
    },
    {
      "name": "Kyrgyzstan",
      "dial_code": "+996",
      "code": "KG"
    },
    {
      "name": "Laos",
      "dial_code": "+856",
      "code": "LA"
    },
    {
      "name": "Latvia",
      "dial_code": "+371",
      "code": "LV"
    },
    {
      "name": "Lebanon",
      "dial_code": "+961",
      "code": "LB"
    },
    {
      "name": "Lesotho",
      "dial_code": "+266",
      "code": "LS"
    },
    {
      "name": "Liberia",
      "dial_code": "+231",
      "code": "LR"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "dial_code": "+218",
      "code": "LY"
    },
    {
      "name": "Liechtenstein",
      "dial_code": "+423",
      "code": "LI"
    },
    {
      "name": "Lithuania",
      "dial_code": "+370",
      "code": "LT"
    },
    {
      "name": "Luxembourg",
      "dial_code": "+352",
      "code": "LU"
    },
    {
      "name": "Macao",
      "dial_code": "+853",
      "code": "MO"
    },
    {
      "name": "Macedonia",
      "dial_code": "+389",
      "code": "MK"
    },
    {
      "name": "Madagascar",
      "dial_code": "+261",
      "code": "MG"
    },
    {
      "name": "Malawi",
      "dial_code": "+265",
      "code": "MW"
    },
    {
      "name": "Malaysia",
      "dial_code": "+60",
      "code": "MY"
    },
    {
      "name": "Maldives",
      "dial_code": "+960",
      "code": "MV"
    },
    {
      "name": "Mali",
      "dial_code": "+223",
      "code": "ML"
    },
    {
      "name": "Malta",
      "dial_code": "+356",
      "code": "MT"
    },
    {
      "name": "Marshall Islands",
      "dial_code": "+692",
      "code": "MH"
    },
    {
      "name": "Martinique",
      "dial_code": "+596",
      "code": "MQ"
    },
    {
      "name": "Mauritania",
      "dial_code": "+222",
      "code": "MR"
    },
    {
      "name": "Mauritius",
      "dial_code": "+230",
      "code": "MU"
    },
    {
      "name": "Mayotte",
      "dial_code": "+262",
      "code": "YT"
    },
    {
      "name": "Mexico",
      "dial_code": "+52",
      "code": "MX"
    },
    {
      "name": "Micronesia, Federated States of Micronesia",
      "dial_code": "+691",
      "code": "FM"
    },
    {
      "name": "Moldova",
      "dial_code": "+373",
      "code": "MD"
    },
    {
      "name": "Monaco",
      "dial_code": "+377",
      "code": "MC"
    },
    {
      "name": "Mongolia",
      "dial_code": "+976",
      "code": "MN"
    },
    {
      "name": "Montenegro",
      "dial_code": "+382",
      "code": "ME"
    },
    {
      "name": "Montserrat",
      "dial_code": "+1664",
      "code": "MS"
    },
    {
      "name": "Morocco",
      "dial_code": "+212",
      "code": "MA"
    },
    {
      "name": "Mozambique",
      "dial_code": "+258",
      "code": "MZ"
    },
    {
      "name": "Myanmar",
      "dial_code": "+95",
      "code": "MM"
    },
    {
      "name": "Namibia",
      "dial_code": "+264",
      "code": "NA"
    },
    {
      "name": "Nauru",
      "dial_code": "+674",
      "code": "NR"
    },
    {
      "name": "Nepal",
      "dial_code": "+977",
      "code": "NP"
    },
    {
      "name": "Netherlands",
      "dial_code": "+31",
      "code": "NL"
    },
    {
      "name": "Netherlands Antilles",
      "dial_code": "+599",
      "code": "AN"
    },
    {
      "name": "New Caledonia",
      "dial_code": "+687",
      "code": "NC"
    },
    {
      "name": "New Zealand",
      "dial_code": "+64",
      "code": "NZ"
    },
    {
      "name": "Nicaragua",
      "dial_code": "+505",
      "code": "NI"
    },
    {
      "name": "Niger",
      "dial_code": "+227",
      "code": "NE"
    },
    {
      "name": "Nigeria",
      "dial_code": "+234",
      "code": "NG"
    },
    {
      "name": "Niue",
      "dial_code": "+683",
      "code": "NU"
    },
    {
      "name": "Norfolk Island",
      "dial_code": "+672",
      "code": "NF"
    },
    {
      "name": "Northern Mariana Islands",
      "dial_code": "+1670",
      "code": "MP"
    },
    {
      "name": "Norway",
      "dial_code": "+47",
      "code": "NO"
    },
    {
      "name": "Oman",
      "dial_code": "+968",
      "code": "OM"
    },
    {
      "name": "Pakistan",
      "dial_code": "+92",
      "code": "PK"
    },
    {
      "name": "Palau",
      "dial_code": "+680",
      "code": "PW"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "dial_code": "+970",
      "code": "PS"
    },
    {
      "name": "Panama",
      "dial_code": "+507",
      "code": "PA"
    },
    {
      "name": "Papua New Guinea",
      "dial_code": "+675",
      "code": "PG"
    },
    {
      "name": "Paraguay",
      "dial_code": "+595",
      "code": "PY"
    },
    {
      "name": "Peru",
      "dial_code": "+51",
      "code": "PE"
    },
    {
      "name": "Philippines",
      "dial_code": "+63",
      "code": "PH"
    },
    {
      "name": "Pitcairn",
      "dial_code": "+872",
      "code": "PN"
    },
    {
      "name": "Poland",
      "dial_code": "+48",
      "code": "PL"
    },
    {
      "name": "Portugal",
      "dial_code": "+351",
      "code": "PT"
    },
    {
      "name": "Puerto Rico",
      "dial_code": "+1939",
      "code": "PR"
    },
    {
      "name": "Qatar",
      "dial_code": "+974",
      "code": "QA"
    },
    {
      "name": "Romania",
      "dial_code": "+40",
      "code": "RO"
    },
    {
      "name": "Russia",
      "dial_code": "+7",
      "code": "RU"
    },
    {
      "name": "Rwanda",
      "dial_code": "+250",
      "code": "RW"
    },
    {
      "name": "Reunion",
      "dial_code": "+262",
      "code": "RE"
    },
    {
      "name": "Saint Barthelemy",
      "dial_code": "+590",
      "code": "BL"
    },
    {
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "dial_code": "+290",
      "code": "SH"
    },
    {
      "name": "Saint Kitts and Nevis",
      "dial_code": "+1869",
      "code": "KN"
    },
    {
      "name": "Saint Lucia",
      "dial_code": "+1758",
      "code": "LC"
    },
    {
      "name": "Saint Martin",
      "dial_code": "+590",
      "code": "MF"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "dial_code": "+508",
      "code": "PM"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "dial_code": "+1784",
      "code": "VC"
    },
    {
      "name": "Samoa",
      "dial_code": "+685",
      "code": "WS"
    },
    {
      "name": "San Marino",
      "dial_code": "+378",
      "code": "SM"
    },
    {
      "name": "Sao Tome and Principe",
      "dial_code": "+239",
      "code": "ST"
    },
    {
      "name": "Saudi Arabia",
      "dial_code": "+966",
      "code": "SA"
    },
    {
      "name": "Senegal",
      "dial_code": "+221",
      "code": "SN"
    },
    {
      "name": "Serbia",
      "dial_code": "+381",
      "code": "RS"
    },
    {
      "name": "Seychelles",
      "dial_code": "+248",
      "code": "SC"
    },
    {
      "name": "Sierra Leone",
      "dial_code": "+232",
      "code": "SL"
    },
    {
      "name": "Singapore",
      "dial_code": "+65",
      "code": "SG"
    },
    {
      "name": "Slovakia",
      "dial_code": "+421",
      "code": "SK"
    },
    {
      "name": "Slovenia",
      "dial_code": "+386",
      "code": "SI"
    },
    {
      "name": "Solomon Islands",
      "dial_code": "+677",
      "code": "SB"
    },
    {
      "name": "Somalia",
      "dial_code": "+252",
      "code": "SO"
    },
    {
      "name": "South Africa",
      "dial_code": "+27",
      "code": "ZA"
    },
    {
      "name": "South Sudan",
      "dial_code": "+211",
      "code": "SS"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "dial_code": "+500",
      "code": "GS"
    },
    {
      "name": "Spain",
      "dial_code": "+34",
      "code": "ES"
    },
    {
      "name": "Sri Lanka",
      "dial_code": "+94",
      "code": "LK"
    },
    {
      "name": "Sudan",
      "dial_code": "+249",
      "code": "SD"
    },
    {
      "name": "Suriname",
      "dial_code": "+597",
      "code": "SR"
    },
    {
      "name": "Svalbard and Jan Mayen",
      "dial_code": "+47",
      "code": "SJ"
    },
    {
      "name": "Swaziland",
      "dial_code": "+268",
      "code": "SZ"
    },
    {
      "name": "Sweden",
      "dial_code": "+46",
      "code": "SE"
    },
    {
      "name": "Switzerland",
      "dial_code": "+41",
      "code": "CH"
    },
    {
      "name": "Syrian Arab Republic",
      "dial_code": "+963",
      "code": "SY"
    },
    {
      "name": "Taiwan",
      "dial_code": "+886",
      "code": "TW"
    },
    {
      "name": "Tajikistan",
      "dial_code": "+992",
      "code": "TJ"
    },
    {
      "name": "Tanzania, United Republic of Tanzania",
      "dial_code": "+255",
      "code": "TZ"
    },
    {
      "name": "Thailand",
      "dial_code": "+66",
      "code": "TH"
    },
    {
      "name": "Timor-Leste",
      "dial_code": "+670",
      "code": "TL"
    },
    {
      "name": "Togo",
      "dial_code": "+228",
      "code": "TG"
    },
    {
      "name": "Tokelau",
      "dial_code": "+690",
      "code": "TK"
    },
    {
      "name": "Tonga",
      "dial_code": "+676",
      "code": "TO"
    },
    {
      "name": "Trinidad and Tobago",
      "dial_code": "+1868",
      "code": "TT"
    },
    {
      "name": "Tunisia",
      "dial_code": "+216",
      "code": "TN"
    },
    {
      "name": "Turkey",
      "dial_code": "+90",
      "code": "TR"
    },
    {
      "name": "Turkmenistan",
      "dial_code": "+993",
      "code": "TM"
    },
    {
      "name": "Turks and Caicos Islands",
      "dial_code": "+1649",
      "code": "TC"
    },
    {
      "name": "Tuvalu",
      "dial_code": "+688",
      "code": "TV"
    },
    {
      "name": "Uganda",
      "dial_code": "+256",
      "code": "UG"
    },
    {
      "name": "Ukraine",
      "dial_code": "+380",
      "code": "UA"
    },
    {
      "name": "United Arab Emirates",
      "dial_code": "+971",
      "code": "AE"
    },
    {
      "name": "United Kingdom",
      "dial_code": "+44",
      "code": "GB"
    },
    {
      "name": "United States",
      "dial_code": "+1",
      "code": "US"
    },
    {
      "name": "Uruguay",
      "dial_code": "+598",
      "code": "UY"
    },
    {
      "name": "Uzbekistan",
      "dial_code": "+998",
      "code": "UZ"
    },
    {
      "name": "Vanuatu",
      "dial_code": "+678",
      "code": "VU"
    },
    {
      "name": "Venezuela, Bolivarian Republic of Venezuela",
      "dial_code": "+58",
      "code": "VE"
    },
    {
      "name": "Vietnam",
      "dial_code": "+84",
      "code": "VN"
    },
    {
      "name": "Virgin Islands, British",
      "dial_code": "+1284",
      "code": "VG"
    },
    {
      "name": "Virgin Islands, U.S.",
      "dial_code": "+1340",
      "code": "VI"
    },
    {
      "name": "Wallis and Futuna",
      "dial_code": "+681",
      "code": "WF"
    },
    {
      "name": "Yemen",
      "dial_code": "+967",
      "code": "YE"
    },
    {
      "name": "Zambia",
      "dial_code": "+260",
      "code": "ZM"
    },
    {
      "name": "Zimbabwe",
      "dial_code": "+263",
      "code": "ZW"
    }
  ]
  countries: Country[] = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
  ]

  documents: any = [
    {name: 'Passport', code: 'passport'},
    {name: 'ID Card', code: 'id_card'},
    {name: 'Driver License', code: 'driver_license'}
  ]

  months: { name: string, number: number }[] = [
    { name: 'January', number: 1 },
    { name: 'February', number: 2 },
    { name: 'March', number: 3 },
    { name: 'April', number: 4 },
    { name: 'May', number: 5 },
    { name: 'June', number: 6 },
    { name: 'July', number: 7 },
    { name: 'August', number: 8 },
    { name: 'September', number: 9 },
    { name: 'October', number: 10 },
    { name: 'November', number: 11 },
    { name: 'December', number: 12 }
  ];

  jobTypes: string[] = [
  'Full Time', 
  'Part Time', 
  'Freelance',  
  'Independent Professional', 
  'Temporary Contract', 
  'Internship Contract', 
  'Training contract',
];

  monthStart: { name: string, number: number } = {name: '', number: 0} ;
  monthEnd:{ name: string, number: number } = {name: '', number: 0};
  yearStart: number = 0;
  yearEnd: number = 0;

  jobType: string = '';

  addNewCourse: boolean = false;
  addNewWork: boolean = false;
  spinner: boolean = false;

  constructor(
    public formbuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
  ) {
    const currentYear = new Date().getFullYear();
    // Ajusta el rango de años según lo necesites
    for (let year = currentYear; year >= 1980; year--) {
      this.years.push(year);
    }
    this.courseText = this.translate.instant('Course');
    this.translate.onLangChange.subscribe(() => {
      this.courseText = this.translate.instant('Course');  // Cuando el idioma cambie, actualiza courseText con la nueva traducción
    });
    // this.currentUser = this.userService.getUser()
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
      if(user !== null){
        if (user.role === 1002 || user.role === 1003) this.getCategories();
      }
    });
    this.patrocinadorForm = formbuilder.group({
      'first_name': [null, Validators.compose([Validators.required, Validators.maxLength(32)])],
      'last_name': [null, Validators.compose([Validators.required, Validators.maxLength(32)])],
      'address': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')])],
      'date_of_birth': [null, Validators.compose([Validators.required])],
      'phoneNumber': ['', Validators.compose([Validators.required, Validators.pattern('[- +()0-9]+')])],
      'selectedCountry': ['', Validators.compose([Validators.required])],
      'country': [null, Validators.compose([Validators.required])],
      'city': [null, Validators.compose([Validators.required])],
      'zipcode': [null, Validators.compose([Validators.required])],
      'invest': [null, Validators.compose([Validators.required])],
      'accept_term': [null, Validators.compose([Validators.required])],
      'province': [null, Validators.compose([Validators.required])],
      "document": [null, Validators.compose([Validators.required])],
      "type_document": [null, Validators.compose([Validators.required])]
    })
    this.estudianteForm = formbuilder.group({
      'first_name': [null, Validators.compose([Validators.required, Validators.maxLength(32)])],
      'last_name': [null, Validators.compose([Validators.required, Validators.maxLength(32)])],
      'address': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')])],
      'date_of_birth': [null, Validators.compose([Validators.required])],
      'phoneNumber': ['', Validators.compose([Validators.required, Validators.pattern('[- +()0-9]+')])],
      'selectedCountry': ['', Validators.compose([Validators.required])],
      'country': [null, Validators.compose([Validators.required])],
      'city': [null, Validators.compose([Validators.required])],
      'zipcode': [null, Validators.compose([Validators.required])],
      'university': [null, Validators.compose([Validators.required])],
      'address_university': [null, Validators.compose([Validators.required])],
      'city_university': [null, Validators.compose([Validators.required])],
      'country_university': [null, Validators.compose([Validators.required])],
      'selectedCategoryStudents': [null, Validators.compose([Validators.required])],
      'zipcode_university': [null, Validators.compose([Validators.required])],
      'experience': [null, Validators.compose([Validators.required])],
      'accept_term': [false, Validators.compose([Validators.required])],
      'years_of_study': [null, Validators.compose([Validators.required])],
      'province_university': [null, Validators.compose([Validators.required])],
      "province": [null, Validators.compose([Validators.required])],
      "document": [null, Validators.compose([Validators.required])],
      "type_document": [null, Validators.compose([Validators.required])],
      "house_number": [null, Validators.compose([Validators.required])]
    })

    this.workExperienceFormArray = this.formbuilder.array([]);
    this.workExperienceForm = this.formbuilder.group({
      workExperiences: this.workExperienceFormArray,
      newCompany: [null, Validators.compose([Validators.required])],
      newPosition: [null, Validators.compose([Validators.required])],
      newYears: [null, Validators.compose([Validators.required])],
      newDescription: [null, Validators.compose([Validators.required])],
      jobType: [null, Validators.compose([Validators.required])]
    });
    this.courseExperienceFormArray = this.formbuilder.array([]);
    this.courseExperienceForm = this.formbuilder.group({
      courseExperiences: this.courseExperienceFormArray,
      newCourse: [null, Validators.compose([Validators.required])],
      newInstitution: [null, Validators.compose([Validators.required])],
      monthStart: [null, Validators.compose([Validators.required])],
      monthEnd: [null, Validators.compose([Validators.required])],
      yearStart: [null, Validators.compose([Validators.required])],
      yearEnd: [null, Validators.compose([Validators.required])],
      descriptionCourse: [null, Validators.compose([Validators.required])],
    });

    this.winTrophyFormArray = this.formbuilder.array([]);
    this.winTrophyForm = this.formbuilder.group({
      trophiesWon: this.winTrophyFormArray,
      newTrophy: [null, Validators.compose([Validators.required])],
      winDate: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
    });

    this.deportistaForm = formbuilder.group({
      'first_name': [null, Validators.compose([Validators.required, Validators.maxLength(32)])],
      'last_name': [null, Validators.compose([Validators.required, Validators.maxLength(32)])],
      'address': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')])],
      'date_of_birth': [null, Validators.compose([Validators.required])],
      'phoneNumber': ['', Validators.compose([Validators.required, Validators.pattern('[- +()0-9]+')])],
      'selectedCountry': ['', Validators.compose([Validators.required])],
      'country': [null, Validators.compose([Validators.required])],
      'city': [null, Validators.compose([Validators.required])],
      'zipcode': [null, Validators.compose([Validators.required])],
      'selectedSportsmanOption': [null, Validators.compose([Validators.required])],
      'experience': [null, Validators.compose([Validators.required])],
      'accept_term': [null, Validators.compose([Validators.required])],
      "institution_sport": [null, Validators.compose([Validators.required])],
      "sport_address": [null, Validators.compose([Validators.required])],
      "sport_country": [null, Validators.compose([Validators.required])],
      "sport_city": [null, Validators.compose([Validators.required])],
      "year_start": [null, Validators.compose([Validators.required])],
      "zipcode_sport": [null, Validators.compose([Validators.required])],
      "answer": [null, Validators.compose([Validators.required])],
      "province": [null, Validators.compose([Validators.required])],
      "document": [null, Validators.compose([Validators.required])],
      "type_document": [null, Validators.compose([Validators.required])],
      "trophies_won": [null, Validators.compose([])],
      "house_number": [null, Validators.compose([Validators.required])],
      "sport_province": [null, Validators.compose([Validators.required])],
    })

  }

  ngOnInit(): void {
    this.getProfileInfo();
    //this.categoryList();
    //this.categoryListSports()


    this.deportistaForm.get('answer')?.valueChanges.subscribe((value) => {
      if(value === "No") {
        this.winTrophyFormArray.clear();
      }
    });
    
  }

  //asignacion en vez de iteracion find index of o un find index y asignar el valor

  getCategories(){
    //console.log(this.currentUser)
    this.userService.getCategories(this.currentUser.role).subscribe({
      next: (data) =>{
        if(this.currentUser.role === 1002){
          this.estudianteOptions = data.categories;          ;
        }
        if(this.currentUser.role === 1003){
          this.deportistaOptions = data.categories;
        }
    }, error: (error) => 
      {console.log(error)
    }});
  }

  addWorkExperience() {
    const newCompany = this.workExperienceForm.get('newCompany')?.value;
    const newPosition = this.workExperienceForm.get('newPosition')?.value;
    const newYears = this.workExperienceForm.get('newYears')?.value;
    const newDescription = this.workExperienceForm.get('newDescription')?.value;
    const newjobType = this.jobType;

    if (!newCompany || !newPosition || !newYears || !newDescription || !newjobType) {
      this.snackBar.open(this.translate.instant('Please fill all fields'), "", { duration: 2000 });
      return; // Agrega esta instrucción de retorno
    }

    if(newYears < 0){
      this.snackBar.open(this.translate.instant('Years must be greater than 0'), "", { duration: 2000 });
      return; // Agrega esta instrucción de retorno
    }

    if (newDescription.length < 20 ){
      this.snackBar.open(this.translate.instant('Description must be at least 20 characters'), "", { duration: 2000 });
      return; // Agrega esta instrucción de retorno
    }

    const newWorkExperience = this.formbuilder.group({
      company: [newCompany],
      position: [newPosition],
      years: [newYears],
      description: [newDescription],
      ejobType: [newjobType]
    });

    this.workExperienceFormArray.push(newWorkExperience);
    this.clearNewExperienceFields();
    this.addNewWork = false;
  }

  clearNewExperienceFields() {
    this.workExperienceForm.patchValue({
      newCompany: '',
      newPosition: '',
      newYears: '',
      newDescription: '',
      jobType: ''
    });
  }

  removeWorkExperience(index: number) {
    this.workExperienceFormArray.removeAt(index);
  }

  getWorkExperienceValue(index: number, controlName: string) {
    const workExperience = this.workExperienceFormArray.at(index) as FormGroup;
    return workExperience.get(controlName)?.value;
  }


  getTranslatedValue(key: string): string {
    let translatedValue = '';
    this.translate.instant(key).subscribe((res: string) => {
      translatedValue = res;
    });
    return translatedValue;
  }

 //Courses 

 addCourseExperience() {
  const newCourse = this.courseExperienceForm.get('newCourse')?.value;
  const newInstitution = this.courseExperienceForm.get('newInstitution')?.value;
  const newDescriptionCourse = this.courseExperienceForm.get('descriptionCourse')?.value;
  let newDateStart = '';
  let newDateEnd = '';
  if(this.monthStart.number < 10){
    newDateStart = this.monthStart.number ? `0${this.monthStart.number} - ${this.yearStart}` : ""; 
  } else {
    newDateStart = this.monthStart.number ? `${this.monthStart.number} - ${this.yearStart}` : "";
  }
  if(this.monthEnd.number < 10){
    newDateEnd = this.monthEnd.number ? `0${this.monthEnd.number} - ${this.yearEnd}` : ""; 
  } else {
    newDateEnd = this.monthEnd.number ? `${this.monthEnd.number} - ${this.yearEnd}` : "";
  }

  console.log(newCourse, newInstitution, newDescriptionCourse, newDateStart, newDateEnd);

  if (!newCourse || ! newInstitution || !newDescriptionCourse || !newDateStart || !newDateEnd) {
    this.snackBar.open(this.translate.instant('Please fill all fields'), "", { duration: 2000 });
    return; // Agrega esta instrucción de retorno
  }

  if ( (this.yearStart > this.yearEnd) || (this.yearStart == this.yearEnd && this.monthStart.number > this.monthEnd.number)) {
    this.snackBar.open(this.translate.instant('Start date must be less than end date'), "", { duration: 2000 });
    return; // Agrega esta instrucción de retorno
  }

  if ( newDescriptionCourse.length < 20 ){
    this.snackBar.open(this.translate.instant('Description must be at least 20 characters'), "", { duration: 2000 });
    return; // Agrega esta instrucción de retorno
  }

  if ( newDescriptionCourse.length > 200 ){
    this.snackBar.open(this.translate.instant('Description must be less than 200 characters'), "", { duration: 2000 });
    return; // Agrega esta instrucción de retorno
  }

  const newCourseExperience = this.formbuilder.group({
    courseName: [newCourse],
    institution: [newInstitution],
    description: [newDescriptionCourse],
    dateStart: [newDateStart],
    dateEnd: [newDateEnd]
  });

  this.courseExperienceFormArray.push(newCourseExperience);
  this.clearNewCourseExperienceFields();
  this.addNewCourse = false;
}

clearNewCourseExperienceFields() {
  this.courseExperienceForm.patchValue({
    newCourse: '',
    newInstitution: '',
    descriptionCourse: '',
    monthStart: '',
    monthEnd: '',
    yearStart: '',
    yearEnd: ''
  });
}

removeCourseExperience(index: number) {
  this.courseExperienceFormArray.removeAt(index);
}

getCourseExperienceValue(index: number, controlName: string) {
  const courseExperience = this.courseExperienceFormArray.at(index) as FormGroup;
  return  courseExperience.get(controlName)?.value;
}


addTrophy() {
  const newTrophy = this.winTrophyForm.get('newTrophy')?.value;
  const winDate = this.winTrophyForm.get('winDate')?.value;
  const description = this.winTrophyForm.get('description')?.value;
  const photoTrophy = this.fileImageTrophy;
  const newPreview = this.selectedImageTrophy;
  console.log(newTrophy, winDate);
  if (!newTrophy || ! winDate ) {
    this.snackBar.open('Please fill all fields', "", { duration: 2000 });
    return; // Agrega esta instrucción de retorno
  }
  const newTrophyWin = this.formbuilder.group({
    trophyName: [newTrophy],
    winDate: [winDate],
    image: [photoTrophy],
    preview: [newPreview],
    description: [description]
  });


  this.winTrophyFormArray.push(newTrophyWin);
  this.clearNewTrophyWinFields();

  console.log(this.winTrophyFormArray);
}

clearNewTrophyWinFields() {
  this.winTrophyForm.patchValue({
    trophyName: '',
    winDate: '',
    image: '',
    preview: '',
  });

  this.selectedImageTrophy = "";
}
 
removeWinTrophy(index: number) {
  this.winTrophyFormArray.removeAt(index);
}



  getProfileInfo = () => {
    this.userService.viewProfile(this.currentUser.user_id).subscribe(result => {
      var resulter: any = result;
      this.userInfo = resulter.result;
      console.log(this.userInfo);
      this.profile_image = this.userInfo.imagePath
      if (!this.profile_image) {
        this.profile_image = 'noimage.png'
      }
      console.log(this.profile_image);
      this.optionsInApi = this.userInfo.options
      //this.optionsChooseByUser = this.userInfo.options
      //this.messageadmin = this.userInfo.message
      this.categorysportsman = this.userInfo.category_sportsman
      this.categorystudents = this.userInfo.categoy_students
    })
  }


  /**
   * This is the function which used update profile information with validation
   */

  onSubmitPatrocinador(formData: any) {

    if (this.termsAndConditions === false) {
      this.snackBar.open('To register you must accept these terms and conditions', "", { duration: 3500 })
      return;
    }

    if (this.selectID === "Passport") {
      if (!this.filePhotoIdFront ) {
          this.snackBar.open("Files are required", "", { duration: 2000 });
          return;
      }
    } else {
      if ( !this.filePhotoIdFront || !this.filePhotoIdBack ) 
    {
      this.snackBar.open("Files are required", "", { duration: 2000 });
      return; // Add this line
    }
    }

    

    if (this.patrocinadorForm.valid) {
      let fieldsToUpdate = new FormData();
      const selectedDate = formData.value.date_of_birth;
      const selectedDateOnly = selectedDate instanceof Date ? selectedDate.toISOString().split('T')[0] : '';
      fieldsToUpdate.append('names', formData.value.first_name);
      fieldsToUpdate.append('lastname', formData.value.last_name);
      fieldsToUpdate.append('email', formData.value.email);
      fieldsToUpdate.append('phone', formData.value.phoneNumber);
      fieldsToUpdate.append('address', formData.value.address);
      fieldsToUpdate.append('country', formData.value.country);
      fieldsToUpdate.append('city', formData.value.city);
      fieldsToUpdate.append('codePostal', formData.value.zipcode);
      fieldsToUpdate.append('date_of_birth', selectedDateOnly);
      fieldsToUpdate.append('invest', formData.value.invest);
      fieldsToUpdate.append('accept_term', this.termsAndConditions.toString());
      fieldsToUpdate.append('countryCode', formData.value.selectedCountry);
      fieldsToUpdate.append('document', formData.value.document);
      fieldsToUpdate.append('type_document', formData.value.type_document);
      fieldsToUpdate.append('province', formData.value.province);

      //images
      fieldsToUpdate.append('imageFrontId', this.filePhotoIdFront);
      fieldsToUpdate.append('imageBackId', this.filePhotoIdBack);

      //console.log(fieldsToUpdate);

      /*
      fieldsToUpdate.forEach((value, key) => {
        if (value instanceof File) {
          console.log(`${key}: ${value.name} (${value.size} bytes)`);
        } else {
          console.log(`${key}: ${value}`);
        }
      }); */

      this.updateApiPatrocinador(fieldsToUpdate);
    } else {
      if (this.patrocinadorForm.controls.email.invalid) {
        if (this.patrocinadorForm.controls.email.errors) {
          console.log(this.patrocinadorForm.controls.email.errors);
          if (this.patrocinadorForm.controls.email.errors.required) {
            this.snackBar.open('Email is required', "", { duration: 2000 });
          } else if (this.patrocinadorForm.controls.email.errors.minlength) {
            this.snackBar.open('Email required minimum 3 characters', "", { duration: 2000 });
          } else if (this.patrocinadorForm.controls.email.errors.pattern) {
            this.snackBar.open('Invalid Email', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.first_name.invalid) {
        if (this.patrocinadorForm.controls.first_name.errors) {
          if (this.patrocinadorForm.controls.first_name.errors.required) {
            this.snackBar.open('First Name is required', "", { duration: 2000 });
          } else if (this.patrocinadorForm.controls.first_name.errors.minlength) {
            this.snackBar.open('First Name required minimum 3 characters', "", { duration: 2000 });
          } else if (this.patrocinadorForm.controls.first_name.errors.maxlength) {
            this.snackBar.open('First Name required maximum 32 characters', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.last_name.invalid) {
        if (this.patrocinadorForm.controls.last_name.errors) {
          if (this.patrocinadorForm.controls.last_name.errors.required) {
            this.snackBar.open('Last Name is required', "", { duration: 2000 });
          } else if (this.patrocinadorForm.controls.last_name.errors.minlength) {
            this.snackBar.open('Last Name required minimum 3 characters', "", { duration: 2000 });
          } else if (this.patrocinadorForm.controls.last_name.errors.maxlength) {
            this.snackBar.open('Last Name required maximum 32 characters', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.address.invalid) {
        if (this.patrocinadorForm.controls.address.errors) {
          if (this.patrocinadorForm.controls.address.errors.required) {
            this.snackBar.open('Address is required', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.date_of_birth.invalid) {
        if (this.patrocinadorForm.controls.date_of_birth.errors) {
          if (this.patrocinadorForm.controls.date_of_birth.errors.required) {
            this.snackBar.open('Date of birth is required', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.phoneNumber.invalid) {
        if (this.patrocinadorForm.controls.phoneNumber.errors) {
          if (this.patrocinadorForm.controls.phoneNumber.errors.required) {
            this.snackBar.open('Phone number is required', "", { duration: 2000 });
          } else if (this.patrocinadorForm.controls.phoneNumber.errors.pattern) {
            this.snackBar.open('Invalid Phone number', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.selectedCountry.invalid) {
        if (this.patrocinadorForm.controls.selectedCountry.errors) {
          if (this.patrocinadorForm.controls.selectedCountry.errors.required) {
            this.snackBar.open('Code country is required', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.country.invalid) {
        if (this.patrocinadorForm.controls.country.errors) {
          if (this.patrocinadorForm.controls.country.errors.required) {
            this.snackBar.open('Country is required', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.invest.invalid) {
        if (this.patrocinadorForm.controls.invest.errors) {
          if (this.patrocinadorForm.controls.invest.errors.required) {
            this.snackBar.open('Invest is required', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.accept_term.invalid) {
        if (this.patrocinadorForm.controls.accept_term.errors) {
          if (this.patrocinadorForm.controls.accept_term.errors.required) {
            this.snackBar.open('Accept terms is required', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.city.invalid) {
        if (this.patrocinadorForm.controls.city.errors) {
          if (this.patrocinadorForm.controls.city.errors.required) {
            this.snackBar.open('City is required', "", { duration: 2000 });
          }
        }
      } else if (this.patrocinadorForm.controls.zipcode.invalid) {
        if (this.patrocinadorForm.controls.zipcode.errors) {
          if (this.patrocinadorForm.controls.zipcode.errors.required) {
            this.snackBar.open('Zipcode is required', "", { duration: 2000 });
          }
        }
      }
    }

  }




  updateApiPatrocinador(fieldsToUpdate: FormData) {
    // this.isAPILoading = true;
    // this.spinner = true;
    //this.userService.updateApi(fieldsToUpdate, this.currentUser.user_id).subscribe(result => {
      this.userService.updateUser(fieldsToUpdate).subscribe(result => {
      console.log(result);
      // this.isAPILoading = false;
      // this.spinner = false;
      // if (result.status === true) {
      //   localStorage.setItem('token', result.return_id);
      //   this.snackBar.open('Register successfully', "", { duration: 2000 });
      //   // location.href = config.base_url;
      //   this.router.navigate(['/profile/' + this.currentUser.user_id + '/favorited']);
      // } else {
      //   this.snackBar.open(result.message, "", { duration: 2000 });
      // }
    });
  }



  onSubmitEstudiante(formData: any) {
    console.log('seejecuto')
    if (this.termsAndConditions === false) {
      this.snackBar.open('To register you must accept these terms and conditions', "", { duration: 3500 })
      return;
    }

    if (this.selectID === "Passport") {
      if (!this.fileVideo || !this.filePhotoIdFront || !this.filePhotoRegister || !this.filePhotoUtilityBill || !this.filePhotoAcademic || !this.pdfFile ) {
          this.snackBar.open("Files are required", "", { duration: 2000 });
          return;
      }
    } else {
      if ( !this.fileVideo || !this.filePhotoIdFront || !this.filePhotoIdBack || !this.filePhotoRegister || !this.filePhotoUtilityBill || !this.filePhotoAcademic || !this.pdfFile) {
        this.snackBar.open("Files are required", "", { duration: 2000 });
        return; // Add this line
      }
    }

    

    if (this.estudianteForm.valid) {
      console.log('valido')
      let fieldsToUpdate = new FormData();
      const selectedDate = formData.value.date_of_birth;
      const selectedDateOnly = selectedDate instanceof Date ? selectedDate.toISOString().split('T')[0] : '';
      fieldsToUpdate.append('names', formData.value.first_name);
      fieldsToUpdate.append('lastname', formData.value.last_name);
      fieldsToUpdate.append('email', formData.value.email);
      fieldsToUpdate.append('phone', formData.value.phoneNumber);
      fieldsToUpdate.append('address', formData.value.address);
      fieldsToUpdate.append('country', formData.value.country);
      fieldsToUpdate.append('city', formData.value.city);
      fieldsToUpdate.append('codePostal', formData.value.zipcode);
      fieldsToUpdate.append('date_of_birth', selectedDateOnly);
      fieldsToUpdate.append('accept_term', this.termsAndConditions.toString());
      fieldsToUpdate.append('countryCode', formData.value.selectedCountry);
      fieldsToUpdate.append('university', formData.value.university);
      fieldsToUpdate.append('address_university', formData.value.address_university);
      fieldsToUpdate.append('city_university', formData.value.city_university);
      fieldsToUpdate.append('country_university', formData.value.country_university);
      fieldsToUpdate.append('category_students', formData.value.selectedCategoryStudents);
      fieldsToUpdate.append('zipcode_university', formData.value.zipcode_university);
      fieldsToUpdate.append('experience', formData.value.experience);
      fieldsToUpdate.append('province', formData.value.years_of_study);
      fieldsToUpdate.append('province_university', formData.value.province_university);
      fieldsToUpdate.append('document', formData.value.document);
      fieldsToUpdate.append('type_document', formData.value.type_document);
      fieldsToUpdate.append('house_number', formData.value.house_number);


      const input = formData.value.years_of_study.toString();
      const dates = input.split(",");

      // Convert each date to the desired format
      const formattedDates = dates.map((date: any) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, "0");
        const month = (d.getMonth() + 1).toString().padStart(2, "0");
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
      });

      // Join the formatted dates with " - " separator
      const output = formattedDates.join(" - ");
      fieldsToUpdate.append('years_of_study', output);
      


      fieldsToUpdate.append('imageFrontId', this.filePhotoIdFront);
      if(this.filePhotoIdBack) fieldsToUpdate.append('imageBackId', this.filePhotoIdBack);
      fieldsToUpdate.append('imageRegisterDay', this.filePhotoRegister);
      fieldsToUpdate.append('imageBill', this.filePhotoUtilityBill);
      fieldsToUpdate.append('imageCertificateId', this.filePhotoAcademic);
      fieldsToUpdate.append('videoPresntation', this.fileVideo);
      fieldsToUpdate.append('pdfSummary', this.pdfFile);
      fieldsToUpdate.append('pdfNotes', this.academicPdf);

      fieldsToUpdate.append('workExperiences', JSON.stringify(this.workExperienceFormArray.value));
      fieldsToUpdate.append('courseExperiences', JSON.stringify(this.courseExperienceFormArray.value));
      

      /*

      console.log(this.fileAcademic, this.fileVideo, this.pdfFile, this.filePhotoId, this.fileRegister, this.fileUtilityBill)
      console.log(fieldsToUpdate);*/


      
      /*
      fieldsToUpdate.forEach((value, key) => {
        if (value instanceof File) {
          console.log(`${key}: ${value.name} (${value.size} bytes)`);
        } else {
          console.log(`${key}: ${value}`);
        }
      }); */

      this.updateApiEstudiante(fieldsToUpdate);
    } else {
      if (this.estudianteForm.controls.email.invalid) {
        if (this.estudianteForm.controls.email.errors) {
          console.log(this.estudianteForm.controls.email.errors);
          if (this.estudianteForm.controls.email.errors.required) {
            this.snackBar.open('Email is required', "", { duration: 2000 });
          } else if (this.estudianteForm.controls.email.errors.minlength) {
            this.snackBar.open('Email required minimum 3 characters', "", { duration: 2000 });
          } else if (this.estudianteForm.controls.email.errors.pattern) {
            this.snackBar.open('Invalid Email', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.first_name.invalid) {
        if (this.estudianteForm.controls.first_name.errors) {
          if (this.estudianteForm.controls.first_name.errors.required) {
            this.snackBar.open('First Name is required', "", { duration: 2000 });
          } else if (this.estudianteForm.controls.first_name.errors.minlength) {
            this.snackBar.open('First Name required minimum 3 characters', "", { duration: 2000 });
          } else if (this.estudianteForm.controls.first_name.errors.maxlength) {
            this.snackBar.open('First Name required maximum 32 characters', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.last_name.invalid) {
        if (this.estudianteForm.controls.last_name.errors) {
          if (this.estudianteForm.controls.last_name.errors.required) {
            this.snackBar.open('Last Name is required', "", { duration: 2000 });
          } else if (this.estudianteForm.controls.last_name.errors.minlength) {
            this.snackBar.open('Last Name required minimum 3 characters', "", { duration: 2000 });
          } else if (this.estudianteForm.controls.last_name.errors.maxlength) {
            this.snackBar.open('Last Name required maximum 32 characters', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.address.invalid) {
        if (this.estudianteForm.controls.address.errors) {
          if (this.estudianteForm.controls.address.errors.required) {
            this.snackBar.open('Address is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.date_of_birth.invalid) {
        if (this.estudianteForm.controls.date_of_birth.errors) {
          if (this.estudianteForm.controls.date_of_birth.errors.required) {
            this.snackBar.open('Date of birth is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.phoneNumber.invalid) {
        if (this.estudianteForm.controls.phoneNumber.errors) {
          if (this.estudianteForm.controls.phoneNumber.errors.required) {
            this.snackBar.open('Phone number is required', "", { duration: 2000 });
          } else if (this.estudianteForm.controls.phoneNumber.errors.pattern) {
            this.snackBar.open('Invalid Phone number', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.selectedCountry.invalid) {
        if (this.estudianteForm.controls.selectedCountry.errors) {
          if (this.estudianteForm.controls.selectedCountry.errors.required) {
            this.snackBar.open('Code country is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.country.invalid) {
        if (this.estudianteForm.controls.country.errors) {
          if (this.estudianteForm.controls.country.errors.required) {
            this.snackBar.open('Country is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.accept_term.invalid) {
        if (this.estudianteForm.controls.accept_term.errors) {
          if (this.estudianteForm.controls.accept_term.errors.required) {
            this.snackBar.open('Accept terms is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.city.invalid) {
        if (this.estudianteForm.controls.city.errors) {
          if (this.estudianteForm.controls.city.errors.required) {
            this.snackBar.open('City is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.zipcode.invalid) {
        if (this.estudianteForm.controls.zipcode.errors) {
          if (this.estudianteForm.controls.zipcode.errors.required) {
            this.snackBar.open('Zipcode is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.university.invalid) {
        if (this.estudianteForm.controls.university.errors) {
          if (this.estudianteForm.controls.university.errors.required) {
            this.snackBar.open('University is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.address_university.invalid) {
        if (this.estudianteForm.controls.address_university.errors) {
          if (this.estudianteForm.controls.address_university.errors.required) {
            this.snackBar.open('Address University is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.city_university.invalid) {
        if (this.estudianteForm.controls.city_university.errors) {
          if (this.estudianteForm.controls.city_university.errors.required) {
            this.snackBar.open('City University is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.country_university.invalid) {
        if (this.estudianteForm.controls.country_university.errors) {
          if (this.estudianteForm.controls.country_university.errors.required) {
            this.snackBar.open('Country University is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.selectedCategoryStudents.invalid) {
        if (this.estudianteForm.controls.selectedCategoryStudents.errors) {
          if (this.estudianteForm.controls.selectedCategoryStudents.errors.required) {
            this.snackBar.open('Category Students is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.zipcode_university.invalid) {
        if (this.estudianteForm.controls.zipcode_university.errors) {
          if (this.estudianteForm.controls.zipcode_university.errors.required) {
            this.snackBar.open('Zipcode University is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.experience.invalid) {
        if (this.estudianteForm.controls.experience.errors) {
          if (this.estudianteForm.controls.experience.errors.required) {
            this.snackBar.open('Experience is required', "", { duration: 2000 });
          }
        }
      } else if (this.estudianteForm.controls.years_of_study.invalid) {
        if (this.estudianteForm.controls.years_of_study.errors) {
          if (this.estudianteForm.controls.years_of_study.errors.required) {
            this.snackBar.open('Years of study is required', "", { duration: 2000 });
          }
        }
      }
    }

  }


  updateApiEstudiante(fieldsToUpdate: FormData) {
    // this.isAPILoading = true;
    // this.spinner = true;
    //this.userService.updateApi(fieldsToUpdate, this.currentUser.user_id).subscribe(result => {
      this.userService.updateUser(fieldsToUpdate).subscribe(result => {  
      // console.log(result);
      // this.isAPILoading = false;
      // this.spinner = false
      // if (result.status === true) {
      //   localStorage.setItem('token', result.return_id);
      //   this.snackBar.open('Register successfully', "", { duration: 2000 });
      //   // location.href = config.base_url;
      //   this.router.navigate(['/profile/' + this.currentUser.user_id + '/favorited']);
      // } else {
      //   this.snackBar.open(result.message, "", { duration: 2000 });
      // }
    });
  }


  onSubmitDeportista(formData: any) { 
    
    if (this.termsAndConditions === false) {
      this.snackBar.open('To register you must accept these terms and conditions', "", { duration: 3500 })
      return;
    }

    if (this.selectID === "Passport") {
      if (!this.fileVideo || !this.filePhotoIdFront || !this.filePhotoRegister || !this.filePhotoUtilityBill || !this.filePhotoAcademic || !this.pdfFile ) {
          this.snackBar.open("Files are required", "", { duration: 2000 });
          return;
      }
    } else {
      if ( !this.fileVideo || !this.filePhotoIdFront || !this.filePhotoIdBack || !this.filePhotoRegister || !this.filePhotoUtilityBill || !this.filePhotoAcademic || !this.pdfFile) {
        this.snackBar.open("Files are required", "", { duration: 2000 });
        return; // Add this line
      }
    }

    console.log(this.deportistaForm.valid)
    
    if (this.deportistaForm.valid) {

      let fieldsToUpdate = new FormData();
      const selectedDate = formData.value.date_of_birth;
      const selectedDateOnly = selectedDate instanceof Date ? selectedDate.toISOString().split('T')[0] : '';
      //const selectedYearStart = formData.value.year_start;
      //const selectedYearStartOnly = selectedYearStart instanceof Date ? selectedYearStart.toISOString().split('T')[0] : '';
      fieldsToUpdate.append('names', formData.value.first_name);
      fieldsToUpdate.append('lastname', formData.value.last_name);
      fieldsToUpdate.append('email', formData.value.email);
      fieldsToUpdate.append('phone', formData.value.phoneNumber);
      fieldsToUpdate.append('address', formData.value.address);
      fieldsToUpdate.append('country', formData.value.country);
      fieldsToUpdate.append('city', formData.value.city);
      fieldsToUpdate.append('codePostal', formData.value.zipcode);
      fieldsToUpdate.append('date_of_birth', selectedDateOnly);
      fieldsToUpdate.append('accept_term', this.termsAndConditions.toString());
      fieldsToUpdate.append('countryCode', formData.value.selectedCountry);
      fieldsToUpdate.append('category_sportsman', formData.value.selectedSportsmanOption);
      fieldsToUpdate.append('experience', formData.value.experience);
      fieldsToUpdate.append('institution_sport', formData.value.institution_sport);
      fieldsToUpdate.append('sport_address', formData.value.sport_address);
      fieldsToUpdate.append('sport_country', formData.value.sport_country);
      fieldsToUpdate.append('sport_city', formData.value.sport_city);
      fieldsToUpdate.append('year_start', formData.value.year_start);
      fieldsToUpdate.append('zipcode_sport', formData.value.zipcode_sport);
      fieldsToUpdate.append('answer', formData.value.answer);
      fieldsToUpdate.append('province', formData.value.province);
      fieldsToUpdate.append('document', formData.value.document);
      fieldsToUpdate.append('type_document', formData.value.type_document);
      fieldsToUpdate.append('sport_province', formData.value.sport_province);
      fieldsToUpdate.append('house_number', formData.value.house_number);

      fieldsToUpdate.append('imageFrontId', this.filePhotoIdFront); //imagePath2 a ahora se llama imageFrontId
      fieldsToUpdate.append('imageBackId', this.filePhotoIdBack); //imagePath3 ahora se llama imageBackId
      fieldsToUpdate.append('imageRegisterDay', this.filePhotoRegister); //imagePath4 ahora se llama imageRegisterDay
      fieldsToUpdate.append('imageBill', this.filePhotoUtilityBill); //imagePath5 ahora se llama imageBill
      fieldsToUpdate.append('imageCertificateId', this.filePhotoAcademic); //imagePath6 ahora se llama imageCertificateId
      fieldsToUpdate.append('videoPresntation', this.fileVideo); //imagePath7 ahora se llama videoPresntation
      fieldsToUpdate.append('pdfSummary', this.pdfFile); //imagePath8 ahora se llama pdfSummary


      const trophiesWon = this.winTrophyFormArray.value.map((trophy: any, index: number) => {
        if (trophy.image) {
          fieldsToUpdate.append(`trophyImage${index}`, trophy.image);
        }
        return {
          trophyName: trophy.trophyName,
          winDate: trophy.winDate,
          description: trophy.description,
          image: `trophyImage${index}`
        }
      });


      console.log(trophiesWon);

      fieldsToUpdate.append('trophies_won', JSON.stringify(trophiesWon));

     // console.log('me ejecturo25225')


      fieldsToUpdate.forEach((value, key) => {
        if (value instanceof File) {
          console.log(`${key}: ${value.name} (${value.size} bytes)`);
        } else {
          console.log(`${key}: ${value}`);
        }
      }); 
      this.updateApiDeportista(fieldsToUpdate);

    } else {
      if (this.deportistaForm.controls.email.invalid) {
        if (this.deportistaForm.controls.email.errors) {
          console.log(this.deportistaForm.controls.email.errors);
          if (this.deportistaForm.controls.email.errors.required) {
            this.snackBar.open('Email is required', "", { duration: 2000 });
          } else if (this.deportistaForm.controls.email.errors.minlength) {
            this.snackBar.open('Email required minimum 3 characters', "", { duration: 2000 });
          } else if (this.deportistaForm.controls.email.errors.pattern) {
            this.snackBar.open('Invalid Email', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.first_name.invalid) {
        if (this.deportistaForm.controls.first_name.errors) {
          if (this.deportistaForm.controls.first_name.errors.required) {
            this.snackBar.open('First Name is required', "", { duration: 2000 });
          } else if (this.deportistaForm.controls.first_name.errors.minlength) {
            this.snackBar.open('First Name required minimum 3 characters', "", { duration: 2000 });
          } else if (this.deportistaForm.controls.first_name.errors.maxlength) {
            this.snackBar.open('First Name required maximum 32 characters', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.last_name.invalid) {
        if (this.deportistaForm.controls.last_name.errors) {
          if (this.deportistaForm.controls.last_name.errors.required) {
            this.snackBar.open('Last Name is required', "", { duration: 2000 });
          } else if (this.deportistaForm.controls.last_name.errors.minlength) {
            this.snackBar.open('Last Name required minimum 3 characters', "", { duration: 2000 });
          } else if (this.deportistaForm.controls.last_name.errors.maxlength) {
            this.snackBar.open('Last Name required maximum 32 characters', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.address.invalid) {
        if (this.deportistaForm.controls.address.errors) {
          if (this.deportistaForm.controls.address.errors.required) {
            this.snackBar.open('Address is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.date_of_birth.invalid) {
        if (this.deportistaForm.controls.date_of_birth.errors) {
          if (this.deportistaForm.controls.date_of_birth.errors.required) {
            this.snackBar.open('Date of birth is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.phoneNumber.invalid) {
        if (this.deportistaForm.controls.phoneNumber.errors) {
          if (this.deportistaForm.controls.phoneNumber.errors.required) {
            this.snackBar.open('Phone number is required', "", { duration: 2000 });
          } else if (this.deportistaForm.controls.phoneNumber.errors.pattern) {
            this.snackBar.open('Invalid Phone number', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.selectedCountry.invalid) {
        if (this.deportistaForm.controls.selectedCountry.errors) {
          if (this.deportistaForm.controls.selectedCountry.errors.required) {
            this.snackBar.open('Code country is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.country.invalid) {
        if (this.deportistaForm.controls.country.errors) {
          if (this.deportistaForm.controls.country.errors.required) {
            this.snackBar.open('Country is required', "", { duration: 2000 });
          }
        }
      } /*else if (this.deportistaForm.controls.accept_term.invalid) {
        if (this.deportistaForm.controls.accept_term.errors) {
          if (this.deportistaForm.controls.accept_term.errors.required) {
            this.snackBar.open('Accept terms is required', "", { duration: 2000 });
          }
        }
      } */ else if (this.deportistaForm.controls.city.invalid) {
        if (this.estudianteForm.controls.city.errors) {
          if (this.estudianteForm.controls.city.errors.required) {
            this.snackBar.open('City is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.zipcode.invalid) {
        if (this.deportistaForm.controls.zipcode.errors) {
          if (this.deportistaForm.controls.zipcode.errors.required) {
            this.snackBar.open('Zipcode is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.experience.invalid) {
        if (this.deportistaForm.controls.experience.errors) {
          if (this.deportistaForm.controls.experience.errors.required) {
            this.snackBar.open('Experience is required', "", { duration: 2000 });
          }
        }
      }  else if(this.deportistaForm.controls.institution_sport.invalid) {
        if(this.deportistaForm.controls.institution_sport.errors) {
          if(this.deportistaForm.controls.institution_sport.errors.required) {
            this.snackBar.open('Institution Sport is required', "", { duration: 2000 });
          }
        }
      } else if(this.deportistaForm.controls.sport_address.invalid) {
        if(this.deportistaForm.controls.sport_address.errors) {
          if(this.deportistaForm.controls.sport_address.errors.required) {
            this.snackBar.open('Sport Address is required', "", { duration: 2000 });
          }
        }
      } else if(this.deportistaForm.controls.sport_city.invalid) { 
        if (this.deportistaForm.controls.sport_city.errors) {
          if(this.deportistaForm.controls.sport_city.errors.required) {
            this.snackBar.open('Sport City is required', "", { duration: 2000 });
          }
        }
      }else if(this.deportistaForm.controls.sport_country.invalid) { 
        if (this.deportistaForm.controls.sport_country.errors) {
          if(this.deportistaForm.controls.sport_country.errors.required) {
            this.snackBar.open('Sport Country is required', "", { duration: 2000 });
          }
        }
      }else if(this.deportistaForm.controls.year_start.invalid) { 
        if (this.deportistaForm.controls.year_start.errors) {
          if(this.deportistaForm.controls.year_start.errors.required) {
            this.snackBar.open('Year Start is required', "", { duration: 2000 });
          }
        }
      } else if(this.deportistaForm.controls.zipcode_sport.invalid) { 
        if (this.deportistaForm.controls.zipcode_sport.errors) {
          if(this.deportistaForm.controls.zipcode_sport.errors.required) {
            this.snackBar.open('Zipcode Sport is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.answer.invalid) {
        if (this.deportistaForm.controls.answer.errors) {
          if (this.deportistaForm.controls.answer.errors.required) {
            this.snackBar.open('Answer is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.selectedSportsmanOption.invalid) { 
        if (this.deportistaForm.controls.selectedSportsmanOption.errors) {
          if (this.deportistaForm.controls.selectedSportsmanOption.errors.required) {
            this.snackBar.open('Category Sportsman is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.province.invalid) { 
        if (this.deportistaForm.controls.province.errors) {
          if (this.deportistaForm.controls.province.errors.required) {
            this.snackBar.open('Province is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.document.invalid) { 
        if (this.deportistaForm.controls.document.errors) {
          if (this.deportistaForm.controls.document.errors.required) {
            this.snackBar.open('Document is required', "", { duration: 2000 });
          }
        }
      } else if (this.deportistaForm.controls.type_document.invalid) { 
        if (this.deportistaForm.controls.type_document.errors) {
          if (this.deportistaForm.controls.type_document.errors.required) {
            this.snackBar.open('Type Document is required', "", { duration: 2000 });
          }
        }
      }
    }

  }

  updateApiDeportista(fieldsToUpdate: FormData) {
    console.log('se ejecuto updateApiDeportista')
    //this.isAPILoading = true;
    //this.spinner = true;
    //
    //this.userService.updateApi(fieldsToUpdate, this.currentUser.user_id).subscribe(result => {
    this.userService.updateUser(fieldsToUpdate).subscribe(result => {
      console.log('el urusrio es:' + result);
      // this.spinner = false;
      // this.isAPILoading = false;
      // if (result.status === true) {
      //   localStorage.setItem('token', result.return_id);
      //   this.snackBar.open('Register successfully', "", { duration: 2000 });
      //   // location.href = config.base_url;
      //   this.router.navigate(['/profile/' + this.currentUser.user_id + '/favorited']);
      // } else {
      //   this.snackBar.open(result.message, "", { duration: 2000 });
      // }
    });
  }

  onSelectOption(event: Event): void {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    this.selectedOption = target.value;
  }


  categoryList = () => {
    this.userService.categoryListStudents().subscribe(result => {
      console.log(result.tempArray);
      this.estudianteOptions = result.tempArray;
    });
  }

  categoryListSports = () => {
    this.userService.categoryListSportsman().subscribe(result => {
      console.log(result.tempArray);
      this.deportistaOptions = result.tempArray;
    });
  }


/*
  onPhotoSelectedPhotoId(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.filePhotoId = <File>event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.photoSelectedPhotoId = reader.result as String;
      reader.readAsDataURL(this.filePhotoId);
    }
  } */

  /*
  onPhotoSelectedRegister(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.fileRegister = <File>event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.photoSelectedRegister = reader.result as String;
      reader.readAsDataURL(this.fileRegister);
    }
  } */


  /*
  onPhotoSelectedUtilityBill(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.fileUtilityBill = <File>event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.photoSelectedUtilityBill = reader.result as String;
      reader.readAsDataURL(this.fileUtilityBill);
    }
  } */

  /*
  onPhotoSelectedAcademic(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.fileAcademic = <File>event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.photoSelectedAcademic = reader.result as String;
      reader.readAsDataURL(this.fileAcademic);
    }
  } */

onPhotoSelectedVideo(event: any) {
  if (event.target.files && event.target.files[0]) {
    const file = <File>event.target.files[0];

    // Check if file size is larger than 200MB
    if (file.size > 200 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large! Please select a file smaller than 200MB.'), '', { duration: 2000 });
      //fileInputVideo.value = '';
      return;
    } else {
      this.fileVideo = file;
      const reader = new FileReader();
      reader.onload = e => this.selectedVideo = reader.result as String;
      reader.readAsDataURL(this.fileVideo);
    }
  }
}

onPhotoSelectedPdf(event: any, fileInputSelectedPdf: any) {
  if (event.target.files && event.target.files[0]) {
    const file = <File>event.target.files[0];

    // Check if file size is larger than 2MB
    if (file.size > 2 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large! Please select a file smaller than 2MB.'), '', { duration: 2000 });
      fileInputSelectedPdf.value = '';
      return;
    } else {
      this.pdfFile = file
      const reader = new FileReader();
      reader.onload = e => this.selectedPdf = reader.result as String;
      reader.readAsDataURL(this.pdfFile);
    }
  }
}

onSelectedAcademicPdf(event: any, fileInputAcademicPdf: any) {
  if (event.target.files && event.target.files[0]) {
    const file = <File>event.target.files[0];

    // Check if file size is larger than 2MB
    if (file.size > 2 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large! Please select a file smaller than 2MB.'), '', { duration: 2000 });
      fileInputAcademicPdf.value = '';
      return;
    } else {
      this.academicPdf = file
      const reader = new FileReader();
      reader.onload = e => this.selectedAcademicPdf = reader.result as String;
      reader.readAsDataURL(this.academicPdf);
    }
  }
}

/*
  onDrop(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
          const files = event.dataTransfer.files;
          this.fileInput.nativeElement.files = files;
          this.onPhotoSelectedPhotoId({ target: this.fileInput.nativeElement });
      }
  } */

  onDrop(event: DragEvent, type: string) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];

      // Comprueba si el tamaño del archivo es mayor que 10MB
    if (file.size > 10 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large. Please select a file smaller than 10MB.'), '', { duration: 2000 });
      return;
    }

      if (type === 'photoIdFront') {
        this.handleFilePhotoIdFront(file);
      } else if (type === 'photoIdBack') {
        this.handleFilePhotoIdBack(file);
      } else if (type === 'trophy') {
        this.handleFileTrophy(file);
      } else if (type === 'register') {
        this.handleFilePhotoRegister(file);
      } else if (type === 'utilityBill') {
        this.handleFileUtilityBill(file);
      } else if (type === 'academic') {
        this.handleFileAcademic(file);
      }
    }
  }

  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
}


/*
  onPhotoSelectedPhotoId(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.filePhotoId = <File>event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.photoSelectedPhotoId = reader.result as String;
      reader.readAsDataURL(this.filePhotoId);
    }
  } */

//Foto frontal del documento
onPhotoSelectedPhotoId(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target && target.files && target.files.length > 0) {
    const file = target.files[0]; 

    // Comprueba si el tamaño del archivo es mayor que 10MB
    if (file.size > 10 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large. Please select a file smaller than 10MB.'), '', { duration: 2000 });
      return;
    }

    this.handleFilePhotoIdFront(file);
  }
}

handleFilePhotoIdFront(file: File) {
    this.filePhotoIdFront = file;
    const reader = new FileReader();
    reader.onload = e => this.selectedImage = reader.result as string;
    reader.readAsDataURL(file);
}


//Foto reverso del documento
onPhotoSelectedPhotoIdBack(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target && target.files && target.files.length > 0) {
    const file = target.files[0];

    // Comprueba si el tamaño del archivo es mayor que 10MB
    if (file.size > 10 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large. Please select a file smaller than 10MB.'), '', { duration: 2000 });
      return;
    }

    this.handleFilePhotoIdBack(file);
  }
}

handleFilePhotoIdBack(file: File) {
    this.filePhotoIdBack = file
    const reader = new FileReader();
    reader.onload = e => this.selectedImagePhotoIdBack = reader.result as string;
    reader.readAsDataURL(file);
}

//foto el dia del registro
onPhotoSelectedRegister(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target && target.files && target.files.length > 0) {
    const file = target.files[0];
    
    // Comprueba si el tamaño del archivo es mayor que 10MB
    if (file.size > 10 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large. Please select a file smaller than 10MB.'), '', { duration: 2000 });
      return;
    }

    this.handleFilePhotoRegister(file);
  }
}

handleFilePhotoRegister(file: File) {
    this.filePhotoRegister = file;
    const reader = new FileReader();
    reader.onload = e => this.selectedImageRegisterDay = reader.result as string;
    reader.readAsDataURL(file);
}

//foto de una factura de servicios
onPhotoSelectedUtilityBill(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target && target.files && target.files.length > 0) {
    const file = target.files[0];
    
    // Comprueba si el tamaño del archivo es mayor que 10MB
    if (file.size > 10 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large. Please select a file smaller than 10MB.'), '', { duration: 2000 });
      return;
    }

    this.handleFileUtilityBill(file);
  }
}

handleFileUtilityBill(file: File) {
    this.filePhotoUtilityBill = file;
    const reader = new FileReader();
    reader.onload = e => this.selectedImageUtilityBill = reader.result as string;
    reader.readAsDataURL(file);
}


//Foto del certificado de deportistas
onPhotoSelectedAcademic(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target && target.files && target.files.length > 0) {
    const file = target.files[0];
    
    // Comprueba si el tamaño del archivo es mayor que 10MB
    if (file.size > 10 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large. Please select a file smaller than 10MB.'), '', { duration: 2000 });
      return;
    }

    this.handleFileAcademic(file);
  }
}

handleFileAcademic(file: File) {
    this.filePhotoAcademic = file;
    const reader = new FileReader();
    reader.onload = e => this.selectedImageAcademic = reader.result as string;
    reader.readAsDataURL(file);
}


//Foto del trofeo
onPhotoSelectedTrophy(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target && target.files && target.files.length > 0) {
    const file = target.files[0];

    // Comprueba si el tamaño del archivo es mayor que 10MB
    if (file.size > 10 * 1024 * 1024) {
      this.snackBar.open(this.translate.instant('File is too large. Please select a file smaller than 10MB.'), '', { duration: 2000 });
      return;
    }

    this.handleFileTrophy(file);
  }
}

handleFileTrophy(file: File) {
  this.fileImageTrophy = file;
  const reader = new FileReader();
  reader.onload = e => this.selectedImageTrophy = reader.result as string;
  reader.readAsDataURL(file);
}

openLegalWarnings() {
  window.open(this.router.createUrlTree(['/avisoslegales']).toString(), '_blank');
}

clearImage(selected: string, file: string): void  {
  console.log('se ejecuto clearImage')
  console.log(selected)
  console.log(file)
  selected = '';
  file = '';
}

}

