<div class="appContainer2">
    <div class="nft-main">


        <!-- <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100"
        style="background-color: rgb(0, 0, 16);">
        <div id="myCarousel" class="carousel slide" data-ride="carousel">
            -- Slides --
            <div class="carousel-inner">
                <div class="carousel-item active"
                    style="background: #0000FF; width: 100%; ">
                    <div class="v-center" style="background-size: cover;">
                        <div class="container gpt3__header " style="background-size: cover;">
                            <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                <div class="spacer-single" style="background-size: cover;"></div>
                                <div class="spacer-10" style="background-size: cover;"></div>
                                <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>Invest in the talent of the future</h1>
                                <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>
                                    Support talented students and athletes with NFTs, boosting their success and your investment.</p>

                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <a href="javascript:void(0)" routerLink="/marketplace"
                                        class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                        style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                        translate>explore</a>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            </div>
                            <div class="gpt3__header-image">
                                <div class="container positionRelative"  >
                                    <img  src="../../../assets/images/backhome.png" alt="Imagen de fondo"
                                        class="background-image">
                                    <img src="./../../../assets/images/home.png" alt="Imagen superior"
                                        class="overlay-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item"
                    style="background: #0000FF; width: 100%; ">
                    <div class="v-center" style="background-size: cover;">
                        <div class="container gpt3__header " style="background-size: cover;">
                            <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                <div class="spacer-single" style="background-size: cover;"></div>
                                <div class="spacer-10" style="background-size: cover;"></div>
                                <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>Support students and athletes with skills NFTs.</h1>
                                <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>
                                    Invest in their skill hours through NFTs and be part of their growth.</p>

                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <a href="javascript:void(0)" routerLink="/marketplace"
                                        class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                        style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                        translate>explore</a>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            </div>
                            <div class="gpt3__header-image">
                                <div class="container positionRelative"  >
                                    <img  src="../../../assets/images/backhome.png" alt="Imagen de fondo"
                                        class="background-image">
                                    <img src="./../../../assets/images/home.png" alt="Imagen superior"
                                        class="overlay-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item"
                style="background: #0000FF; width: 100%; ">
                <div class="v-center" style="background-size: cover;">
                    <div class="container gpt3__header " style="background-size: cover;">
                        <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                            <div class="spacer-single" style="background-size: cover;"></div>
                            <div class="spacer-10" style="background-size: cover;"></div>
                            <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                translate>Your investment, their success, our NFTs.</h1>
                            <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                translate>
                                Your investment empowers the success of students and athletes, backed by NFTs on our platform.</p>

                                <div class="spacer-10" style="background-size: cover;"></div>
                                <a href="javascript:void(0)" routerLink="/marketplace"
                                    class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                    style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                    translate>explore</a>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            <div class="mb-sm-30" style="background-size: cover;"></div>
                        </div>
                        <div class="gpt3__header-image">
                            <div class="container positionRelative"  >
                                <img  src="../../../assets/images/backhome.png" alt="Imagen de fondo"
                                    class="background-image">
                                <img src="./../../../assets/images/home.png" alt="Imagen superior"
                                    class="overlay-image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </div>
        </div>
    </section> -->

    <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100"
        style="background-color: #0000FF;">

        <div class="principalImage">
            <div class="gpt3__header-image">
                <div class="container positionRelative"  >
                    <img  src="../../../assets/images/backhome.png" alt="Imagen de fondo"
                        class="background-image secondaryImage">
                    <img src="./../../../assets/images/patrocinador.webp" alt="Imagen superior"
                        class="overlay-image" style="height: 170%;">
                </div>
            </div>
        </div>

        <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
              </ol>
            <!-- Slides -->
            <div class="carousel-inner">
                <div class="carousel-item active " data-interval="5000"
                    style="background: #0000FF; width: 100%; ">
                    <div class="v-center" style="background-size: cover;">
                        <div class="container gpt3__header " style="background-size: cover;">
                            <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                <div class="spacer-single" style="background-size: cover;"></div>
                                <div class="spacer-10" style="background-size: cover;"></div>
                                <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>Invest in the talent of the future</h1>
                                <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>
                                    Support talented students and athletes with NFTs, boosting their success and your investment.</p>

                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <a href="javascript:void(0)" routerLink="/marketplace"
                                        class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                        style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                        translate>explore</a>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-interval="5000"
                    style="background: #0000FF; width: 100%; ">
                    <div class="v-center" style="background-size: cover;">
                        <div class="container gpt3__header " style="background-size: cover;">
                            <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                <div class="spacer-single" style="background-size: cover;"></div>
                                <div class="spacer-10" style="background-size: cover;"></div>
                                <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>Support students and athletes with skills NFTs</h1>
                                <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>
                                    Invest in their skill hours through NFTs and be part of their growth.</p>

                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <a href="javascript:void(0)" routerLink="/marketplace"
                                        class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                        style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                        translate>explore</a>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item " data-interval="5000"
                style="background: #0000FF; width: 100%; ">
                <div class="v-center" style="background-size: cover;">
                    <div class="container gpt3__header " style="background-size: cover;">
                        <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                            <div class="spacer-single" style="background-size: cover;"></div>
                            <div class="spacer-10" style="background-size: cover;"></div>
                            <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                translate>Your investment, their success, our NFTs</h1>
                            <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                translate>
                                Your investment empowers the success of students and athletes, backed by NFTs on our platform.</p>

                                <div class="spacer-10" style="background-size: cover;"></div>
                                <a href="javascript:void(0)" routerLink="/marketplace"
                                    class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                    style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                    translate>explore</a>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            <div class="mb-sm-30" style="background-size: cover;"></div>
                        </div>
                    </div>
                </div>
            </div>

            </div>
        </div>
    </section>

        <section style="background-color: white;" class="sizeSectionA">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">How does it work?</h1>
                    </div>
                </div>
            </div>
            <div class="container gpt3__header " style="background-size: cover; padding: 50px 0 10px 0;" >
                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                    <div class="text">
                        <h2 translate style="color: black;">Invest in the Potential of the Future</h2>
                    </div>
                    <p class="changetextsize" translate>
                        On this platform, sponsors can invest in NFTs of student hours and Tokens of promising athletes. Your investment can make a difference in their lives and in their futures. Sign up, discover talent and participate in their growth.
                    </p>
                    <button href="javascript:void(0)" class="newbtn btnsize3" style="    background: transparent;
                        color: black;
                        padding: 0;     font-weight: 500;" translate>explore</button>
                </div>
                <div class="gpt3__header-image1">
                    <div style="width: 500px; height: 379px; background-color: #000080; border-radius: 30px;" 
                    class="d-flex justify-content-center align-items-center">
                    <img  src="../../../assets/images/sistema-informatico.png"  style="filter: invert(1); width: 50%;" alt="Imagen de fondo"
                                    class="background-image p-auto">
                    </div>
                </div>
            </div>
        </section>



        <section style="background-color: #000080" class="sizeSection">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: white;">Steps</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/registro.png'" [name]="'Register'"
                            [description]="'Register as a Promoter.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/dar-dinero.png'" [name]="'Invest'"
                            [description]="'Invest in the career of your favorite athlete or a student with projection through our platform.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/beneficios.png'" [name]="'Benefits'"
                            [description]="'Receive a portion of future awards or work hours at a lower price.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/recibo.png'" [name]="'Claim'"
                            [description]="'Claim your hours or benefits.'"></app-beneficios>
                    </div>
                </div>
            </div>
        </section>

        <section style="background-color: white;" class="sizeSectionA">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">Guarantee of Compliance and Transparency</h1>
                    </div>
                </div>
            </div>
            <div class="container" style="    padding: 50px 0 10px 0;">
                <div class="container gpt3__header " style="background-size: cover;">
                    <div class="gpt3__header-image1">
                        <div style="width: 500px; height: 379px; background-color: #000080; border-radius: 30px;" 
                            class="d-flex justify-content-center align-items-center">
                    <img  src="../../../assets/images/garantia.png"  style="filter: invert(1); width: 50%;" alt="Imagen de fondo"
                                    class="background-image p-auto">
                    </div>
                    </div>
                    <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                        <p class="changetextsize" translate>
                            On our platform, we strive to ensure that both students, athletes and sponsors have a safe and transparent experience.
                        </p>
                        <p translate>
                            The fulfillment process for hours sold is completely transparent and backed by blockchain technology to ensure that both students and sponsors meet their commitments fairly and securely. This provides confidence to users of our platform and ensures that the exchange of skills for investment is beneficial to all.
                        </p>

                        <p translate>Click on view more to see the process of fulfilling the hours sold.</p>

                        <button href="javascript:void(0)" class="newbtn btnsize3" style="background: transparent;
                            color: black; padding: 0; font-weight: 500;"
                            (click)="openDialog()" translate>View More</button>
                    </div>

                </div>
            </div>
        </section>

        <section style="background-color: #000080" class="sizeSection">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: white;">Benefits of Supporting New Talent</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/financiamiento-de-la-educacion.png'" [name]="'Talent'"
                            [description]="'Access to emerging talent in diverse areas.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/marca.png'" [name]="'Support'"
                            [description]="'Opportunity to support education and sports.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/acuerdo.png'" [name]="'Custom agreements'"
                            [description]="'Possibility of establishing personalized agreements with students and athletes.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/flexibilidad.png'" [name]="'Get involved'"
                            [description]="'Get involved in the success of future professionals.'"></app-beneficios>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="container" style="background-color: #000CFE; border-radius: 30px;">
                    <div class="text-center paddingB">
                        <p class="h2" style="color: white!important;" translate>Do you want to Support Emerging Talent?</p>
                        <p style=" color: white;" translate  class="p-2">
                            Your investment can change lives. Sign up as a sponsor today and find out how your NFTs can boost the success of students and athletes.
                        </p>
                        <button href="javascript:void(0)" class="newbtn btnsize3" style="    background: transparent;
                            color: white;
                            padding: 0;
                            border: 1px solid;     font-weight: 500;" translate>Register</button>

                    </div>
                </div>
            </div>
        </section>

    </div>
</div>