<div class="blog">

    <div>
     <div>
         <div class="circle">
             <!-- <div class="image lazy" [ngStyle]="{'background-image': 'url(' + img + ')'}"></div> -->
             <img class="image lazy" src="{{img}}" alt="image">
         </div>
     </div>
 
   </div>
     <div class="ml-4" style="margin-top: 11rem;">
         <div class="left" style="display: flex; gap: 10px;">
             <span class="cd-text1"  translate>{{name}}</span>
         </div>
         <div class="left d-flex">
             <span class="cd-text2" translate>{{description}}</span>
         </div>
         <!-- <div class="left">
             <button class="btn btn-primary rounded-0" style="padding: 10px 50px; background: white;  border: 1px solid #FF004F; color: black; font-weight: bold;">VER</button>
         </div> -->
     </div>
 </div>


