import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/services/collection.service';
import { ItemService } from 'src/app/services/item.service';
import { config } from 'src/app/constants/config';
import { FaqItem } from './faq-item/faq-item.component';
import { EmailService } from 'src/app/services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'console';
// import * as jsignature from 'jsignature';
declare var $: any;
interface Slide {
  image: string;
  text: string;
}

interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  showingVideo: boolean = false;
  mediaBase: string = config.media_path;
  currentUser: any;
  collectionMenu = '';
  collections: any = [];
  hotCollections: any = [];
  collectionItems: any = [];
  mostrarCantidad = 5;
  collectionItemsSubastas: any = [];
  trendingItems: any = [];
  trendingMysteryBox: any = [];
  loading = true;
  isSending: boolean = false;

  loadingMysteryBox = true;
  categories: any = [];
  bestSellers: any = [];
  sellMethod: any;
  tokenId: any;
  faqs: FaqItem[] = [
    { question: 'What is this NFT system?', answer: 'This system allows athletes and students to sell NFTs (Non-Fungible Tokens) representing their future earnings or skills. Sponsors can invest in these NFTs and support the users.', showAnswer: false },
    { question: 'What are the types of users on the platform?', answer: 'The platform has two main types of users: Athletes: Those who sell tokens representing future earnings from their athletic performance. Students: Those who sell tokens representing hours of their skills and knowledge.', showAnswer: false },
    { question: 'What is the purpose of this platform?', answer: 'The purpose is twofold: For Athletes: It enables them to fund their sports career by selling a portion of their future earnings and gaining financial support. For Students: It provides them with the opportunity to monetize their skills and knowledge by selling NFT tokens and funding their education or career.', showAnswer: false },
    { question: 'How does the NFT system work for Athletes and Students?', answer: 'Users create NFTs representing their future earnings or skills, setting the terms of agreements with sponsors. Once the agreed-upon conditions are met, the NFTs are automatically burned.', showAnswer: false },
    { question: 'How is agreement compliance ensured?', answer: 'Agreements are recorded on a blockchain platform, ensuring transparency. Additionally, profit percentages are maintained according to agreed-upon milestones, providing security to sponsors.', showAnswer: false },
    { question: 'What is the advantage of investing in NFTs of Athletes or Students?', answer: 'Sponsors have the opportunity to support emerging talents and receive a percentage of their future earnings. It is an investment in the success of future professionals.', showAnswer: false },
    { question: 'Can I sell tokens if I am an Athlete or Student?', answer: 'Yes, both athletes and students can create and sell their own tokens on the platform.', showAnswer: false },
    { question: 'What happens if disagreements or issues arise in the process?', answer: 'The platform offers support for problem resolution in case of disagreements or unexpected situations, ensuring a fair process for all parties involved.', showAnswer: false },
    { question: 'Is it safe to invest in NFTs of Athletes and Students?', answer: 'Yes, blockchain technology ensures transparency and the security of transactions. Additionally, profit percentages are maintained according to agreed-upon milestones, providing confidence to sponsors.', showAnswer: false },
    { question: 'What benefits do I get as an Athlete or Student by joining this platform?', answer: 'Athletes can fund their careers and attract sponsors, while students can monetize their skills and finance their education or projects.', showAnswer: false }
  ];
  name!: string;
  email!: string;
  message!: string;
  asunto!: string;
  slides: Slide[] = [
    { image: '../../../assets/images/back.jpg', text: 'Texto de la primera imagen' },
    { image: '../../../assets/images/back2.jpg', text: 'Texto de la segunda imagen' },
  ];
  currentIndex: number = 0;
  foods: Food[] = [
    {value: 'Deportista', viewValue: 'Deportista'},
    {value: 'Estudiante', viewValue: 'Estudiante'},
    {value: 'Patrocinador', viewValue: 'Patrocinador'},
  ];

  lastItemsForSale: any = [];
  constructor(
    private userService: UserService,
    private router: Router,
    private collectionService: CollectionService,
    private itemService: ItemService,
    private emailService: EmailService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    // this.currentUser = this.userService.getUser()
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
    });

    this.loadTrendingItems();
    this.loadTrendingMysteryBox();
    this.getCollection();
    this.getCategory();
    this.getBestSellers();

    this.lastItemMarket();

    $(document).ready(function() {
      // Start the carousel autoplay
      $('#myCarousel').carousel({
        interval: 6000, // Set the interval between slide transitions (in milliseconds)
      });
    });
  }

  ngOnInit(): void {
    this.startSlideshow();
  }

  startSlideshow() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    }, 8000); // Cambiar imagen cada 2 segundos
  }
  mostrarValorSeleccionado() {
    console.log(this.asunto); // Imprimir el valor seleccionado en la consola
  }

  verMas() {
    this.mostrarCantidad = this.faqs.length; // Mostrar todas las preguntas
  }
  onSubmit() {
    if (!this.name || !this.email || !this.message || !this.asunto) {
      console.log(this.asunto)
      this.snackBar.open(this.translate.instant('Please fill all the fields'), '', { duration: 2000 });
      return;
    }

    if (!this.isValidEmail(this.email)) {
      this.snackBar.open(this.translate.instant('Please enter a valid email address'), '', { duration: 2000 });
      return;
    }

    this.isSending = true;
    this.emailService.sendEmail(this.name, this.email, this.message, this.asunto).subscribe(
      response => {
        this.snackBar.open(this.translate.instant('Message sent successfully'), '', { duration: 2000 });
        console.log('Email sent successfully!');
        this.isSending = false;
      },
      error => {
        this.snackBar.open(this.translate.instant('Message was not sent, an error happened'), '', { duration: 2000 });
        console.log('Error sending email:', error);
        this.isSending = false;
      }
    );
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/i;
    return emailRegex.test(email);
  }
  /**
   * This is the function which navigate uswer to login and marketplace on button click
   */
  openCreate = () => {
    if (this.currentUser == null) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/collection/mycollection']);
    }
  }

  /**
   * This is the function which load item by collection on tab click
   */
  getCollection = () => {
    const params = {
      page: 1,
      type: 'item',
    };
    this.collectionService.listCollection(params).subscribe(result => {
      console.log('l64: ', result);
      if (result.status === true) {
        this.hotCollections = [...result.tempArray];
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          this.collections.push(element);
          if (index === 6) {
            break;
          }
        }
        this.showItemByCollection(this.collectionMenu);
        this.showItemByCollectionSubastas(this.collectionMenu)
      }
    });
  }

  /**
   * This is the function which load item by collection on tab click
   */
  showItemByCollection = (id: any) => {
    this.loading = true;
    this.collectionMenu = id;
    let params = {};
    if (this.collectionMenu === '') {
      params = {
        page: 1,
        type: 'recent',
        // includemysteryboxes: true,
        keyword: ''
      };
    } else {
      params = {
        page: 1,
        type: 'collection',
        collection_id: this.collectionMenu,
        keyword: ''
      };
    }

    this.itemService.listItem(params).subscribe(result => {
      // console.log(result);
      if (result.status === true) {
        this.collectionItems = [];
        for (let index = 1; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          this.tokenId = element.token_id
          this.sellMethod = element.sell_method;
          
          if (element.orderId && element.token_id){
            this.collectionItems.push(element);
            console.log(this.collectionItems)
            if (index === 8) {
              break;
            }
          }

        }
      }
      this.loading = false;
    });
  }

  showItemByCollectionSubastas = (id: any) => {
    this.loading = true;
    this.collectionMenu = id;
    let params = {};
    if (this.collectionMenu === '') {
      params = {
        page: 1,
        type: 'recent',
        // includemysteryboxes: true,
        keyword: ''
      };
    } else {
      params = {
        page: 1,
        type: 'collection',
        collection_id: this.collectionMenu,
        keyword: ''
      };
    }

    this.itemService.listItem(params).subscribe(result => {
      console.log(result);
      if (result.status === true) {
        this.collectionItemsSubastas = [];
        const currentTime = Date.now();
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          this.tokenId = element.token_id;
          this.sellMethod = element.sell_method;

          if (this.sellMethod == 2 && this.tokenId == '') {
            const expireTime = element.expire_at * 1000;
            if (expireTime <= currentTime) {
              // The item has expired, remove it from the array
              this.collectionItemsSubastas.splice(index, 1);
              continue; // Skip to the next iteration of the loop
            }

            console.log(new Date(expireTime).toISOString().slice(0, 19).replace('T', ' '));
            this.collectionItemsSubastas.push(element);
            if (this.collectionItemsSubastas.length === 3) {
              break;
            }
          }
        }
      }
      this.loading = false;
    });
  }


  loadTrendingItems = () => {
    this.loading = true;
    let params = {
      page: 1,
      type: 'trending',
      keyword: ''
    };

    this.itemService.listItem(params).subscribe(result => {

      if (result.status === true) {
        this.trendingItems = [];
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          if (element.for_sale) {
            this.trendingItems.push(element);
            if (index === 3) {
              break;
            }
          }
        }
      }
      this.loading = false;
    });
  }

  loadTrendingMysteryBox = () => {
    this.loadingMysteryBox = true;
    let params = {
      page: 1,
      type: 'mysterybox',
      keyword: ''
    };

    this.collectionService.listCollection(params).subscribe(result => {
      this.trendingMysteryBox = [];
      if (result.status === true) {
        for (let element of result.tempArray) {
          this.trendingMysteryBox.push({
            name: element.name,
            thumb: element.image,
            price: element.price,
            sell_method: 1,
            // currency: "ETH",
            for_sale: true,
            status: "active",
            like_count: 0,
            collection_id: { _id: element._id, author_id: element.author_id }
          });
        }
      }
      this.loadingMysteryBox = false;
    });
  }

  /**
   * This is the output function which used to navigate user to item detail page
   */
  itemEvent = (event: any) => {
    console.log('event:', event);
    if (event.type === 'view') {
      this.router.navigate(['/item/view/' + event.item._id]);
    }
  };

  /**
   * This is the function which used to get category list from api
   */
  getCategory = () => {
    this.itemService.categoryList().subscribe(result => {
      for (let index = 7; index < result.tempArray.length; index++) {
        const element = result.tempArray[index];
        this.categories.push(element);
        if (index === 7) {
          break;
        }
      }
    });
  };

  getBestSellers = () => {
    this.userService.bestSellers().subscribe(result => {
      const arreglo = result.data.slice(0, 8)
      this.bestSellers = arreglo
    });
  }

  author = (item: any) => {
    const profile_image = item.profile_image !== '' ? item.profile_image : 'nouser.jpg';
    return this.mediaBase + '/images/user/' + profile_image;
  }

  authorFullname = (item: any) => {
    return `${item.first_name} ${item.last_name}`;
  }

  collectionsWithItems = () => this.collections.filter((e: any) => e.published_items > 0);

  itemsActivatedForSale = () => {
    return this.collectionItems.filter((e: any) => e.for_sale && e.trending === false);
  }

  showVideo() {
    this.showingVideo = true;
  }

  lastItemMarket(){
    this.itemService.getItemsHome().subscribe(
      result => {
        console.log(result)
        const items = result.data.sales;
        console.log(items);
        this.lastItemsForSale = items;
        console.log(this.lastItemsForSale);
    },
    error => {
      console.log('Error:', error);
    });
  }
}
