import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-estudiante',
  templateUrl: './estudiante.component.html',
  styleUrls: ['./estudiante.component.css']
})
export class EstudianteComponent implements OnInit {

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  openDialog() {
    this.dialogService.openDialogGuarantee({
      title: 'The process of fulfilling the hours sold is governed by the following rules:',
      content: [
        'Term Agreement: When a sponsor decides to invest in your hour NFTs, they agree to the terms and conditions of the NFT generated by the student. This includes the number of hours, the scope of tasks to be performed, and any other relevant condition.',
       'Full Transparency: All agreement information, including terms and committed hours, is transparently recorded on our blockchain platform. This ensures that there are no misunderstandings and that both parties are aware of the commitments.',
       'Direct Communication: To maintain smooth communication between the student and the sponsor, our platform facilitates the exchange of messages and updates on the progress of the work.',
       'Automatic NFT Closure: Once the agreed work is completed and both parties are satisfied, the NFTs are automatically burned. This means that the NFTs will no longer be in circulation and the hours have been successfully fulfilled.',
       'Problem Resolution: In the event of disagreements or unexpected problems, our platform provides support to mediate and resolve conflicts, ensuring a fair process for all parties involved.',
      ]
    });
  }
}
