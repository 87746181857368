import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficiosdeportista',
  templateUrl: './beneficiosdeportista.component.html',
  styleUrls: ['./beneficiosdeportista.component.css']
})
export class BeneficiosdeportistaComponent implements OnInit {
  @Input('img') img = '';
  @Input('title') title = '';
  @Input('name') name = '';
  @Input('description') description = '';
  constructor() { }

  ngOnInit(): void {
  }

}
