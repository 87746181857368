import { AfterViewInit, AfterViewChecked, Component, ElementRef, OnInit, Renderer2, ViewChild, HostListener } from '@angular/core';
import { CollapseDirective } from 'ngx-bootstrap/collapse';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { config } from './constants/config';
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { OnboardService } from 'src/app/services/onboard.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApService } from './services/ap.service';
import { DialogService } from './services/dialog.service';
// import Onboard from 'bnc-onboard';
import Web3 from 'web3';
import { Observable } from 'rxjs';
import { ScrollSectionService } from './services/scroll-section.service';
// import { init as cookiesInit, configure as cookiesConfigure } from 'cookie-though';
// import Config from 'cookie-though/types';

declare var $: any;
declare var window: any;
declare var loadJs: Function;
let web3;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('btnLogout', { static: false }) btnLogout: ElementRef | undefined;
  @ViewChild('btnConnecWallet', { static: false }) btnConnecWallet: ElementRef | undefined;

  mediaBase: string = config.media_path;
  title = 'nftfrontend';
  user: any = null;
  today: number = Date.now();
  profile_image = '';
  userInfo: any = null;
  current_page = '';
  apply_dark_theme = false;
  categories: any = [];
  categoriesLimited: any = [];
  searchInput = '';
  current_lang = 'es';
  available_langs = ['es', 'gb'];
  // private window: any;
  private _isCollapsed = true;
  isPageLoaded = false;

  set isCollapsed(value) {
    this._isCollapsed = value;
  }

  get isCollapsed() {
    if (this.collapseRef) {
      // temp fix for "overflow: hidden"
      if (getComputedStyle(this.collapseRef.nativeElement).getPropertyValue('display') === 'flex') {
        this.renderer.removeStyle(this.collapseRef.nativeElement, 'overflow');
      }
    }
    return this._isCollapsed;
  }
  @ViewChild(CollapseDirective, { read: ElementRef, static: false }) collapse !: CollapseDirective;
  collapseRef: any;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private itemService: ItemService,
    private commonService: CommonService,
    private onboardService: OnboardService,
    private snackBar: MatSnackBar,
    private eleRef: ElementRef,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private apService: ApService,
    private scrollService: ScrollSectionService, 
    // private location:Location
  ) {
    // this.user = this.userService.getUser();
    this.userService.user.subscribe((x) => {
      this.user = x;
      if (x == null) { return; }
      this.profile_image = this.user.profile_image !== '' ? this.user.profile_image : 'nouser.jpg';
    });

    const getAccount = this.getAccount;
    const connectWallet = this.connectWallet;
    const checkWallet = () => {

      if (!getAccount() && this.user) {
        this.btnLogout?.nativeElement.click();
      }
      if (!getAccount()) {
        this.btnConnecWallet?.nativeElement.click();
      }
    };
    checkWallet();
    // const that = this;
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', checkWallet);
    }

    this.current_lang = localStorage.getItem('lang') || 'es';
    this.translate.addLangs(this.available_langs);
    this.translate.setDefaultLang('es');
    this.translate.use(this.current_lang);

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      $('#back-to-top').click();
      if ($('header').height() > 100) {
        $('#menu-btn').click();
      }
      if (this.user != null) {
        if (this.user.status === 'reset') {
          this.router.navigate(['/reset']);
        }
      }

      this.apply_dark_theme = false;
      if (event.url == "/") {
        this.apply_dark_theme = true;
      }
      let url = event.url.split('/');
      // console.log(url);
      if (url[1].includes('?')) {
        let suburl = url[1].split('?');
        this.current_page = suburl[0];
      } else {
        this.current_page = url[1];
      }
      // console.log('current_page:', this.current_page);
      // console.log('url:', event);

      // console.log("data:", this.router.getCurrentNavigation()?.extras.state);
      console.log(this.current_page);
      if (['marketplace', 'stats'].filter((item) => this.current_page.includes(item)).length != 0) {
        this.apply_dark_theme = true;
      }
    });

    this.getCategories();
    this.getSettings();


    // this.initCookiesConcent();
  }

  ngAfterViewInit() {
    // Mostrar el componente app-root después de que se haya cargado completamente
    window.addEventListener('load', () => {
      const appRoot = document.querySelector('app-root') as HTMLElement;
      const menu = document.querySelector('.menu') as HTMLElement;
      if (appRoot !== null && menu !== null) {
        appRoot.style.display = 'block';

        // Agregar una demora de 1 segundo antes de mostrar la pagina
        setTimeout(() => {
          menu.style.display = 'block';
        }, 1000);
      }
    });
        this.getProfileInfo();

  }

  getProfileInfo = () => {
    this.userService.viewProfile(this.user.user_id).subscribe(result => {
      var resulter: any = result;
      this.userInfo = resulter.result;

    })
  }

  navigateToContact() {
    const contactoSection = document.getElementById('contacto');
    if (contactoSection) {
      this.renderer.setProperty(document.documentElement, 'scrollTop', contactoSection.offsetTop);
    }
  }

  ngAfterViewChecked(): void {
    this.collapseRef = this.collapse;
  }


  ngOnInit() {
    this.onboardService.walletAccount.subscribe((response: string) => {
      console.log('Wallet address ', response);
      this.userService.setWallet(response);
    })
    if (this.user != null) {
      this.profile_image = this.user.profile_image != '' ? this.user.profile_image : 'nouser.jpg';
      if (this.user.status == 'reset') {
        this.router.navigate(['/reset']);
      }
    }
    // Inicializar la propiedad _isCollapsed en ngOnInit
    this._isCollapsed = true;

    this.scrollService.sectionToScroll$.subscribe((sectionId: any) => {
      this.scrollToSection(sectionId);
    });



  }


  collapseMenu = () => {
    this._isCollapsed = true;
  };

  async getAccount() {
    if (!window.ethereum) { return false; }
    const accounts = await window.ethereum?.enable();
    if (accounts?.length === 0) { return false; }
    return accounts[0];
    // do something with new account here
    // console.log('account:', account);
    // this.connectWallet();
  }

  async connectWallet() {

    console.log('connectWallet');


    if (window.ethereum?.selectedAddress == null || +window.ethereum?.chainId !== this.onboardService.networkId) {
      const walletSelected = await this.onboardService.walletSelect();
      console.log('walletSelected:', walletSelected);
      const readyToTransact = await this.onboardService.walletCheck();
      console.log('readyToTransact:', readyToTransact);
    }

    if (window.ethereum?.selectedAddress && +window.ethereum?.chainId === this.onboardService.networkId) {
      // do Login
      const wallet = window.ethereum?.selectedAddress;
      console.log('do Login with wallet: ', window.ethereum?.selectedAddress);
      this.loginApi(wallet);
    } else {
      console.log('you need to select the correct chain');
    }



  }



  /**
 * This is the function which used to send login information with params to api
 */
  loginApi = async (wallet: string) => {
    console.log('log', wallet);
    this.userService.getChallenge(wallet.toLowerCase()).subscribe(async resultChallenge => {
      console.log('resultChallenge: ', resultChallenge);
      if (!resultChallenge) {
        this.snackBar.open(this.translate.instant('Error getting Challenge'), '');
        return;
      }
      let resultChallengeSigned;
      try {
        resultChallengeSigned = await this.onboardService.signChallenge(resultChallenge);
        console.log('resultChallengeSigned:', resultChallengeSigned);
      }
      catch (err) {
        console.error(err);
        return;
      }

      if (!resultChallengeSigned) {
        this.snackBar.open(this.translate.instant('Error getting Challenge'), '', { duration: 2000 });
        return;
      }

      this.userService.loginWithWallet(wallet).subscribe({
        next: (result) => {
          console.log('result', result);

          localStorage.setItem('token', result.token);
          const token1 = window.localStorage.getItem('token');
          const wallet1 = window.localStorage.getItem('wallet');

          console.log('token1:', token1);
          console.log('wallet1:', wallet1);

          const token = token1;
          const wallet = wallet1;

          // Creamos un objeto con los datos a guardar
          const dataToSave = {
            token,
            wallet
          };

          // Convertimos el objeto en una cadena JSON
          const dataToSaveString = JSON.stringify(dataToSave);

          // Guardamos los datos en una cookie con una duración de una hora
          document.cookie = `myCookie=${dataToSaveString};path=/;domain=.devtop.online`;
          document.cookie = `myCookie=${dataToSaveString};path=/;`;

          this.userService.refreshUser();

          console.log(this.user)

          if(!this.user.role){
            //this.snackBar.open(this.translate.instant('Login Successful'), "", { duration: 2000 });
            this.router.navigate(['/principal'])
            return;
          }

          if(this.user.role && (this.user.names || this.user.lastname)){
            this.snackBar.open(this.translate.instant('Login Successful'), "", { duration: 2000 });
            this.router.navigate(['/profile/favorited'])
            return;
          }

          if(this.user.role && (!this.user.names && !this.user.lastname)){
            //this.snackBar.open(this.translate.instant('Login Successful'), "", { duration: 2000 });
            this.router.navigate(['/register'])
            return;
          }
      }, error: (err) => {
        console.error(err);

      }});
      
      // this.userService.loginWallet(message.value, String(resultChallengeSigned)).subscribe(result => {
      //   console.log('result', result);

      //   const response: any = result;
      //   console.log('response:', response.token);

      //   if (response.token) {
      //     localStorage.setItem('token', response.token);
      //     const token1 = window.localStorage.getItem('token');
      //     const wallet1 = window.localStorage.getItem('wallet');

      //     console.log('token1:', token1);
      //     console.log('wallet1:', wallet1);

      //     const token = token1;
      //     const wallet = wallet1;

      //     // Creamos un objeto con los datos a guardar
      //     const dataToSave = {
      //       token,
      //       wallet
      //     };

      //     // Convertimos el objeto en una cadena JSON
      //     const dataToSaveString = JSON.stringify(dataToSave);

      //     // Guardamos los datos en una cookie con una duración de una hora
      //     document.cookie = `myCookie=${dataToSaveString};path=/;domain=.devtop.online`;
      //     // document.cookie = `myCookie=${dataToSaveString};path=/;`;

      //     this.userService.refreshUser();

      //     if(response.options) { 
      //       console.log('entre')
      //       this.snackBar.open(this.translate.instant('Login Successful'), "", { duration: 2000 });
      //       this.router.navigate(['/profile/'+this.user.user_id+'/favorited'])
      //     } else {
      //       console.log('no entre')
      //       this.router.navigate(['/principal'])
      //     }

      //   } else {
      //     this.snackBar.open(result.message, '', { duration: 2000 });
      //   }
      // });


    });
  };

  search() {
    if (this.searchInput.length > 0) {
      this.router.navigate([`/marketplace`], { queryParams: { q: this.searchInput } });
      this.apply_dark_theme = true;
      this.searchInput = "";
    }
  }



  availableLangs() {
    return this.available_langs.filter(l => l != this.current_lang);
  }

  changeLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.current_lang = lang;
    this.translate.use(lang);
    this.collapseMenu();
  }




  /**
   * This is the function which used to logout user from the application on menu click
   */
  logoutAction = () => {
    localStorage.removeItem('token'); 
    this.deleteCookie('myCookie');
    this.userService.refreshUser();
    // Eliminamos la cookie
    // location.href = config.base_url;
    this.router.navigate(['/']);
  };

  deleteCookie = (name: any) => {
    // Establecemos la propiedad "expires" de la cookie en una fecha en el pasado
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=.devtop.online`;
  };

  /**
   * This is the function which used to retreive category list from api
   */
  getCategories = () => {
    this.itemService.categoryList().subscribe(result => {
      if (result.status == true) {
        // console.log('categories:', result);
        this.categoriesLimited = [...result.tempArray];
        this.categories = [...result.tempArray].filter((_, i) => i <= 5);
      }
    });
  }

  getSettings = () => {
    this.commonService.loadSettings().subscribe(result => {
      if (result.status === true) {
        // console.log('settings:', result);
        this.commonService.updateSetting(result.data);
        // this.commonService.updateEthNetwork(result.data.eth_network);
        // this.commonService.updateCommission(parseFloat(result.data.admin_commission));
      }
    });
  }

  openDialogCookies() {
    this.dialogService.openDialogCustom({
      title: "cookies modal title",
      content: "cookies modal content",
      btnText: "Accept Cookies"
    });
  }

  checkUser() {
    console.log(this.user?.options);
  }

  
  scrollToSection(sectionId: string) {
    if(this.router.url !== "/") {
      this.router.navigate(['/'])
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  } 


  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    // Detect scroll events if needed
  }

}
