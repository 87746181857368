import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogGuaranteeData } from 'src/app/models/dialog-custom-data';

@Component({
  selector: 'app-dialog-guarantee',
  templateUrl: './dialog-guarantee.component.html',
  styleUrls: ['./dialog-guarantee.component.css']
})
export class DialogGuaranteeComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogGuaranteeData) { }

}
