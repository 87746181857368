import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router,  } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogService } from '../services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class PrincipalGuard implements CanActivate {
  constructor(private userService: UserService,private router:Router, private dialogService:DialogService) {}

  goToPrincipal(){
    this.router.navigate(['/principal'])
  }

  completeProfile(){
    this.router.navigate(['/register'])
  }

  openDialogWarning(){
    this.dialogService.openDialogCustom({
      title: 'Warning',
      content: 'We have not detected any wallet installed, the functions of this site may be limited if you do not connect a wallet.',
      btnText: 'Accept',
    })
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var userDetails = this.userService.getUser();

    const { ethereum } = window as any;

    if (ethereum && ethereum.selectedAddress !== null && localStorage.getItem('token') !== null) {
      console.log(`se ejecuto user guards con usuario conectado`)
      console.log(`userDetails`, userDetails)
      
      if(route.routeConfig?.path !== 'principal'){ 
        if(userDetails.role){
          this.goToPrincipal()
        }
        // if(userDetails.options !== "" && userDetails.email == ""){
        //   this.completeProfile()
        // }
      } 
    }

    if(!ethereum) {
      this.openDialogWarning()
    }

    return true;
  }
  
}
