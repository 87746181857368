<div class="appContainer2">
    <div class="nft-main">

        <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100" style="background: #0000FF">
           
            <div class="principalImage">
                <div class="gpt3__header-image">
                    <div class="container positionRelative"  >
                        <img  src="../../../assets/images/backhome.png" alt="Imagen de fondo"
                            class="background-image">
                        <img src="./../../../assets/images/deportista2.webp" alt="Imagen superior"
                            class="overlay-image" style="height: 175%; width: 100%;">
                    </div>
                </div>
            </div>
           
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                    <li data-target="#myCarousel" data-slide-to="2"></li>
                  </ol>
                <!-- Slides -->
                <div class="carousel-inner">
                    <div class="carousel-item active " data-interval="5000"
                        style="background: #0000FF; width: 100%; ">
                        <div class="v-center" style="background-size: cover;">
                            <div class="container gpt3__header " style="background-size: cover;">
                                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                    <div class="spacer-single" style="background-size: cover;"></div>
                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                        translate>Tokenize Your Sports Success</h1>
                                    <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                        translate>
                                        Turn your achievements into tokens, funding your career and securing future income.</p>
    
                                        <div class="spacer-10" style="background-size: cover;"></div>
                                        <a href="javascript:void(0)" routerLink="/marketplace"
                                            class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                            style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                            translate>explore</a>
                                        <div class="mb-sm-30" style="background-size: cover;"></div>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item" data-interval="5000"
                        style="background: #0000FF; width: 100%; ">
                        <div class="v-center" style="background-size: cover;">
                            <div class="container gpt3__header " style="background-size: cover;">
                                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                    <div class="spacer-single" style="background-size: cover;"></div>
                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                        translate>Earn while you compete with NFT Tokens</h1>
                                    <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                        style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                        translate>
                                        Turn your sporting success into investment opportunities for your fans.</p>
    
                                        <div class="spacer-10" style="background-size: cover;"></div>
                                        <a href="javascript:void(0)" routerLink="/marketplace"
                                            class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                            style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                            translate>explore</a>
                                        <div class="mb-sm-30" style="background-size: cover;"></div>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item " data-interval="5000"
                    style="background: #0000FF; width: 100%; ">
                    <div class="v-center" style="background-size: cover;">
                        <div class="container gpt3__header " style="background-size: cover;">
                            <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                                <div class="spacer-single" style="background-size: cover;"></div>
                                <div class="spacer-10" style="background-size: cover;"></div>
                                <h1 class="wow fadeInUp animated" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>Your achievements, your tokens, your secure future</h1>
                                <p class="wow fadeInUp animated changetextsize hide-mobil" data-wow-delay=".75s"
                                    style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                    translate>
                                    Monetize your career, ensuring a steady stream of income with NFTs.</p>
    
                                    <div class="spacer-10" style="background-size: cover;"></div>
                                    <a href="javascript:void(0)" routerLink="/marketplace"
                                        class="newbtn btnsize3-1 wow fadeInUp lead animated" data-wow-delay="1.25s"
                                        style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;     border: 1px solid;     font-weight: 500;"
                                        translate>explore</a>
                                    <div class="mb-sm-30" style="background-size: cover;"></div>
                                <div class="mb-sm-30" style="background-size: cover;"></div>
                            </div>
                        </div>
                    </div>
                </div>
    
                </div>
            </div>
        </section>

        <section style="background-color: white;" class="sizeSectionA">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">How does it work?</h1>
                    </div>
                </div>
            </div>
            <div class="container gpt3__header " style="background-size: cover; padding: 50px 0 10px 0;" >
                <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                    <div class="text">
                        <h2 translate style="color: black;">Monetize Your Sports Career with NFT Tokens</h2>
                    </div>
                    <p class="changetextsize" translate>
                        Athletes can sell NFT Tokens that represent future rewards. Each athlete will initially share 35% of all future gross rewards received for competing in any event and/or competition mode. The 35% share of prizes will be maintained until the athlete reaches a defined milestone depending on the sport he/she plays, e.g. a professional contract, or entering the top 100, etc. Once the athlete reaches this point, he/she will share 30% for another 2 calendar years. At that point, the revenue sharing will drop by 5% each year until reaching a minimum of 10% until the end of the athlete's career.
                    </p>
                    <button href="javascript:void(0)" class="newbtn btnsize3-1" style="    background: transparent;
                        color: black;
                        padding: 0;     font-weight: 500;" translate>explore</button>
                </div>
                <div class="gpt3__header-image1">
                    <div style="width: 500px; height: 379px; background-color: #000080; border-radius: 30px;" 
                    class="d-flex justify-content-center align-items-center">
                    <img  src="../../../assets/images/sistema-informatico.png"  style="filter: invert(1); width: 50%;" alt="Imagen de fondo"
                                    class="background-image p-auto">
                    </div>
                </div>
            </div>
        </section>



        <section style="background-color: #000080" class="sizeSection">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: white;">Steps</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/registro.png'" [name]="'Register'"
                            [description]="'Register as an Athlete.'"></app-beneficios>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/nfts.png'" [name]="'Create tokens'"
                            [description]="'Create the tokens you offer with their description.'"></app-beneficios>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/dar-dinero.png'" [name]="'Sell your tokens'"
                            [description]="'Sell your tokens and boost your career.'"></app-beneficios>
                    </div>
                </div>
            </div>
        </section>

        <section style="background-color: white;" class="sizeSectionA">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: black;">Your Success is Our Priority</h1>
                    </div>
                </div>
            </div>
            <div class="container" style="    padding: 50px 0 10px 0;">
                <div class="container gpt3__header " style="background-size: cover;">
                    <div class="gpt3__header-image1">
                        <div style="width: 500px; height: 379px; background-color: #000080; border-radius: 30px;" 
                            class="d-flex justify-content-center align-items-center">
                                <img  src="../../../assets/images/garantia.png"  style="filter: invert(1); width: 50%;" alt="Imagen de fondo"
                                    class="background-image p-auto">
                        </div>
                    </div>
                    <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                        <div class="text">
                            <h2 translate style="color: black;">How revenue sharing works.</h2>
                        </div>
                        <p class="changetextsize" translate>
                            On our platform, transparency and compliance are paramount. When you sell tokens that represent your future revenue, you commit to sharing a percentage of your profits. 
                        </p>
                        <p class="changetextsize" translate>
                            Click see more to see how we make sure everything runs smoothly and with confidence.
                        </p>
                        <button href="javascript:void(0)" class="newbtn btnsize3" style="    background: transparent;
                            color: black; padding: 0; font-weight: 500;"
                            (click)="openDialog()" translate>View More</button>
                    </div>

                </div>
            </div>
        </section>

        <section style="background-color: #000080" class="sizeSection">
            <div class="container">
                <div class="container" style="display: flex; justify-content: center; gap: 10px;">
                    <div class="text">
                        <h1 translate style="color: white;">Benefits of Tokenizing Your Sports Success</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/dar-dinero.png'" [name]="'Generate income'"
                            [description]="'Generate income during your sports career.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/inversor.png'" [name]="'Invest'"
                            [description]="'Invest in your future without compromising your present.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/beneficios.png'" [name]="'Sustainability'"
                            [description]="'Secure a long-term income stream.'"></app-beneficios>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <app-beneficios [img]="'../../../assets/images/flexibilidad.png'" [name]="'Flexibility'"
                            [description]="'Maximum flexibility according to your achievements.'"></app-beneficios>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="container" style="background-color: #000CFE; border-radius: 30px;">
                    <div class="text-center paddingB">
                        <p class="h2" style="color: white!important;" translate style="color: white!important;" translate>Are You an Athlete? Boost Your Career with NFTs.</p>
                        <p style=" color: white;" translate>
                            Don't let lack of funds stop you on your path to professional sports.Tokenize your future income and fund your sports career with security and confidence.
                        </p>
                        <button href="javascript:void(0)" class="newbtn btnsize3-1" style="    background: transparent;
                            color: white;
                            padding: 0;
                            border: 1px solid;     font-weight: 500;" translate>Register</button>

                    </div>
                </div>
            </div>
        </section>

    </div>
</div>