import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import { ItemComponent } from './item.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TranslateModule } from '@ngx-translate/core';
import { CountdownModule } from 'ngx-countdown';
import { LoaderModule } from '../loader/loader.module';
@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        TranslateModule,
        CountdownModule,
        LoaderModule
    ],
    exports: [ItemComponent],
    declarations: [ItemComponent],
    providers: [],
})
export class ItemModule {
}
