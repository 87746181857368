<div class="appContainer">
    <div class="nft-main">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <h1 class="text-center" translate>Help Center</h1>
            <p style="font-size: 1rem;" translate>
                If you have any questions or problems, please contact us. An expert will contact you within 48 hours.
            </p>
        </div>
        
    <div class="row pt-5">
        <div class="col-sm-6 col-xs-12 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-center" style="width: 80%;">
            <div class="container">
                <div *ngFor="let categoria of categorias" >
                    <div class="item">
                        <label  [ngClass]="{ 'selected': categoria === opcionSeleccionada }" >
                            <input type="radio" [id]="categoria.id" name="categorias" [(ngModel)]="opcionSeleccionada" [value]="categoria" class="mi-input-radio mr-2">
                            {{categoria.label | translate}}
                        </label>
                    </div>
                    
                </div>
                <!-- <button (click)="opcionSeleccionadaChanged()" translate>My Account</button> -->
            </div>
        </div>
        </div>

        <!-- contact form -->
        <div class="col-sm-6 col-xs-12"> <!--  class="appContainer" -->
            <div *ngIf="opcionSeleccionada.label === 'initial'" class="d-flex justify-content-center">
               <div class="box-help">
                <h2 class="text-center" translate>
                    Select a topic
                </h2>
                <img class="image lazy" src="../../../assets/images/apoyo-tecnico.png" alt="image" style="filter: invert(1); width: 25%;"/>
                <p class="pt-3" translate>
                    Choose the topic that best describes your problem. For other resources, search our frequently asked questions. Use our official support channels, such as our Help Center, for support.
                </p>
               </div>
            </div>

            <div *ngIf="opcionSeleccionada.label !== 'initial'" style="background-size: cover;" class="d-flex justify-content-center">
                <div style="width: 80%;">
                <div class="container">
                    <form (ngSubmit)="onSubmit()" class="contact-form">
                        <mat-label translate>What can we help with?</mat-label>
                        <div class="contact-form-input">
                            <input type="text" class="form-control rounded border-white form-input" 
                             required value="{{opcionSeleccionada.label | translate}}" readonly>
                        </div>
        
                        <mat-label translate>Name</mat-label>
                        <div class="contact-form-input">
                            <input type="text" class="form-control rounded border-white form-input" [(ngModel)]="name"
                                name="name" required>
                        </div>
        
                        <mat-label translate>Email</mat-label>
                        <div class="contact-form-input">
                            <input type="email" class="form-control rounded border-white form-input" [(ngModel)]="email"
                                name="email"  required
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
                        </div>
                        
                        <mat-label translate>Message</mat-label>
                        <div class="contact-form-input">
                            <textarea class="form-control rounded border-white mb-3 form-text-area"
                                [(ngModel)]="message"  rows="4" cols="30" name="message"
                                required></textarea>
                        </div>
        
                        <mat-label translate>Subject</mat-label>
                        <mat-select class="form-control contact-form-input" [(ngModel)]="asunto" name="asunto">
                          <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                          </mat-option>
                        </mat-select>
        
                        <div class="text-center">
                            <button
                                style="background: white; border: 1px solid #0000FF; font-weight: bold; padding: 8px 50px;"
                                type="submit" translate>
                                <span *ngIf="!isSending; else sending" translate>
                                    Send
                                </span>
                                <ng-template #sending>
                                    <mat-progress-spinner style="background-color: gray; border-radius: 40px;"
                                        diameter="20" mode="indeterminate"></mat-progress-spinner>
                                </ng-template>
                            </button>
                        </div>
                    </form>
                </div>
                </div>
            </div>

        </div>
    </div>


    </div>
</div>
