import { Injectable } from '@angular/core';
import {
   HttpInterceptor, HttpHandler, HttpRequest
  } from '@angular/common/http';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private user: UserService, 
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService
    ) {} 

  logoutAction = () => {
    localStorage.removeItem('token');
    this.user.refreshUser();
    // location.href = config.base_url;
    this.router.navigate(['/']);
  };

intercept(req: HttpRequest<any>, next: HttpHandler) {
  const authToken = localStorage.getItem('token');

  const expiredToken = this.user.getExpirationToken();

  if (expiredToken === true) {
    this.snackBar.open(this.translate.instant('Session expired, please login again'), '');
    setTimeout(() => {
      this.logoutAction();
    }, 3000);
    return EMPTY; // Termina el manejo de la solicitud HTTP
  }

  let authReq = req;
  if (authToken != null) {
    authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    });
  }
  return next.handle(authReq);
}
}
