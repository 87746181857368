<div class="menu" style="display: none">

  <header class="transparent" [ngClass]="{'header-light scroll-light': apply_dark_theme == false}"
    style="background: #000010; padding: 1rem;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="de-flex sm-pt10">
            <div class="de-flex-col">
              <div class="de-flex-col">
                <!-- logo begin -->
                <div id="logo">
                  <a routerLink="/">
                    <img alt="" class="logo2" src="./../assets/images/logoheader.png">
                  </a>
                </div>
                <!-- logo close -->
              </div>
            </div>
            <div class="de-flex-col header-col-mid">
              <!-- mainmenu begin -->
              <ul id="mainmenu">
                <li [ngClass]="{'active': current_page == ''}">
                  <a routerLink="/" href="javascript:void(0)" (click)="collapseMenu()" translate>Home</a>
                </li>
                <li [ngClass]="{'active': current_page == 'marketplace'}">
                  <a href="javascript:void(0)" [routerLink]="['/marketplace']">Marketplace</a>
                  <ul>
                    <li><a href="javascript:void(0)" [routerLink]="['/marketplace']" translate>All NFTs</a></li>
                    <li *ngFor="let item of categoriesLimited">
                      <a href="javascript:void(0)" [routerLink]="['/marketplace']"
                        [queryParams]="{category_id: item._id}">{{item.title}}</a>
                    </li>
                  </ul>
                </li>
                <li [ngClass]="{'active': current_page == 'stats'}">
                  <a routerLink="/stats" href="javascript:void(0)" (click)="collapseMenu()" translate>Activity</a>
                </li>
                <li [ngClass]="{'active': current_page == 'conntacto'}">
                  <a routerLink="/contacto" href="javascript:void(0)" (click)="collapseMenu()" translate>Contact</a>
                </li>
                <li *ngIf="user!=null">
                  <a [routerLink]="'/profile/favorited'" href="javascript:void(0)"
                    (click)="collapseMenu()" translate>My Profile</a>
                </li>
                <li [ngClass]="{'active': current_page == 'login'}" *ngIf="user!=null">
                  <a href="javascript:void(0)"><img [src]="mediaBase + '/images/user/' + profile_image"
                      class="profileImage" alt="" />{{user.username}}
                  </a>
                  <ul>
                    <li><a href="javascript:void(0)" #btnLogout id="linkLogout" (click)="logoutAction()"
                        (click)="collapseMenu()" translate>Logout</a></li>
                  </ul>
                </li>


                <li>
                  <a href="javascript:void(0)" translate *ngIf="current_lang === 'gb'">
                    <em class="flag-icon flag-icon-{{current_lang}}"></em>
                    English
                  </a>
                  <a href="javascript:void(0)" translate *ngIf="current_lang === 'es'">
                    <em class="flag-icon flag-icon-{{current_lang}}"></em>
                    Spanish
                  </a>
                  <ul class="flag-custom-label">
                    <li *ngFor="let lang of availableLangs()">
                      <ng-container *ngIf="lang === 'gb'">
                        <a href="javascript:void(0)" (click)="changeLang(lang)" class="flag-alt" translate>
                          <em class="flag-icon flag-icon-{{lang}}"></em>
                          English
                        </a>
                      </ng-container>
                      <ng-container *ngIf="lang === 'es'">
                        <a href="javascript:void(0)" (click)="changeLang(lang)" class="flag-alt" translate>
                          <em class="flag-icon flag-icon-{{lang}}"></em>
                          Spanish
                        </a>
                      </ng-container>
                    </li>
                  </ul>
                </li>

                <!-- until here -->
              </ul>
              <div class="menu_side_area">
                <a href="javascript:void(0)" #btnConnecWallet id="linkConnecWallet" (click)="connectWallet()"
                  class="newbtn btnsize1" *ngIf="user==null">Wallet</a>
                <a [routerLink]="['/item/add']" class="btn-main btn-coll-add" *ngIf="user && user?.options !== 'Patrocinador'  " translate>Create
                  tokens</a>
                <span id="menu-btn"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>


  <router-outlet></router-outlet>


  <footer style="background-color: #000000; padding: 0!important;     margin-top: 4rem;">
    <!-- Grid container -->
    <div class="p-4">
      <!--Grid row-->
      <div class="row mt-4">
        <!--Grid column-->
        <div class="col-lg-5 col-md-12 mb-4 mb-md-0">
          <div style="    display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;">
            <img src="./../assets/images/logofooter.png" height="66" style="width: 268px; height: 66px;" alt=""
              loading="lazy" />
            <div class="widget">
              <ul>
                <li><a href="#" translate>Copyright© JuniorsTime 2023</a></li>
              </ul>
              <div class="col item social" style="    display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding-top: 5px;">
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-twitter"></i></a>
                <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-discord" viewBox="0 0 16 16">
                    <path
                      d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                  </svg>
                </a>
                <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-linkedin" viewBox="0 0 16 16">
                    <path
                      d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                  </svg>
                </a>
                <a href="#"><i class="fa fa-google"></i></a>
              </div>
            </div>
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->


        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <div class="widget">
            <h5 class="text-uppercase mb-4 pb-1" translate>JuniorsTime</h5>
            <ul style="color: white;">
              <li><a (click)="scrollToSection('WhyJunoirsTime')" style="cursor: pointer;" translate>Why Juniors Time?</a></li>
              <li><a (click)="scrollToSection('howtowork')" style="cursor: pointer;" translate>How does it work?</a></li>
              <li><a (click)="scrollToSection('section-faqs')" style="cursor: pointer;" translate>Faqs</a></li>
              <li><a (click)="scrollToSection('contacto')" style="cursor: pointer;" ranslate>Contact</a></li>
            </ul>
          </div>

        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">

          <div class="widget">
            <h5 class="text-uppercase mb-4 pb-1">Marketplace</h5>
            <ul>
              <li><a [routerLink]="['/marketplace']">All NFTs</a></li>
              <li *ngFor="let item of categories"><a [routerLink]="['/marketplace']"
                  [queryParams]="{category_id: item._id}">{{item.title}}</a></li>
            </ul>
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <div class="widget">
            <h5 class="text-uppercase mb-4 pb-1" translate>Legal</h5>
            <ul>
              <li><a [routerLink]="['/avisoslegales']" translate>Legal warning</a></li>
              <li><a [routerLink]="['/privacidadlegales']" translate>Privacy Policy</a></li>
              <li><a  (click)="openDialogCookies()" style="color: white; cursor: pointer;" 
                onmouseover="this.style.color='#0a58ca'" onmouseout="this.style.color='white'"
                translate>Cookies Policy</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-1 col-md-6 mb-4 mb-md-0">

        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
    <!-- Grid container -->
  </footer>

</div>