import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {API} from '../constants/api'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private http: HttpClient) { }

  sendEmail(name: string, email: string, message: string, asunto: string): Observable<any>  {
    let url = API.base_url + API.send_data;
    const data = {
      name: name,
      email: email,
      message: message,
      asunto: asunto
    };
    return this.http.post<any>(url, data);
  }

}