import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-social-media',
  templateUrl: './share-social-media.component.html',
  styleUrls: ['./share-social-media.component.css']
})
export class ShareSocialMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
