import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotComponent } from './components/forgot/forgot.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetComponent } from './components/reset/reset.component';
import { SettingsComponent } from './components/settings/settings.component';
import { StatsComponent } from './components/stats/stats.component';
import { ReferRegisterComponent } from './components/refer-register/refer-register.component';
import { GuestGuard } from './guard/guest.guard';
import { UserGuard } from './guard/user.guard';
import { PrincipalGuard } from './guard/principal.guard';
import { EstudianteComponent } from './components/estudiante/estudiante.component';
import { DeportistaComponent } from './components/deportista/deportista.component';
import { PatrocinadorComponent } from './components/patrocinador/patrocinador.component';
import { AvisolegalesComponent } from './components/avisolegales/avisolegales.component';
import { CookieslegalesComponent } from './components/cookieslegales/cookieslegales.component';
import { PrivacidadlegalesComponent } from './components/privacidadlegales/privacidadlegales.component';
import { PrincipalComponent } from './components/principal/principal.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import { HomesponsorComponent } from './components/homesponsor/homesponsor.component';
import { SponsorGuard } from './guard/sponsor.guard';
import { StudentGuard } from './guard/student.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    //canActivate: [PrincipalGuard, SponsorGuard, StudentGuard],
  },
  {
    path: 'marketplace',
    component: MarketplaceComponent,
    //canActivate: [PrincipalGuard],
  },
  {
    path: 'stats',
    component: StatsComponent,
    //canActivate: [PrincipalGuard],
  },
  {
    path: 'estudiantes',
    component: EstudianteComponent,
    //canActivate: [PrincipalGuard],
  },
  {
    path: 'deportistas',
    component: DeportistaComponent,
    //canActivate: [PrincipalGuard],
  },
  {
    path: 'patrocinador',
    component: PatrocinadorComponent,
    //canActivate: [PrincipalGuard],
  },
  {
    path: 'avisoslegales',
    component: AvisolegalesComponent,
    //***canActivate: [PrincipalGuard],
  },
  {
    path: 'privacidadlegales',
    component: PrivacidadlegalesComponent,
    //canActivate: [PrincipalGuard],
  },
  {
    path: 'privacidadcookies',
    component: CookieslegalesComponent,
    //canActivate: [PrincipalGuard],
  },
  {
    path: 'principal',
    component: PrincipalComponent,
    //canActivate:[UserGuard]
  },
  {
    path: 'contacto',
    component: HelpCenterComponent,
    //canActivate:[PrincipalGuard]

  },
  {
    path: 'homesponsor',
    component: HomesponsorComponent,
    //canActivate:[PrincipalGuard]

  },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  //   canActivate:[GuestGuard]
  // },
  // {
  //   path: 'forgot',
  //   component: ForgotComponent,
  //   canActivate:[GuestGuard]
  // },
  {
    path: 'register',
    component: RegisterComponent,
    //canActivate:[UserGuard]
  },
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  //   canActivate:[GuestGuard]
  // },
  // {
  //   path: 'reset',
  //   component: ResetComponent,
  //   canActivate:[UserGuard]
  // },

  {
    path: 'contacto',
    redirectTo: '/#contacto',
    pathMatch: 'full',
    canActivate: [PrincipalGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    //canActivate: [UserGuard, PrincipalGuard, GuestGuard],
    loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'settings',
    component: SettingsComponent,
    //canActivate: [UserGuard, PrincipalGuard],
    loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule),
  },
  { 
    path: 'collection', 
    //canActivate: [PrincipalGuard],
    loadChildren: () => import('./components/collections/collections.module').then(m => m.CollectionsModule), 
  },
  { 
    path: 'item', 
    //canActivate: [PrincipalGuard],
    loadChildren: () => import('./components/items/items.module').then(m => m.ItemsModule), 
  },
  { 
    path: ':refer_id', 
    component: ReferRegisterComponent,
    //canActivate: [PrincipalGuard],
   },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
