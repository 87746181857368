import { Component } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-patrocinador',
  templateUrl: './patrocinador.component.html',
  styleUrls: ['./patrocinador.component.css']
})
export class PatrocinadorComponent  {

  constructor(private dialogService:DialogService) { }

  openDialog() {
    this.dialogService.openDialogGuarantee({
      title: "The fulfillment process for hours sold is governed by the following rules:",
      content: ["Terms Agreement: When a sponsor decides to invest in your NFTs of hours they agree to the terms and conditions of the NFT generated by the student. This includes the number of hours, the scope of tasks to be performed and any other relevant conditions.", 
    "Total Transparency: All agreement information, including terms and hours committed, is transparently recorded on our blockchain platform. This ensures that there are no misunderstandings and that both parties are aware of the commitments.",
    "Direct Communication: To maintain fluid communication between the student and sponsor, our platform facilitates the exchange of messages and updates on the progress of the work.",
    "Automatic Closing of NFTs: Once the agreed work is completed and both parties are satisfied, the NFTs are automatically burned. This means that the NFTs will no longer be in circulation and the hours have been successfully completed.",
    "Problem Resolution: In the event of disagreements or unexpected problems, our platform provides support to mediate and resolve disputes, ensuring a fair process for all parties involved."
  ]
    });
  }

}
