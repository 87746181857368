<div class="profileContainer" #profileContainer>
    <div class="topContainer" #topContainer style="background-color: white;">
        <div class="profileHeader">
            <div class="profileCover">
                <img src="https://static.vecteezy.com/system/resources/thumbnails/005/544/718/small/profile-icon-design-free-vector.jpg">
                <div class="editAction" *ngIf="ownProfile">
                    <i class="icon-note " style="color: white;"></i>
                    <input type="file" ng2FileSelect [uploader]="cover_uploader" (change)="uploadCover()" multiple />
                </div>
            </div>
            <!-- <div>
                <img [src]="../../../assets/images/verificar.png">
            </div> -->
            <div class="settingsAction" *ngIf="ownProfile">
                <button class="btn btn-secondary px-3" style="    width: 109%;
                height: 4vh; background-color:  #0000FF!important;" routerLink="/settings"><i class="icon-settings"></i>
                    {{'Settings'|translate}}</button>
            </div>
            <div class="profileAvatar">
                <div class="profileAvatarInner">
                    <img src="https://static.vecteezy.com/system/resources/thumbnails/005/544/718/small/profile-icon-design-free-vector.jpg">
                    <img *ngIf="!userVerify" class="warning-acept " src="../../../assets/images/warning.png" 
                        ngbTooltip="{{'Verification pending. We are reviewing all data provided'|translate}}">
                    <div class="avatarAction" *ngIf="ownProfile">
                        <i class="icon-pencil"></i>
                        <input type="file" ng2FileSelect [uploader]="uploader" (change)="uploadAvatar()" multiple />
                    </div>
                </div>


            </div>
        </div>
        <div class="profileSubHeader" *ngIf="userInfo!=null">
            <div class="d-flex justify-content-center align-items-center">
            <h2 style="color: black!important;"> {{profile_name}}</h2>
            <img *ngIf="userVerify" src="../../../assets/images/verificar.png" style="width: 20px; height: 20px;">
            </div>
            <p (click)="copyAddress()">
                {{currentUser.wallet.substring(0,4)}}...{{currentUser.wallet.substring(currentUser.wallet.length-4)}}<i
                    class="fa fa-copy"></i></p>
        </div>
        <div>

            <div class="tabmenu-1 container">
                <ul>
                    <!-- <li (click)="profileLink('general')" class="tab-item" [ngClass]="{'active': type=='general'}"><a
                        href="javascript:void(0);"><i class="cil-note-add"></i><span translate>General</span></a>
                </li> -->
                <li (click)="profileLink('favorited')" class="tab-item active"[ngClass]="{'active': type=='favorited'}"><a
                    href="javascript:void(0);"><i class="cil-note-add"></i><span translate style="    padding-left: 0.5rem;">General</span></a>
                </li>      
                    <li (click)="profileLink('created')" class="tab-item active" [ngClass]="{'active': type=='created'}" *ngIf="currentUser.role != 1004"><a
                            href="javascript:void(0);"><i class="cil-note-add"></i><span translate style="    padding-left: 0.5rem;">My tokens</span></a>
                    </li>
                    <li (click)="profileLink('collected')" class="tab-item active"
                        [ngClass]="{'active': type=='collected'}"><a href="javascript:void(0);"><i
                                class="cil-basket"></i><span translate style="    padding-left: 0.5rem;">Tokens purchased</span></a></li>
                    <li (click)="profileLink('activity')" class="tab-item active" [ngClass]="{'active': type=='activity'}"><a
                            href="javascript:void(0);"><i class="cil-bar-chart"></i><span translate style="    padding-left: 0.5rem;">Activity</span></a>
                    </li>
                </ul>

            </div>

            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>


</div>
<app-loader *ngIf="spinner"></app-loader>