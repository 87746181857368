import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EmailService } from 'src/app/services/email.service';

interface Food {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-avisolegales',
  templateUrl: './avisolegales.component.html',
  styleUrls: ['./avisolegales.component.css']
})

export class AvisolegalesComponent implements OnInit {

  name!: string;
  email!: string;
  message!: string;
  asunto!: string;
  isSending: boolean = false;

  foods: Food[] = [
    {value: 'Deportista', viewValue: 'Deportista'},
    {value: 'Estudiante', viewValue: 'Estudiante'},
    {value: 'Patrocinador', viewValue: 'Patrocinador'},
  ];

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private emailService: EmailService,
  ) { }

  ngOnInit(): void {
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/i;
    return emailRegex.test(email);
  }

  onSubmit() {
    if (!this.name || !this.email || !this.message || !this.asunto) {
      console.log(this.asunto)
      this.snackBar.open(this.translate.instant('Please fill all the fields'), '', { duration: 2000 });
      return;
    }

    if (!this.isValidEmail(this.email)) {
      this.snackBar.open(this.translate.instant('Please enter a valid email address'), '', { duration: 2000 });
      return;
    }

    this.isSending = true;
    this.emailService.sendEmail(this.name, this.email, this.message, this.asunto).subscribe(
      response => {
        this.snackBar.open(this.translate.instant('Message sent successfully'), '', { duration: 2000 });
        console.log('Email sent successfully!');
        this.isSending = false;
      },
      error => {
        this.snackBar.open(this.translate.instant('Message was not sent, an error happened'), '', { duration: 2000 });
        console.log('Error sending email:', error);
        this.isSending = false;
      }
    );
  }

}
