<div class="appContainer2">

    <section id="subheader" class="text-light" style="padding-top: 5rem!important;">
    <div class="center-y relative text-center">
      <div class="container">
        <div class="row">
  
          <div class="col-md-12 text-center">
            <h1 class="fs-explore" style="color: black; font-size: 3.5rem !important;" translate>JuniorsTime</h1>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </section>


    <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100" style="margin-top: -4rem;">
        <div class="v-center" style="background-size: cover;">
            <div class="container text-center" style="overflow: auto;" style="overflow-y: scroll; scroll-behavior: smooth; max-height: 750px;">
                <h1 style="color: black;" translate="">Privacy Policy</h1>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>Personal Information We Collect</h3>
                <p class="text-justify" style="color: black;" translate>
                    Personal Information We Collect p1
                </p>
                <p class="text-justify" style="color: black;" translate>
                    Personal Information We Collect p2
                </p>
                <p class="text-justify" style="color: black;" translate>
                    Personal Information We Collect p3
                </p>
                <h3 class="text-left font-weight-bold" style="color: black;" translate>1. Personal Information You Provide to Us.</h3>
                <p class="text-justify" style="color: black;" translate>
                    1. Personal Information You Provide to Us p1
                </p>
                <p class="text-justify" style="color: black;" translate>
                    1. Personal Information You Provide to Us p2
                </p>
                <p class="text-justify" style="color: black;" translate>
                    1. Personal Information You Provide to Us p3
                </p>
                <p class="text-justify" style="color: black;" translate>
                    1. Personal Information You Provide to Us p4
                </p>
                <p class="text-justify" style="color: black;" translate>
                    1. Personal Information You Provide to Us p5
                </p>
                <p class="text-justify" style="color: black;" translate>
                    1. Personal Information You Provide to Us p6
                </p>
                <h3 class="text-left font-weight-bold" style="color: black;" translate>2. Personal Information We Collect When You Use Our Services.</h3>
                <p class="text-justify" style="color: black;" translate>
                    2. Personal Information We Collect When You Use Our Services p1
                </p>
                <p class="text-justify" style="color: black;" translate>
                    2. Personal Information We Collect When You Use Our Services p2
                </p>
                <p class="text-justify" style="color: black;" translate>
                    2. Personal Information We Collect When You Use Our Services p3
                </p>
                <p class="text-justify" style="color: black;" translate>
                    2. Personal Information We Collect When You Use Our Services p4
                </p>
                <p class="text-justify" style="color: black;" translate>
                    2. Personal Information We Collect When You Use Our Services p5
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>How We Use the Personal Information We Collect</h3>
                <p class="text-justify" style="color: black;" translate>
                    How We Use the Personal Information We Collect p1
                </p>
                <ul>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p2</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p3</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p4</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p5</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p6</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p7</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p8</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p9</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p10</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p11</li>
                    <li class="text-justify" style="color: black;" translate>How We Use the Personal Information We Collect p12</li>
                </ul>
                <h3 class="text-left font-weight-bold" style="color: black;" translate>Legal Bases for Processing European Personal Information</h3>
                <p class="text-justify" style="color: black;" translate>
                    Legal Bases for Processing European Personal Information p1
                </p>
                <ul>
                    <li class="text-justify" style="color: black;" translate>Legal Bases for Processing European Personal Information p2</li>
                    <li class="text-justify" style="color: black;" translate>Legal Bases for Processing European Personal Information p3</li>
                    <li class="text-justify" style="color: black;" translate>Legal Bases for Processing European Personal Information p4</li>
                    <li class="text-justify" style="color: black;" translate>Legal Bases for Processing European Personal Information p5</li>
                </ul>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>How We Share the Personal Information We Collect</h3>
                <p class="text-justify" style="color: black;" translate>
                    How We Share the Personal Information We Collect p1
                </p>
                <p class="text-justify" style="color: black;" translate>
                    How We Share the Personal Information We Collect p2
                </p>
                <p class="text-justify" style="color: black;" translate>
                    How We Share the Personal Information We Collect p3
                </p>
                <p class="text-justify" style="color: black;" translate>
                    How We Share the Personal Information We Collect p4
                </p>
                <p class="text-justify" style="color: black;" translate>
                    How We Share the Personal Information We Collect p5
                </p>
                <p class="text-justify" style="color: black;" translate>
                    How We Share the Personal Information We Collect p6
                </p>
                <p class="text-justify" style="color: black;" translate>
                    How We Share the Personal Information We Collect p7
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>Your Rights and Choices</h3>
                <p class="text-justify" style="color: black;" translate>
                    Your Rights and Choices p1
                </p>
                <p class="text-justify" style="color: black;" translate>
                    Your Rights and Choices p2
                </p>
                <p class="text-justify" style="color: black;" translate>
                    Your Rights and Choices p3
                </p>
                <ul>
                    <li class="text-justify" style="color: black;" translate>Your Rights and Choices p4</li>
                    <li class="text-justify" style="color: black;" translate>Your Rights and Choices p5</li>
                </ul>
                <p class="text-justify" style="color: black;" translate>
                    Your Rights and Choices p6
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>Third Parties</h3>
                <p class="text-justify" style="color: black;" translate>
                    Third Parties p1
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>Retention</h3>
                <p class="text-justify" style="color: black;" translate>
                    Retention p1
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>Security</h3>
                <p class="text-justify" style="color: black;" translate>
                    Security p1
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>Children's Privacy</h3>
                <p class="text-justify" style="color: black;" translate>
                    Children's Privacy p1
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>International Visitors</h3>
                <p class="text-justify" style="color: black;" translate>
                    International Visitors p1
                </p>
                <p class="text-justify" style="color: black;" translate>
                    International Visitors p2
                </p>
                <p class="text-justify" style="color: black;" translate>
                    International Visitors p3
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>Update Your Personal Information</h3>
                <p class="text-justify" style="color: black;" translate>
                    Update Your Personal Information p1
                </p>
                <h3 class="text-left font-weight-bold" style="color: black; text-decoration: underline;" translate>Changes to this Privacy Policy</h3>
                <p class="text-justify" style="color: black;" translate>
                    Changes to this Privacy Policy p1
                </p>
                
            </div>
        </div>
    </section>
    
    <h4 class="text-center" translate>If you have any questions regarding these Terms or the Services</h4>
        <app-contact-us></app-contact-us>
</div>