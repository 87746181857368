import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-deportista',
  templateUrl: './deportista.component.html',
  styleUrls: ['./deportista.component.css']
})
export class DeportistaComponent implements OnInit {

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  openDialog() {
    this.dialogService.openDialogGuarantee({
      title: "Here's how we ensure everything runs smoothly and with confidence.",
      content: ["Clear Terms Agreement: Before backers invest in your tokens, specific terms are set out, including the percentage of profits you'll share with them. This ensures that all parties are on the same page from the start.", 
    "Transparent Revenue Recording: We use blockchain technology to record all earnings and revenue distributions. This ensures that every transaction is transparent and traceable.",
    "Agreed Milestones and Percentages: Earnings percentages are maintained according to agreed milestones, such as professional contracts or rankings. This ensures that you share according to your actual success.",
    "Gradual Percentage Reduction: As you advance in your career, percentages gradually decrease. This gives you long-term financial flexibility while rewarding your sponsors.",
    "Problem Resolution Support: In the event of disagreements or unexpected problems, our platform is here to help mediate and resolve disputes fairly."
  ]
    });
  }

}
