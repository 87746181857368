<!-- nft item begin -->

<div class="nft__item {{ item.creator.role === 1002 ? 'student' : 'non-student' }}">
    <!-- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
    </div> -->
    <!-- <div *ngIf="item.sell_method == 2 && timeLeft() > 0 && item.for_sale" class="de_countdown bg-color-secondary text-white is-countdown">
        <countdown [config]="cdConfig"></countdown>
    </div> -->
    <div *ngIf="useFrom == 'createdComponent'" class="pending-message" [style.borderColor]="item.role === 1002 ? '#122030' : '#0000f2'">
        <p *ngIf="!item.tokenId" translate>Not minted yet</p>
        <p *ngIf="item.salesData && !item.salesData.sold" translate>On sale</p>
        <p *ngIf="item.salesData && item.salesData.sold" translate>Sold</p>
        <p *ngIf="!item.salesData && item.tokenId" translate>Not for sale</p>
    </div>


    <div class="nft__item_wrap {{ item.creator.role === 1002 ? 'student' : 'non-student' }}">
        <a href="javascript:void(0)" (click)="viewAction()">
            <img  style="    height: 340px!important; max-width: 246px !important;" [src]="imageBase + item.imagePath" class="lazy nft__item_preview" alt="">
        </a>
    </div>
    
    <div class="nft__item_info">
        <div class="nft__item_price">
            <div class="row">
                <div class="col-7">
                    <a *ngIf="useFrom == 'createdComponent'" href="javascript:void(0)">
                        <h5 style="color:white; margin: 0!important;">{{item.name}}</h5>
                        <h4 style="color:white">{{item.category}}</h4>
                        <h6 style="color:white"translate>Cantidad: {{item.amount}}</h6>
                    </a>
                    
                    <a *ngIf="useFrom != 'createdComponent'" href="javascript:void(0)">
                        <h5 style="color:white; margin: 0!important;">{{item.creator.names}}</h5>
                        <h4 style="color:white">{{item.creator.category}}</h4>
                        <h6 style="color:white" translate>Cantidad: {{item.amount}}</h6>
                    </a>
                </div>
                <div class="col-5" >
                    <a (click)="viewAction()"  style="padding: 5px 20px;  border: 1px solid white; color:white; font-weight: bold; cursor: pointer;" translate>See</a>
                    <p style="color:white; margin-top: 15px;" class="d-inline-block mr-1">USDT {{item.price}}</p>
                    <img src="../../../assets/images/logo-card.png" alt="">
                </div>
            </div>
            <!-- <h4 style="color:#727272" class="d-inline-block">({{converted_prices.usd | currency:'USD':'':'1.0-0'}}$)</h4> -->
            <!-- <span>1/20</span> -->
        </div>

        <!-- <div class="nft__item_action" *ngIf="item.sell_method == 2 && timeLeft() > 0 && item.status == 'active' && item.for_sale">
            <a href="javascript:void(0)" (click)="viewAction()" translate>Place a bid</a>
        </div>
        <div class="nft__item_like">
            <div class="moreAction dropdown dropright" dropdown *ngIf="item.status == 'inactive' && isOwner() && displayActions">
                <button [id]="'morelink-dropdown'" class="btn" dropdownToggle aria-controls="more-dropdown"><i
                        class="icon-options-vertical"></i></button>
                <div [id]="'more-dropdown'" class="dropdown-menu dropdown-menu-right" aria-labelledby="morelink-dropdown"
                     *dropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="editAction()" translate>Edit</a>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="deleteAction()" translate>Delete</a>
--                    <a class="dropdown-item" href="javascript:void(0)" (click)="publishAction()" translate>Publish</a>--
                </div>
            </div>
            -- <i class="fa fa-heart"></i><span>{{item.like_count}}</span> --
        </div>
        <div *ngIf="isOwner() && isOrderExist()">
            <a class="btn top5 hide-mobil"   style="padding: 5px 25px; border: 1px solid white; color:white; font-weight: bold; border-radius: 0; cursor: pointer;" href="javascript:void(0)" (click)="publishAction()"   translate>Publish</a>
        </div> -->
    </div>


    
</div>



<app-loader *ngIf="spinner"></app-loader>