<!-- nft item begin -->

<div class="nft__item">
    <!-- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
    </div> -->
    <div  style="font-weight: bold;      
    border-radius: 20px;
    top: -29px;
    right: 0px;" *ngIf="item.sell_method == 2 && timeLeft() > 0 && item.for_sale" class="de_countdown bg-color-secondary text-white is-countdown">
        <countdown [config]="cdConfig"></countdown>
    </div>
    <!-- <div class="author_list_pp">
        <a href="javascript:void(0)">
            <img class="lazy" [src]="author()" [alt]="authorFullname()">
            <i class="fa fa-check"></i>
        </a>
    </div> -->
    <div class="nft__item_wrap">
        <a href="javascript:void(0)" (click)="viewAction()">
            <img *ngIf="item.token_id != null && !isVideo" [src]="mediaBase + '/images/item/thumb/' + item.thumb" class="lazy nft__item_preview">
            <video #video *ngIf="item.token_id != null && isVideo" width="100%" style="max-height:300px; padding: 10px;" 
            (loadedmetadata)="video.muted = true"
            (loadedmetadata)="video.controls = true">
                <source [src]="mediaBase + '/images/item/thumb/' + item.thumb" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <img *ngIf="item.token_id == null" [src]="mediaBase+'/images/item/thumb/'+ item.thumb" style="padding: 10px;">
        </a>
    </div>
    <div class="nft__item_info">
        <a href="javascript:void(0)" (click)="viewAction()">
            <h3 style="color: #fff;" >{{item.name}}</h3>
        </a>
        <div >
            <div [innerHTML]="item.description"></div>
        </div>
        <div class="nft__item_price" style="display: flex; justify-content: space-between;">
           <h4 style="color:#04D8D7" class="d-inline-block mr-1">{{item.price}} {{item.set_token}}</h4>
           <div *ngIf="item.sell_method == 2 && timeLeft() > 0 && item.status == 'active' && item.for_sale">
            <a href="javascript:void(0)" (click)="viewAction()" translate>Place a bid</a>
        </div>
            <!-- <h4 style="color:#04D8D7" class="d-inline-block">({{converted_prices.usd | currency:'USD':'':'1.0-0'}}$)</h4>  -->
        
            <!-- <div *ngIf="item.status == 'inactive' && isOwner() && displayActions">
                <a  style="margin-bottom: 1rem;" class="btn btn-primary top5 hide-mobil" href="javascript:void(0)" (click)="publishAction()" translate>Publish</a>
            </div> -->
        </div>



       
        <div class="nft__item_like">
          
            <i style="color: white;" class="fa fa-heart"></i><span style="color: white;">{{item.like_count}}</span>
    
            <div class="moreAction dropdown dropright" dropdown *ngIf="item.status == 'inactive' && isOwner() && displayActions">
                <button [id]="'morelink-dropdown'" class="btn" dropdownToggle aria-controls="more-dropdown"><i
                        class="icon-options-vertical" style="    color: white;"></i></button>
                <div [id]="'more-dropdown'" style="    border: none;
                border-radius: 0px;
                color: white!important;
                background-color: #111432e4;"  class="dropdown-menu dropdown-menu-right" aria-labelledby="morelink-dropdown"
                     *dropdownMenu >
                    <a  style="     color: white!important;" class="dropdown-item" href="javascript:void(0)" (click)="editAction()" translate>Edit</a>
                    <a  style="     color: white!important;"  class="dropdown-item" href="javascript:void(0)" (click)="deleteAction()" translate>Delete</a>
                   <!-- <a  style="     color: white!important;"  class="dropdown-item" href="javascript:void(0)" (click)="publishAction()" translate>Publish</a> -->
                </div>
            </div>
        </div>
     
        <!-- <button class="newbtn btnsize3" style="position: absolute; bottom: -26px; margin-left: 35px; margin-bottom: -10px;" (click)="viewAction()"> COMPRAR</button> -->
    </div>
   
</div>
