<div class="blog">


    <div class="circle">
        <div class="image lazy"  [ngStyle]="{'background-image': 'url(' + img + ')'}"></div>
    </div>

<div style="    position: absolute;

background-size: cover;
bottom: 3rem;">
    <div class="left ">
        <span class="cd-text1" style="color: white;">{{name}}</span>
    </div>
    <div class="left ">
        <span class="cd-text2 " style="color: white" >{{description}}</span>
    </div>
</div>

</div>


