import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogComponent } from '../components/dialogs/custom-dialog/custom-dialog.component';
import { DialogCustomData, DialogGuaranteeData } from '../models/dialog-custom-data';
import { DialogGuaranteeComponent } from '../components/dialogs/dialog-guarantee/dialog-guarantee.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private matDialog: MatDialog) {}

  openDialogCustom(data: DialogCustomData) {
  this.matDialog.open(CustomDialogComponent, {
    data, 
    disableClose: true,
    backdropClass: 'custom-dialog-backdrop',
    panelClass: 'custom-dialog-panel'
  })
}

 openDialogGuarantee(data: DialogGuaranteeData) {
  this.matDialog.open(DialogGuaranteeComponent, {
    data,
    disableClose: true,
    backdropClass: 'custom-dialog-backdrop',
    panelClass: 'custom-dialog-panel'
  })
}

}