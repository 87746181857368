<!-- content begin -->
<div class="no-bottom no-top" id="content">
  <div id="top"></div>

  <!-- section begin -->
  <!-- <section id="subheader" class="text-light no-bottom">
    <div class="center-y relative text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1 class="style-2">Explore</h1>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- section close -->
  <!-- section begin -->
  <section id="subheader" class="text-light" style="padding-top: 5rem!important; ">
    <div class="center-y relative text-center">
      <div class="container">
        <div class="row">

          <div class="col-md-12 text-center">
            <h1 class="fs-explore" translate style="color: black;">explore</h1>
            
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </section>
  <!-- section close -->


  <!-- section begin -->
  <section aria-label="section no-top" style="background-color: white;     padding: 0px 0 0px 0!important">
    <div class="container">
      <div class="row wow fadeIn">
        <div class="col-lg-12">
          <main class="main fixedContainer" [ngClass]="{'minimizeContainer': isToggled == true}">
            <div class="custom-sidebar fixed" #appSidebar
              [ngClass]="{'minmized':isToggled, 'hide':!isToggled, 'f-l':isToggled}">
              <h4 (click)="minimizeMenu()"><i class="cil-filter leftIcon"></i><span translate>Filter</span><i
                  class="rightIcon" [ngClass]="{'cil-arrow-right': isToggled == false, '': isToggled == true}"></i>
              </h4>
            </div>

            <div class="custom-sidebar fixed" #appSidebar
              [ngClass]="{'hide':isToggled, 'expanded':!isToggled, 'f-l':!isToggled}">
              <h4 (click)="minimizeMenu()"><i class="cil-filter leftIcon"></i><span translate>Filter</span><i style="color: #000080!important;"
                  class="rightIcon" [ngClass]="{'cil-arrow-left': isToggled == false, '': isToggled == true}"></i>
              </h4>
              <div class="offerBlock SortBy" *ngIf="!isToggled">
                <div class="animated fadeIn">
                  <div class="card">
                    <div class="card-header" translate>
                      Sort by
                      <div class="card-header-actions">
                        <button class="btn" (click)="isOfferCollapsed = !isOfferCollapsed">
                          <i style="color: #000080!important;" class="icon-arrow-up" *ngIf="!isOfferCollapsed"></i>
                          <i  style="color: #000080!important;" class="icon-arrow-down" *ngIf="isOfferCollapsed"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body" [collapse]="isOfferCollapsed">
                      <div class="row">
                        <div class="col-md-6">
                          <button class="btn btn-block btn-outline-primary"
                            [ngClass]="{'active':type == 'sort' && value == 'recent'}" [routerLink]="['/marketplace']"
                            [queryParams]="{type: 'recent'}" translate>Recent</button>
                        </div>
                        <div class="col-md-6">
                          <button class="btn btn-block btn-outline-primary"
                            [ngClass]="{'active':type == 'sort' && value == 'mostviewed'}"
                            [routerLink]="['/marketplace']" [queryParams]="{type: 'mostviewed'}" translate>Most
                            Viewed</button>
                        </div>
                        <div class="col-md-6">
                          <button class="btn btn-block btn-outline-primary"
                            [ngClass]="{'active':type == 'sort' && value == 'mostliked'}"
                            [routerLink]="['/marketplace']" [queryParams]="{type: 'mostliked'}" translate>Most
                            Liked</button>
                        </div>
                        <div class="col-md-6">
                          <button class="btn btn-block btn-outline-primary"
                            [ngClass]="{'active':type == 'sort' && value == 'offer'}" [routerLink]="['/marketplace']"
                            [queryParams]="{type: 'offer'}" translate>On Auction</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="offerBlock PriceFilter" *ngIf="!isToggled">
                <div class="animated fadeIn">
                  <div class="card">
                    <div class="card-header" translate>
                      Price
                      <div class="card-header-actions">
                        <button class="btn" (click)="isPriceCollapsed = !isPriceCollapsed">
                          <i style="color: #000080!important;"class="icon-arrow-up" *ngIf="!isPriceCollapsed"></i>
                          <i style="color: #000080!important;" class="icon-arrow-down" *ngIf="isPriceCollapsed"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body" [collapse]="isPriceCollapsed">
                      <div class="row">
                        <div class="col-md-6">
                          <button class="btn btn-block btn-outline-primary"
                            [ngClass]="{'active':type == 'price' && value == '1'}" [routerLink]="['/marketplace']"
                            [queryParams]="{price: '1'}">
                            < 1 {{currency}}</button>
                        </div>
                        <div class="col-md-6">
                          <button class="btn btn-block btn-outline-primary"
                            [ngClass]="{'active':type == 'price' && value == '10'}" [routerLink]="['/marketplace']"
                            [queryParams]="{price: '10'}">
                            < 10 {{currency}}</button>
                        </div>
                        <div class="col-md-6">
                          <button class="btn btn-block btn-outline-primary"
                            [ngClass]="{'active':type == 'price' && value == '100'}" [routerLink]="['/marketplace']"
                            [queryParams]="{price: '100'}">
                            < 100 {{currency}}</button>
                        </div>
                        <div class="col-md-6">
                          <button class="btn btn-block btn-outline-primary"
                            [ngClass]="{'active':type == 'price' && value == '1000'}" [routerLink]="['/marketplace']"
                            [queryParams]="{price: '1000'}">
                            < 1000 {{currency}}</button>
                        </div>
                        <div class="col-md-12">
                          <div class="input-group">
                            <input type="number" class="form-control" placeholder="min." name="min" [(ngModel)]="min_price">
                            <input type="number" class="form-control" placeholder="max." name="max" [(ngModel)]="max_price">
                            <div class="input-group-append">
                              <button class="btn btn-block btn-outline-primary" type="button" [ngClass]="{'active':type == 'price_range'}" [routerLink]="['/marketplace']"
                              [queryParams]="{min: (min_price)?min_price:0, max: max_price}">Search</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="offerBlock" *ngIf="!isToggled">
                <div class="animated fadeIn">
                  <div class="card">
                    <div class="card-header" translate>Collections
                      <div class="card-header-actions">
                        <button class="btn" (click)="isCollectionCollapsed = !isCollectionCollapsed">
                          <i style="color: #000080!important;" class="icon-arrow-up" *ngIf="!isCollectionCollapsed"></i>
                          <i  style="color: #000080!important;" class="icon-arrow-down" *ngIf="isCollectionCollapsed"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body" [collapse]="isCollectionCollapsed">
                      <div class="row">
                        <div class="col-sm-12">
                          <div>
                            <input type="text" class="form-control" id="name" placeholder="Search"
                              [(ngModel)]="collectionSearchInput" (keyup.enter)="searchCollectionAction()"
                              (ngModelChange)="searchCollectionAction()" autocomplete="off">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="collectionItem" *ngFor="let item of collections" [routerLink]="['/marketplace']"
                            [queryParams]="{collection_id: item._id}">
                            <div class="collectionItemLeft">
                              <img [src]="mediaBase + '/images/collection/logo/' + item.image" />
                            </div>
                            <div class="collectionItemRight">
                              {{item.name}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="offerBlock" *ngIf="!isToggled">
                <div class="animated fadeIn">
                  <div class="card">
                    <div class="card-header" translate>
                      Categories
                      <div class="card-header-actions">
                        <button class="btn" (click)="isCategoryCollapsed = !isCategoryCollapsed">
                          <i style="color: #000080!important;"class="icon-arrow-up" *ngIf="!isCategoryCollapsed"></i>
                          <i style="color: #000080!important;"class="icon-arrow-down" *ngIf="isCategoryCollapsed"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body" [collapse]="isCategoryCollapsed">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="collectionItem" *ngFor="let item of categories" (click)="categoryAction(item)"
                            [routerLink]="['/marketplace']" [queryParams]="{category_id: item._id}">
                            <div class="collectionItemLeft">
                              <img [src]="mediaBase + '/images/categories/' + item.category_image" />
                            </div>
                            <div class="collectionItemRight">
                              {{item.title}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="container-fluid">
              <!-- <h2>{{page_title | translate}}</h2> -->
              <div class="card-body-filter" [ngClass]="{'fs':!isToggled}">
                <div class="row">
                  <div class="col-12 offset-sm-8 col-sm-4 offset-md-6 col-md-6">
                    <div>
                      <input type="text" class="form-control" id="name" placeholder="{{'Search'|translate}}"
                        [(ngModel)]="searchInput" (keyup.enter)="searchAction()">
                    </div>
                  </div>
                </div>
                <!--/.row-->
                <div class="card-body-filter-action">
                  <button type="submit" class="btn btn-primary btn-main" (click)="searchAction()"><i
                      class="fa fa-search"></i> {{'Search'|translate}}</button>
                  <button type="submit" class="btn border" [ngClass]="{'btn-light':!includemysteryboxes, 'btn-primary':includemysteryboxes}" (click)="includemysteryboxes=!includemysteryboxes;"><i
                    class="fa fa-archive" title="include Mystery boxes"></i></button>
                  <button type="submit" class="btn btn-primary btn-main reset" (click)="resetAction()"><i
                      class="fa fa-refresh"></i> {{'Reset'|translate}}</button>
                </div>
              </div>
              <div class="collectionMainContainer" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50" (scrolled)="onNextPage()">
                <app-itemskeleton *ngIf="loading"></app-itemskeleton>
                <app-nodata *ngIf="!loading && items.length==0" msg="There is no data to display here"></app-nodata>
                <div class="row wow fadeIn animated" *ngIf="!loading && items.length>0">
                  <!-- <div class="col-md-4 col-6 p-lr-5" > -->
                  <app-item [item]="item" (itemEvent)="itemEvent($event)"
                    class="{{filterActiveStyle}}" *ngFor="let item of items"> </app-item>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </main>


        </div>
        <!--  -->
      </div>
    </div>
  </section>

</div>
<!-- content close -->



<div class="appContainer">
  <div class="app-body">


  </div>
</div>