<div> <!--  class="appContainer" -->
    <div style="background-size: cover;">
        <div class="container">
            <form (ngSubmit)="onSubmit()" class="contact-form">
                <div class="container"
                    style="display: flex; justify-content: center; gap: 10px; margin-bottom: 3rem;">
                    <div class="text">
                        <h1 translate style="color: black;">Contact</h1>
                    </div>
                </div>

                <mat-label translate>Name</mat-label>
                <div class="contact-form-input">
                    <input type="text" class="form-control rounded border-white form-input" [(ngModel)]="name"
                        name="name" required>
                </div>

                <mat-label translate>Email</mat-label>
                <div class="contact-form-input">
                    <input type="email" class="form-control rounded border-white form-input" [(ngModel)]="email"
                        name="email"  required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
                </div>
                
                <mat-label translate>Message</mat-label>
                <div class="contact-form-input">
                    <textarea class="form-control rounded border-white mb-3 form-text-area"
                        [(ngModel)]="message"  rows="4" cols="30" name="message"
                        required></textarea>
                </div>

                <mat-label translate>Subject</mat-label>
                <mat-select class="form-control contact-form-input" [(ngModel)]="asunto" name="asunto">
                  <mat-option *ngFor="let food of foods" [value]="food.value">
                    {{food.viewValue}}
                  </mat-option>
                </mat-select>

                <div class="text-center">
                    <button
                        style="background: white; border: 1px solid #0000FF; font-weight: bold; padding: 8px 50px;"
                        type="submit" translate>
                        <span *ngIf="!isSending; else sending" translate>
                            Send
                        </span>
                        <ng-template #sending>
                            <mat-progress-spinner style="background-color: gray; border-radius: 40px;"
                                diameter="20" mode="indeterminate"></mat-progress-spinner>
                        </ng-template>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>